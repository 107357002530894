@import "variables";
@import "../../helpers/tableStyles/TableStyles.scss";

.grid {
  display: grid;
  grid-template-rows: 48px auto;
  @include max-content-width;
  @include on-bp(xl) {
    grid-template-rows: 62px 60px auto;
  }
}

.actionRow {
  grid-row: 1 / 2;
  align-self: center;
}

.tableHeaderRow {
  display: none;
  @include on-bp(xl) {
    display: unset;
    grid-row: 2 / 3;
    @include border;
    border-bottom: 2px solid $grayscale-30-color;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    z-index: 0;
    background-color: $bg-primary-color;
  }
}

.tableBodyRow {
  grid-row: 3 / 4;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  z-index: 0;
  overflow-y: scoll;
  @include on-bp(xl) {
    max-height: $table-body-height;
    overflow-y: auto;
    background-color: $bg-primary-color;
    @include border;
  }
}
