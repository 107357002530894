import { Checkbox } from "@flixbus/honeycomb-react";
import * as React from "react";
import {
  BookingDataField,
  getLabelTextForBookingData,
  isDefaultValue,
} from "../../helpers/bookingDataSelectorHelper/bookingDataSelectorHelper";

import * as css from "./BookingDataSelectorColumn.scss";

export type BookingDataSelectorColumnProps = {
  bookingInformationConfigurationPerFieldType?: BookingDataField[];
  selectedFields: BookingDataField[];
  onConfigurationChange: (newValues: BookingDataField[]) => void;
};

const BookingDataSelectorColumn: React.FC<BookingDataSelectorColumnProps> = ({
  bookingInformationConfigurationPerFieldType,
  selectedFields,
  onConfigurationChange,
}) => {
  const [values, setValues] = React.useState(selectedFields);

  return (
    <>
      {bookingInformationConfigurationPerFieldType?.map((field) => {
        const isChecked = values.includes(field);

        return (
          <Checkbox
            extraClasses={css.checkbox}
            label={getLabelTextForBookingData(field)}
            id={field}
            key={field}
            value={field}
            disabled={isDefaultValue(field)}
            checked={isChecked}
            onChange={() => {
              let newValues: BookingDataField[];

              if (isChecked) {
                newValues = values.filter((value) => value !== field);
              } else {
                newValues = [...values, field];
              }

              setValues(newValues);
              onConfigurationChange(newValues);
            }}
          />
        );
      })}
    </>
  );
};

export default BookingDataSelectorColumn;
