import { Icon, IconStationSolid } from "@flixbus/honeycomb-icons-react";
import { Divider } from "@flixbus/honeycomb-react";
import React from "react";
import { Station } from "../../../shared/types/schema";
import { QueriedRidePassenger } from "../../types";
import PaxRow from "../paxRow/PaxRow";
import * as css from "./PaxListStationRow.scss";

type Props = {
  stationName: Station["name"];
  paxList: QueriedRidePassenger[];
  readOnly: boolean;
};

const PaxListStationRow: React.FC<Props> = ({ stationName, paxList, readOnly }) => {
  return (
    <>
      <div className={css.stationWrapper}>
        <Icon size={3} InlineIcon={IconStationSolid} extraClasses={css.icon} />
        <span className={css.stationName}>{stationName}</span>
        <Divider extraClasses={css.divider} />
      </div>

      {paxList.map((pax) => (
        <PaxRow key={pax.id} pax={pax} readOnly={readOnly} />
      ))}
    </>
  );
};

export default PaxListStationRow;
