import { Icon, IconCheckmark } from "@flixbus/honeycomb-icons-react";
import { Tag } from "@flixbus/honeycomb-react";
import React from "react";
import { FormattedMessage } from "react-intl";
import { CheckInStatus } from "../../../../shared/types/schema";

type Props = {
  status: CheckInStatus;
};

const CheckInStatusTag: React.FC<Props> = ({ status }) => {
  switch (status) {
    case CheckInStatus.Checked:
      return (
        <Tag display="subtle" appearance="success">
          <Icon InlineIcon={IconCheckmark} />
          <FormattedMessage id={`checkIn.checkInStatus.${status}`} />
        </Tag>
      );
    case CheckInStatus.Absent:
      return (
        <Tag display="subtle" appearance="warning">
          <FormattedMessage id={`checkIn.checkInStatus.${status}`} />
        </Tag>
      );
    case CheckInStatus.NotAvailable:
      return (
        <Tag display="subtle">
          <FormattedMessage id="general.notAvailable" />
        </Tag>
      );
    default:
      return (
        <Tag display="subtle">
          <FormattedMessage id={`checkIn.checkInStatus.${status}`} />
        </Tag>
      );
  }
};

export default CheckInStatusTag;
