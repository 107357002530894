import { LocalDate, DateTimeFormatter } from "@js-joda/core";
import { format } from "date-fns";
import isValidDate from "../isValidDate/isValidDate";

export const API_DATE_FORMAT = "yyyy-MM-dd";

export const formatApiDate = (date?: string): string => {
  if (date) {
    return LocalDate.parse(date).format(DateTimeFormatter.ofPattern(API_DATE_FORMAT));
  }
  return LocalDate.now().format(DateTimeFormatter.ofPattern(API_DATE_FORMAT));
};

export const formatDate = (date: string, dateFormat: string): string => {
  if (!isValidDate(date)) {
    return date;
  }

  return format(new Date(date), dateFormat);
};
