import { Heading, Input, ListWrapper, ListWrapperItem } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { FindAllPartnersQuery } from "../../../shared/api/operations.generated";
import { filter, sort } from "../../helpers/arrayHelper/arrayHelper";
import * as css from "./PartnerList.scss";

export type PartnerListProps = {
  partners: FindAllPartnersQuery["findAllPartners"];
  onPartnerSelected: (contractPartnerId: string, contractPartnerName: string) => void;
};

const PartnerList: React.FC<PartnerListProps> = ({ partners, onPartnerSelected }) => {
  const { formatMessage } = useIntl();

  const [partnerList, setPartnerList] = React.useState(sort(partners));

  return (
    <>
      <Heading size={1}>
        <FormattedMessage id="bdOverview.headline" />
      </Heading>

      <Input
        id="filter-partner"
        label={formatMessage({ id: "bdOverview.partner.search.label" })}
        type="text"
        placeholder={formatMessage({ id: "bdOverview.partner.search.placeholder" })}
        extraClasses={css.partnerFilter}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setPartnerList(sort(filter(event.target.value, partners)))
        }
      />

      <ListWrapper extraClasses={css.list}>
        {partnerList.map((partner) => {
          return (
            <ListWrapperItem
              key={partner.name}
              onClick={() => {
                onPartnerSelected(partner.contractPartnerId, partner.name);
              }}
            >
              {partner.name}
            </ListWrapperItem>
          );
        })}
      </ListWrapper>
    </>
  );
};

export default PartnerList;
