import { ApolloError } from "@apollo/client";
import {
  NotificationType,
  UpdateNotificationsArgs,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { PassengerUpdateAction } from "../../../shared/types/schema";

export enum PassengerStatusUpdateErrors {
  PASSENGER_ALREADY_CHECKED_IN = "PASSENGER_ALREADY_CHECKED_IN",
  PASSENGER_NOT_CHECKED_IN = "PASSENGER_NOT_CHECKED_IN",
  PASSENGER_NOT_PART_OF_RIDE = "PASSENGER_NOT_PART_OF_RIDE",
  RIDE_CHECK_IN_CLOSED = "RIDE_CHECK_IN_CLOSED",
  PASSENGER_CANCELED = "PASSENGER_CANCELED",
  SERVER_ERROR = "SERVER_ERROR",
}

export const getSuccessNotification = (
  actionType: PassengerUpdateAction
): UpdateNotificationsArgs => {
  let message = <FormattedMessage id="general.notification.success" />;

  if (actionType === PassengerUpdateAction.CheckIn) {
    message = <FormattedMessage id="checkIn.notification.success.checkIn" />;
  }
  if (actionType === PassengerUpdateAction.CheckOut) {
    message = <FormattedMessage id="checkIn.notification.success.checkOut" />;
  }

  return {
    message,
    type: NotificationType.Success,
  };
};

export const getErrorNotification = (error: ApolloError): UpdateNotificationsArgs => {
  let message = <FormattedMessage id="checkIn.notification.error.SERVER_ERROR" />;
  let type: NotificationType | undefined = NotificationType.Danger;

  if (
    Object.keys(PassengerStatusUpdateErrors).some((key) => error.message.includes(key))
  ) {
    message = (
      <FormattedMessage
        id={`checkIn.notification.error.${Object.keys(PassengerStatusUpdateErrors).find(
          (key) => error.message.includes(key)
        )}`}
      />
    );

    if (
      error.message.includes(PassengerStatusUpdateErrors.PASSENGER_ALREADY_CHECKED_IN) ||
      error.message.includes(PassengerStatusUpdateErrors.PASSENGER_NOT_CHECKED_IN)
    ) {
      type = undefined;
    }
  }

  return {
    message,
    type,
  };
};
