import { datadogRum } from "@datadog/browser-rum";

const env =
  process.env.NODE_ENV === "production"
    ? "prod"
    : process.env.NODE_ENV === "staging"
      ? "stg"
      : "dev";

datadogRum.init({
  applicationId: "84d35d4c-29ad-4b6b-994a-ff016da23e5f",
  clientToken: "pub73b29372254ae8e82cfb9f72336737c8",
  site: "datadoghq.eu",
  env,
  version: process.env.CI_CI_COMMIT_SHA ? process.env.CI_COMMIT_SHA : "unknown",
  service: "plabu-phx-marketplace-ops",
  sessionSampleRate: env === "prod" ? 100 : 10,
  sessionReplaySampleRate: 0,
  trackSessionAcrossSubdomains: true,
  useSecureSessionCookie: true,
  useCrossSiteSessionCookie: true,
  allowFallbackToLocalStorage: true,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});
