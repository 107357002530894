.box {
  padding: 0;
}

.clickable {
  cursor: pointer;
  display: flex;
}

.buttonArrow {
  display: grid;
  align-items: center;
}
