const getPassengerNameText = (
  name: string,
  isLast: boolean,
  totalPassengers: number,
  limit?: number
) => {
  if (!isLast) {
    return `${name},`;
  }

  if (limit && limit < totalPassengers) {
    return `${name}...+${totalPassengers - limit}`;
  }

  return name;
};

export default getPassengerNameText;
