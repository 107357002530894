import { makeVar, ReactiveVar, useReactiveVar } from "@apollo/client";
import { parseDate, readURIParams } from "@flixbus-phx/marketplace-common";
import {
  add,
  differenceInCalendarDays,
  format,
  isBefore,
  isFuture,
  isSameDay,
  isToday,
} from "date-fns";
import { useSearchParams } from "react-router-dom";
import addGetParams from "../../shared/helpers/addGetParams/addGetParams";
import {
  API_DATE_FORMAT,
  formatApiDate,
} from "../../shared/helpers/formatDate/formatDate";
import isValidDate from "../../shared/helpers/isValidDate/isValidDate";
import { DayOfWeek } from "../../shared/types/schema";

export type RideFilter = {
  flixLineCode: string;
  hideCompletedRides: boolean;
  departureStation: string;
  departureTime: string;
  period: {
    startDate: string;
    endDate: string;
  };
  departureDays: Array<DayOfWeek>;
};

export const getFlixLineCode = (): RideFilter["flixLineCode"] => {
  const { flixLineCode } = readURIParams(window.location.search);
  return flixLineCode || "";
};

export const getDepartureStation = (): RideFilter["departureStation"] => {
  const { departureStation } = readURIParams(window.location.search);
  return departureStation || "";
};

export const getDepartureTime = (): RideFilter["departureTime"] => {
  const { departureTime } = readURIParams(window.location.search);

  if (departureTime && /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test(departureTime)) {
    return departureTime;
  }

  return "";
};

export const getPeriod = (): RideFilter["period"] => {
  const { startDate } = readURIParams(window.location.search);
  const { endDate } = readURIParams(window.location.search);

  if (
    startDate &&
    endDate &&
    isValidDate(startDate) &&
    isValidDate(endDate) &&
    (isBefore(new Date(startDate), new Date(endDate)) ||
      isSameDay(new Date(startDate), new Date(endDate))) &&
    differenceInCalendarDays(new Date(endDate), new Date(startDate)) <= 31
  ) {
    return {
      startDate: formatApiDate(startDate),
      endDate: formatApiDate(endDate),
    };
  }

  return {
    startDate: formatApiDate(format(Date.now(), API_DATE_FORMAT)),
    endDate: formatApiDate(format(add(Date.now(), { days: 6 }), API_DATE_FORMAT)),
  };
};

export const getDepartureDays = (): RideFilter["departureDays"] => {
  const { departureDays } = readURIParams(window.location.search);
  const days: Array<String> = (departureDays || "").split(";");

  return Object.values(DayOfWeek).filter((dow) => days.includes(dow));
};

export const getHideCompletedRidesBasedOnPeriod = (
  period: RideFilter["period"]
): RideFilter["hideCompletedRides"] => {
  const startDate = parseDate(period.startDate);

  // noinspection RedundantIfStatementJS
  if (isToday(startDate) || isFuture(startDate)) {
    return true;
  }

  return false;
};

export const rideFilterVar = makeVar({
  flixLineCode: getFlixLineCode(),
  hideCompletedRides: getHideCompletedRidesBasedOnPeriod(getPeriod()),
  departureStation: getDepartureStation(),
  departureTime: getDepartureTime(),
  period: getPeriod(),
  departureDays: getDepartureDays(),
});

export const useResetRideFilter = (reactiveVar: ReactiveVar<RideFilter>) => {
  return () => {
    reactiveVar({
      period: {
        startDate: formatApiDate(format(Date.now(), API_DATE_FORMAT)),
        endDate: formatApiDate(format(add(Date.now(), { days: 6 }), API_DATE_FORMAT)),
      },
      flixLineCode: "",
      hideCompletedRides: true,
      departureStation: "",
      departureTime: "",
      departureDays: [],
    });
  };
};

const useRideFilter = (
  reactiveVar: ReactiveVar<RideFilter>
): [
  RideFilter,
  (changedRideFilter: RideFilter) => void,
  (changedPeriod: RideFilter) => void,
] => {
  const rideFilter = useReactiveVar(reactiveVar);
  const [searchParams, setSearchParams] = useSearchParams();

  const convertParams = (values: RideFilter) => {
    return {
      flixLineCode: values.flixLineCode,
      departureStation: values.departureStation,
      departureTime: values.departureTime,
      departureDays: values.departureDays.join(";"),
      startDate: values.period.startDate,
      endDate: values.period.endDate,
    };
  };

  const setParams = (changedRideFilter: RideFilter) => {
    const newSearchParams = convertParams(changedRideFilter);
    addGetParams(newSearchParams, searchParams);
    setSearchParams(searchParams);
  };

  const setRideFilter = (changedRideFilter: RideFilter) => {
    reactiveVar({
      ...changedRideFilter,
    });
    setParams(changedRideFilter);
  };

  const setPeriodAndRideFilter = (changedPeriodAndRideFilter: RideFilter) => {
    const { period } = changedPeriodAndRideFilter;
    reactiveVar({
      ...changedPeriodAndRideFilter,
      hideCompletedRides: getHideCompletedRidesBasedOnPeriod(period),
      period: {
        startDate: period.startDate,
        endDate: period.endDate,
      },
    });
    setParams(changedPeriodAndRideFilter);
  };

  return [rideFilter, setRideFilter, setPeriodAndRideFilter];
};

export default useRideFilter;
