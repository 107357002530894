@import "variables";

.picker {
  @media (max-width: ($breakpoint-sm - 1px)),
    (min-width: ($breakpoint-md)) and (max-width: ($breakpoint-xl - 1px)) {
    ul {
      width: 100%;
      li label {
        padding: 9px 0 6px 0 !important;
        &::before {
          display: none !important;
        }
      }
    }
  }
}
