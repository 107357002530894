import { Infobox } from "@flixbus/honeycomb-react";
import * as React from "react";
import { useIntl } from "react-intl";
import { Ride } from "../../../../shared/types/schema";
import * as css from "./RideTableRowError.scss";

type Props = {
  rideId: Ride["id"];
};

const RideTableRowError: React.FC<Props> = ({ rideId }) => {
  const { formatMessage } = useIntl();

  return (
    <Infobox appearance="danger" extraClasses={css.box}>
      <p
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: formatMessage(
            { id: "ride.errorMessage" },
            { rideId },
            { ignoreTag: true }
          ),
        }}
      />
    </Infobox>
  );
};

export default RideTableRowError;
