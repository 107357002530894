import { Checkbox } from "@flixbus/honeycomb-react";
import cx from "classnames";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import hasUserPermissionToChangeRides from "../../helpers/hasUserPermissionToChangeRides/hasUserPermissionToChangeRides";
import * as css from "./TableHeaderRow.scss";

export type TableHeaderRowProps = {
  isCheckboxDisabled: boolean;
  isCheckboxChecked: boolean;
  isCheckboxIndeterminate: boolean;
  onSelectCheckbox: () => void;
};

const TableHeaderRow: React.FC<TableHeaderRowProps> = ({
  isCheckboxDisabled,
  isCheckboxChecked,
  isCheckboxIndeterminate,
  onSelectCheckbox,
}) => {
  const showCheckbox = hasUserPermissionToChangeRides();

  return (
    <div className={cx(css.tableGrid, !showCheckbox && css.tableGridViewOnly)}>
      {showCheckbox && (
        <div>
          <Checkbox
            id="selectAllRides"
            value="selectAllRides"
            aria-label="checkbox-to-select-all-rides"
            checked={isCheckboxChecked}
            indeterminate={isCheckboxIndeterminate}
            disabled={isCheckboxDisabled}
            onChange={onSelectCheckbox}
          />
        </div>
      )}

      <div className={css.center}>
        <FormattedMessage id="ride.saleStatus" />
      </div>
      <div className={css.center}>
        <FormattedMessage id="general.line" />
      </div>
      <div className={css.center}>
        <FormattedMessage id="ride.filter.date" />
      </div>
      <div className={css.center}>
        <FormattedMessage id="general.route" />
      </div>
      <div className={cx(css.center, css.textCenter)}>
        <FormattedMessage id="general.passengers" />
      </div>
      <div className={cx(css.center, css.textCenter)}>
        <FormattedMessage id="ride.seatsOnOffer" />
      </div>
      <div className={cx(css.center, css.textCenter)}>
        <FormattedMessage id="ride.lastModified" />
      </div>
    </div>
  );
};

export default TableHeaderRow;
