import * as React from "react";
import { BarChart, XAxis, YAxis, Bar, ReferenceLine, Tooltip } from "recharts";
import { Capacity } from "../../../../shared/types/schema";
import { SegmentsForChart } from "../../helpers/generateSegementsForChart/generateSegmentsForChart";
import ChartTooltip from "../chartTooltip/ChartTooltip";

type Props = {
  segments: SegmentsForChart;
  minimumCapacity: Capacity["minSeatsOnOffer"];
  desiredCapacity: Capacity["seatsOnOfferExpected"];
  disabled: Boolean;
};

export const CHART_BLUE: string = "rgba(0,181,226,0.4)";
export const CHART_LIGHT_BLUE: string = "rgba(165, 226, 243, 0.5)";
export const CHART_GRAY: string = "rgba(136,136,136,0.4)";

const Chart: React.FC<Props> = ({
  segments,
  minimumCapacity,
  desiredCapacity,
  disabled,
}) => {
  return (
    <>
      <BarChart
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        width={800}
        height={280}
        data={segments}
        barCategoryGap={1}
      >
        <XAxis height={85} dataKey="name" interval={0} angle={-65} textAnchor="end" />
        <YAxis type="number" domain={[0, desiredCapacity + 1]} scale="linear" />
        <Tooltip
          content={(props) => {
            if (props.payload && props.payload.length > 0) {
              return (
                <ChartTooltip
                  active={props.active}
                  data={
                    (props.payload[0] as { payload: SegmentsForChart[number] }).payload
                  }
                />
              );
            }
            return null;
          }}
        />
        <Bar
          dataKey="seatsSold"
          stackId="seats"
          fill={disabled ? CHART_GRAY : CHART_BLUE}
        />
        <Bar
          dataKey="seatsReserved"
          stackId="seats"
          fill={disabled ? CHART_GRAY : CHART_LIGHT_BLUE}
        />
        <ReferenceLine y={desiredCapacity} strokeWidth={3} stroke="#73d700" />
        <ReferenceLine y={minimumCapacity} strokeWidth={3} stroke="#ff5700" />
      </BarChart>
    </>
  );
};

export default Chart;
