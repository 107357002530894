import { Skeleton } from "@flixbus/honeycomb-react";
import * as React from "react";
import { getContractPartner } from "../../../shared/helpers/selectedContractPartner/selectedContractPartner";
import { useFindPartnerBookingInfoConfigQuery } from "../../api/operations.generated";
import { applyPreselection } from "../../helpers/selectedBookingInformationConfiguration/selectedBookingInformationConfiguration";
import DownloadConfiguratorPopup from "../../ui/downloadConfiguratorPopup/DownloadConfiguratorPopup";

type Props = {
  disabled: boolean;
};

const DownloadConfigurator: React.FC<Props> = ({ disabled }) => {
  const { contractPartnerId } = getContractPartner();

  const { data, loading } = useFindPartnerBookingInfoConfigQuery({
    variables: { contractPartnerId },
  });

  if (loading) {
    return <Skeleton width="sm" height="lg" flushBottom />;
  }

  if (data?.findPartner) {
    const preselectedBookingInformationConfiguration = applyPreselection(
      contractPartnerId,
      data.findPartner.bookingInformationConfiguration
    );

    return (
      <DownloadConfiguratorPopup
        allowedConfiguration={data.findPartner.bookingInformationConfiguration}
        preselection={preselectedBookingInformationConfiguration}
        disabled={disabled}
      />
    );
  }

  return <></>;
};

export default DownloadConfigurator;
