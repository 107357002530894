import { BookingInformationConfiguration } from "../../../shared/types/schema";

const BOOKING_INFORMATION_CONFIGURATION_KEY =
  "download_booking_information_configuration";

type BookingInformationConfigurationEntry = {
  contractPartnerId: string;
  config: BookingInformationConfiguration;
};

const getBookingInformationConfigurationPreselection = () => {
  const storedItem = localStorage.getItem(BOOKING_INFORMATION_CONFIGURATION_KEY);

  if (storedItem !== null) {
    return JSON.parse(storedItem) as Array<BookingInformationConfigurationEntry>;
  }

  return [];
};

const getBookingInformationConfigurationPreselectionForPartner = (
  contractPartnerId: string
) =>
  getBookingInformationConfigurationPreselection().find(
    (entry) => entry.contractPartnerId === contractPartnerId
  )?.config;

export const storeBookingInformationConfigrationPreselection = (
  contractPartnerId: string,
  bookingInformationConfiguration: BookingInformationConfiguration
) => {
  const allData = getBookingInformationConfigurationPreselection().filter(
    (entry) => entry.contractPartnerId !== contractPartnerId
  );

  allData.push({ contractPartnerId, config: bookingInformationConfiguration });

  localStorage.setItem(BOOKING_INFORMATION_CONFIGURATION_KEY, JSON.stringify(allData));
};

export const applyPreselection = (
  contractPartnerId: string,
  bookingInformationConfiguration: BookingInformationConfiguration
): BookingInformationConfiguration => {
  const preselection =
    getBookingInformationConfigurationPreselectionForPartner(contractPartnerId);

  if (preselection === undefined) return bookingInformationConfiguration;

  return {
    bookingInformation: bookingInformationConfiguration.bookingInformation.filter(
      (field) => !!preselection?.bookingInformation?.includes(field)
    ),
    tripInformation: bookingInformationConfiguration.tripInformation.filter(
      (field) => !!preselection?.tripInformation?.includes(field)
    ),
    passengerInformation: bookingInformationConfiguration.passengerInformation.filter(
      (field) => !!preselection?.passengerInformation?.includes(field)
    ),
  };
};
