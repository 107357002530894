import { parseDate } from "@flixbus-phx/marketplace-common";
import { format } from "date-fns";
import * as React from "react";
import { API_DATE_FORMAT } from "../../../../shared/helpers/formatDate/formatDate";
import useRideFilter, {
  rideFilterVar,
} from "../../../../state/useRideFilter/useRideFilter";
import getWeekdaysOfPeriod from "../../helpers/getWeekdaysOfPeriod/getWeekdaysOfPeriod";
import PeriodPicker from "../../ui/periodPicker/PeriodPicker";

const PeriodFilter: React.FC = () => {
  const [rideFilter, , setPeriodAndRideFilter] = useRideFilter(rideFilterVar);

  const handlePeriodChange = (start: Date, end: Date) => {
    const formatedStart = format(start, API_DATE_FORMAT);
    const formatedEnd = format(end, API_DATE_FORMAT);

    const weekdaysOfPeriod = getWeekdaysOfPeriod(start, end);
    const departureDays = rideFilter.departureDays.filter((d) =>
      weekdaysOfPeriod.includes(d.toLowerCase())
    );

    setPeriodAndRideFilter({
      ...rideFilter,
      departureDays,
      period: { startDate: formatedStart, endDate: formatedEnd },
    });
  };

  return (
    <PeriodPicker
      periodStart={parseDate(rideFilter.period.startDate)}
      periodEnd={parseDate(rideFilter.period.endDate)}
      onChange={handlePeriodChange}
    />
  );
};

export default PeriodFilter;
