import { Icon, IconAttention } from "@flixbus/honeycomb-icons-react";
import { Box, List } from "@flixbus/honeycomb-react";
import { contactEmail, localizeDateWithWeekday } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ExcludedRides } from "../../helpers/includeAndExcludeRidesForBatchOperations/includeAndExcludeRidesForBatchOperations";
import RideConnection from "../rideConnection/RideConnection";
import * as css from "./BatchAdjustExcludedRides.scss";

type Props = {
  rides: ExcludedRides;
  type: "capacityChange" | "salesStop" | "rideCancellation" | "salesRestart";
};

const BatchAdjustExcludedRides: React.FC<Props> = ({ rides, type }) => {
  const getReasonsContent = (reasons: ExcludedRides[number]["reasons"]) => {
    switch (type) {
      case "capacityChange":
        return reasons.length
          ? reasons.map((reason) => (
              <li key={reason}>
                <FormattedMessage
                  id={`ride.CapacityManagementNotPossibleReasons.${reason}`}
                />
              </li>
            ))
          : [
              <li key="no_change">
                <FormattedMessage id="ride.CapacityChangeNotPossibleReasons.NO_CHANGE" />
              </li>,
            ];
      case "salesStop":
        return reasons.map((reason) => (
          <li key={reason}>
            <FormattedMessage
              id={`ride.CapacityManagementNotPossibleReasons.${reason}`}
            />
          </li>
        ));
      case "salesRestart":
        return reasons.map((reason) => (
          <li key={reason}>
            <FormattedMessage
              id={`ride.CapacityManagementNotPossibleReasons.${reason}`}
              values={{
                supportEmail: contactEmail,
              }}
            />
          </li>
        ));
      default:
        return [];
    }
  };

  return (
    <div className={css.table}>
      <div className={css.header} data-id="excluded-rides-headline">
        <FormattedMessage
          id="ride.batchUpdate.popup.excludedRides.title"
          values={{
            countRides: rides.length,
          }}
        />
      </div>
      <div className={css.body}>
        {rides.map(({ id, route, reasons }) => {
          return (
            <div className={css.row} key={id} data-id="excluded-ride-row">
              <div className={css.rideInfoWrapper}>
                <div className={css.date} data-id="excluded-ride-date">
                  {route[0].time.localDeparture
                    ? localizeDateWithWeekday(new Date(route[0].time.localDeparture))
                    : ""}
                </div>
                <div className={css.connection} data-id="excluded-ride-connection">
                  <RideConnection rideRoute={route} />
                </div>
              </div>

              <div className={css.reasons} data-id="excluded-ride-reasons">
                <Box highlighted extraClasses={css.reasonsBox}>
                  <Icon extraClasses={css.reasonsIcon} InlineIcon={IconAttention} />
                  <List extraClasses={css.reasonsList}>{getReasonsContent(reasons)}</List>
                </Box>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BatchAdjustExcludedRides;
