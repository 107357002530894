import { SelectGroup } from "@flixbus/honeycomb-react";
import React from "react";
import { useIntl } from "react-intl";
import { DayOfWeek } from "../../../../shared/types/schema";
import * as css from "./DayPicker.scss";

export type DayPickerProps = {
  value: Array<DayOfWeek>;
  disabled: Array<DayOfWeek>;
  onValueChanged: (v: Array<DayOfWeek>) => void;
};

type Option = {
  id: number;
  value: DayOfWeek;
  name: DayOfWeek;
  label: string;
  checked?: Boolean;
};

const DayPicker: React.FC<DayPickerProps> = ({
  value,
  disabled = [],
  onValueChanged,
}) => {
  const { formatMessage } = useIntl();

  const allOptions: Array<Option> = [
    {
      id: 1,
      value: DayOfWeek.Monday,
      name: DayOfWeek.Monday,
      label: formatMessage({ id: "general.monday" }),
    },
    {
      id: 2,
      value: DayOfWeek.Tuesday,
      name: DayOfWeek.Tuesday,
      label: formatMessage({ id: "general.tuesday" }),
    },
    {
      id: 3,
      value: DayOfWeek.Wednesday,
      name: DayOfWeek.Wednesday,
      label: formatMessage({ id: "general.wednesday" }),
    },
    {
      id: 4,
      value: DayOfWeek.Thursday,
      name: DayOfWeek.Thursday,
      label: formatMessage({ id: "general.thursday" }),
    },
    {
      id: 5,
      value: DayOfWeek.Friday,
      name: DayOfWeek.Friday,
      label: formatMessage({ id: "general.friday" }),
    },
    {
      id: 6,
      value: DayOfWeek.Saturday,
      name: DayOfWeek.Saturday,
      label: formatMessage({ id: "general.saturday" }),
    },
    {
      id: 0,
      value: DayOfWeek.Sunday,
      name: DayOfWeek.Sunday,
      label: formatMessage({ id: "general.sunday" }),
    },
  ];

  const onChange = (e: { target: Option }) => {
    const newValue = [...value];

    if (e.target.checked) {
      if (!value.includes(e.target.value)) {
        newValue.push(e.target.value);
        onValueChanged(newValue);
      }
    } else {
      const index = value.indexOf(e.target.value);
      if (index !== -1) {
        newValue.splice(index, 1);
        onValueChanged(newValue);
      }
    }
  };

  const options = allOptions.map((option) => {
    return {
      ...option,
      disabled: disabled.includes(option.value),
      checked: value.includes(option.value),
      onChange,
    };
  });

  return (
    <SelectGroup
      label={formatMessage({ id: "bookingInfo.filter.departureDay.label" })}
      options={options}
      extraClasses={css.picker}
      multi
    />
  );
};

export default DayPicker;
