import { Spinner } from "@flixbus/honeycomb-react";
import {
  DataReportType,
  DefaultInfoAlert,
  legacyTranslate,
} from "@flixbus-phx/marketplace-common";
import { PowerBIEmbed } from "powerbi-client-react";
import { BackgroundType, TokenType } from "powerbi-models";
import * as React from "react";
import { getContractPartner } from "../shared/helpers/selectedContractPartner/selectedContractPartner";
import * as css from "./PowerBiReports.scss";
import { useGetPowerBiEmbeddedReportQuery } from "./api/operations.generated";

const powerBiReportMap = new Map<DataReportType, string>()
  .set(DataReportType.GrossRevenue, "bc2a72d2-a6a0-406e-9e64-479ed9f2a3a8")
  .set(DataReportType.SegmentDistance, "cdb0e16d-c76e-4719-b387-9ddda80554a0");

export type PowerBiReportsProps = {
  report?: DataReportType;
};

const PowerBiReports: React.FC<PowerBiReportsProps> = ({ report }) => {
  const { contractPartnerId } = getContractPartner();
  const reportUuid = powerBiReportMap.get(report || DataReportType.GrossRevenue)!!;

  const { data, loading, error } = useGetPowerBiEmbeddedReportQuery({
    variables: {
      reportUuid,
      contractPartnerId,
    },
  });

  if (loading) {
    return (
      <div className={css.spinnerWrapper}>
        <Spinner />
      </div>
    );
  }

  if (data) {
    return (
      <PowerBIEmbed
        embedConfig={{
          type: "report",
          id: reportUuid,
          embedUrl: data.getPowerBiEmbeddedReport.embedUrl,
          accessToken: data.getPowerBiEmbeddedReport.embedToken,
          tokenType: TokenType.Embed,
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: false,
              },
            },
            background: BackgroundType.Transparent,
          },
        }}
        cssClassName={css.embeddedReport}
      />
    );
  }

  if (error) {
    return (
      <DefaultInfoAlert
        message={legacyTranslate("general.AnErrorOccured")}
        translate={legacyTranslate}
      />
    );
  }

  return <></>;
};

export default PowerBiReports;
