@import "variables";

.headerSkeleton {
  height: 32px;
  margin-bottom: $spacing-2;
  @include on-bp(md) {
    height: 36px;
    margin-bottom: 0;
  }
}

.buttonSkeleton {
  height: 25px;
  @include on-bp(md) {
    height: 36px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: $spacing-4;

  @include on-bp(md) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.heading {
  margin-bottom: $spacing-2;
  @include on-bp(md) {
    margin-bottom: 0;
  }
  > h1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
