@import "variables";

.qrPanel {
  > div {
    max-height: 100% !important;

    .panelContent {
      position: relative;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .video {
        height: 100%;
        max-width: 100%;
        position: absolute;
      }

      .scanOverlay {
        outline: rgba(255, 255, 255, 0.5) solid 50vmax;
        z-index: 1;
        border: 2px solid #fff;
        border-radius: 10px;
        display: flex !important;
        justify-content: center;

        [class^="hcr-theme-dark"] & {
          outline-color: rgba(0, 0, 0, 0.5);
        }

        .cameraActions {
          position: absolute;
          pointer-events: all;

          bottom: calc(-1 * ($input-height-desktop + 10px));

          @include on-bp(md) {
            bottom: calc(-1 * ($input-height-mobile + 10px));
          }

          &.userCamera {
            transform: scaleX(-1);
          }

          button {
            color: $content-primary-color;

            &:focus,
            &:active {
              outline: 0 !important;
              box-shadow: none !important;
            }

            .flashIconOn svg {
              fill: $ui-primary-light-color;
            }
          }
        }
      }

      .permissionDenied {
        padding: 20%;
        text-align: center;

        .cameraIcon {
          width: 50px !important;
          height: 50px !important;

          svg {
            fill: $icon-primary-color;
          }
        }
      }
    }
  }
}
