// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type FindRideFilterDataQueryVariables = Types.Exact<{
  start: Types.Scalars["String"]["input"];
  end: Types.Scalars["String"]["input"];
  contractPartnerId: Types.Scalars["ID"]["input"];
}>;

export type FindRideFilterDataQuery = {
  __typename?: "Query";
  findRidesByDeparture: Array<{
    __typename?: "Ride";
    id: string;
    localDeparture: string;
    departureStation: { __typename?: "Station"; name: string };
    line: { __typename?: "Line"; code: string };
  }>;
};

export const FindRideFilterDataDocument = gql`
  query FindRideFilterData($start: String!, $end: String!, $contractPartnerId: ID!) {
    findRidesByDeparture(
      start: $start
      end: $end
      contractPartnerId: $contractPartnerId
    ) {
      id
      departureStation {
        name
      }
      line {
        code
      }
      localDeparture
    }
  }
`;

/**
 * __useFindRideFilterDataQuery__
 *
 * To run a query within a React component, call `useFindRideFilterDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindRideFilterDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindRideFilterDataQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      contractPartnerId: // value for 'contractPartnerId'
 *   },
 * });
 */
export function useFindRideFilterDataQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FindRideFilterDataQuery,
    FindRideFilterDataQueryVariables
  > &
    ({ variables: FindRideFilterDataQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FindRideFilterDataQuery,
    FindRideFilterDataQueryVariables
  >(FindRideFilterDataDocument, options);
}
export function useFindRideFilterDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindRideFilterDataQuery,
    FindRideFilterDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FindRideFilterDataQuery,
    FindRideFilterDataQueryVariables
  >(FindRideFilterDataDocument, options);
}
export function useFindRideFilterDataSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    FindRideFilterDataQuery,
    FindRideFilterDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    FindRideFilterDataQuery,
    FindRideFilterDataQueryVariables
  >(FindRideFilterDataDocument, options);
}
export type FindRideFilterDataQueryHookResult = ReturnType<
  typeof useFindRideFilterDataQuery
>;
export type FindRideFilterDataLazyQueryHookResult = ReturnType<
  typeof useFindRideFilterDataLazyQuery
>;
export type FindRideFilterDataSuspenseQueryHookResult = ReturnType<
  typeof useFindRideFilterDataSuspenseQuery
>;
