import { Icon, IconCallSolid } from "@flixbus/honeycomb-icons-react";
import * as React from "react";
import * as css from "./ContactPhone.scss";

type Props = {
  phone: string;
};

const ContactPhone: React.FC<Props> = ({ phone }) => {
  return (
    <div className={css.wrapper}>
      <Icon InlineIcon={IconCallSolid} extraClasses={css.icon} />
      <a href={`tel:${phone}`} className={css.phone}>
        {phone}
      </a>
    </div>
  );
};

export default ContactPhone;
