import { Icon, IconArrowLeft } from "@flixbus/honeycomb-icons-react";
import { Button, Heading } from "@flixbus/honeycomb-react";
import {
  Feature,
  hasUserPermission,
  mpoPathnames as pathnames,
} from "@flixbus-phx/marketplace-common";
import image from "@flixbus-phx/marketplace-common/assets/illustrations/customer-service-call.svg";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { getContractPartner } from "../../../../helpers/selectedContractPartner/selectedContractPartner";
import ChangeSelectedPartnerButton from "../../../changeSelectedPartnerButton/ChangeSelectedPartnerButton";
import * as css from "./NoDataPrivacyContractSignedMessage.scss";

const NoDataPrivacyContractSignedMessage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className={css.wrapper}>
      <div className={css.header}>
        <Heading size={3} flushSpace>
          {hasUserPermission(Feature.VIEW_PARTNER_LIST) ? (
            <FormattedMessage
              id="bookingInfo.noDataPrivacyContractSignedMessage.text.bd"
              values={{ partnerName: getContractPartner().contractPartnerName }}
            />
          ) : (
            <FormattedMessage id="bookingInfo.noDataPrivacyContractSignedMessage.text" />
          )}
        </Heading>
      </div>
      {hasUserPermission(Feature.VIEW_PARTNER_LIST) && (
        <div className={css.topButtonWrapper}>
          <ChangeSelectedPartnerButton />
        </div>
      )}

      <img src={image} alt="" />
      <div className={css.buttonWrapper}>
        <Button appearance="link" onClick={() => navigate(pathnames.capacityManager)}>
          <Icon InlineIcon={IconArrowLeft} />
          <FormattedMessage id="bookingInfo.bookingInfo.noDataPrivacyContractSignedMessage.button" />
        </Button>
      </div>
    </div>
  );
};

export default NoDataPrivacyContractSignedMessage;
