import { Tag } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { RideSaleStatus } from "../../../../shared/types/schema";
import * as css from "./RideSaleStatusTag.scss";

type Props = {
  saleStatus: RideSaleStatus;
};

const RideSaleStatusTag: React.FC<Props> = ({ saleStatus }) => {
  if (saleStatus === RideSaleStatus.Arrived) {
    return (
      <Tag small extraClasses={css.salesStatusArrived}>
        <FormattedMessage id="ride.saleStatus.arrived" />
      </Tag>
    );
  }
  if (saleStatus === RideSaleStatus.SaleStopped) {
    return (
      <Tag appearance="warning" display="outlined" small>
        <FormattedMessage id="ride.saleStatus.saleStopped" />
      </Tag>
    );
  }
  if (saleStatus === RideSaleStatus.Canceled) {
    return (
      <Tag appearance="danger" display="outlined" small>
        <FormattedMessage id="ride.saleStatus.canceled" />
      </Tag>
    );
  }

  // if (saleStatus === RideSaleStatus.OnSale) {
  return (
    <Tag appearance="success" small>
      <FormattedMessage id="ride.saleStatus.onSale" />
    </Tag>
  );
};

export default RideSaleStatusTag;
