import * as React from "react";
import * as css from "./Grid.scss";

export const Grid: React.FC = ({ children }) => {
  return <div className={css.grid}>{children}</div>;
};

export const GridActionRow: React.FC = ({ children }) => {
  return <div className={css.actionRow}>{children}</div>;
};

export const GridTableHeaderRow: React.FC = ({ children }) => {
  return <div className={css.tableHeaderRow}>{children}</div>;
};

export const GridTableBodyRow: React.FC = ({ children }) => {
  return <div className={css.tableBodyRow}>{children}</div>;
};
