import { parseDate } from "@flixbus-phx/marketplace-common";
import React from "react";
import { DayOfWeek } from "../../../../shared/types/schema";
import useRideFilter, {
  RideFilter,
  rideFilterVar,
} from "../../../../state/useRideFilter/useRideFilter";
import getWeekdaysOfPeriod from "../../helpers/getWeekdaysOfPeriod/getWeekdaysOfPeriod";
import DayPicker from "../../ui/dayPicker/DayPicker";

export type DepartureDayFilterProps = {
  onChange?: (value: RideFilter["departureDays"]) => void;
  values?: RideFilter["departureDays"];
};

const DepartureDayFilter: React.FC<DepartureDayFilterProps> = ({ onChange, values }) => {
  const [rideFilter, setRideFilter] = useRideFilter(rideFilterVar);

  const handleInputChange = (departureDays: Array<DayOfWeek>) => {
    if (onChange && values) {
      onChange(departureDays);
    } else {
      setRideFilter({
        ...rideFilter,
        departureDays,
      });
    }
  };

  const weekdaysOfPeriod = getWeekdaysOfPeriod(
    parseDate(rideFilter.period.startDate),
    parseDate(rideFilter.period.endDate)
  );

  const disabledDays: Array<DayOfWeek> = Object.values(DayOfWeek).filter(
    (dow) => !weekdaysOfPeriod.includes(dow.toLowerCase())
  );

  return (
    <>
      <DayPicker
        disabled={disabledDays}
        value={values || rideFilter.departureDays}
        onValueChanged={handleInputChange}
      />
    </>
  );
};

export default DepartureDayFilter;
