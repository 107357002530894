import { Icon, IconPlay } from "@flixbus/honeycomb-icons-react";
import { Button, List, Tooltip } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { CapacityManagementNotPossibleReasons } from "../../../../shared/types/schema";
import RideActionPopup, {
  RideActionType,
} from "../../ui/rideActionPopup/RideActionPopup";
import * as css from "./RestartSalesButton.scss";

export type RestartSalesButtonProps = {
  onRestartSales: () => void;
  salesRestartNotPossibleReasons: Array<CapacityManagementNotPossibleReasons>;
};

const RestartSalesButton: React.FC<RestartSalesButtonProps> = ({
  onRestartSales,
  salesRestartNotPossibleReasons,
  children,
}) => {
  const [popupActive, setPopupActive] = React.useState(false);
  const [confirmationLoading, setConfirmationLoading] = React.useState(false);

  const isDisabled = salesRestartNotPossibleReasons.length > 0;

  const ButtonComponent = (
    <Button
      appearance="secondary"
      extraClasses={css.actionButton}
      disabled={isDisabled}
      onClick={() => setPopupActive(true)}
    >
      <Icon InlineIcon={IconPlay} />
      <FormattedMessage id="ride.restartSale.button" />
    </Button>
  );

  return (
    <>
      {isDisabled && (
        <>
          <div className={css.tooltipWrapper} data-id="disabled-tooltip-wrapper">
            <Tooltip
              id="RestartSalesNotPossibleReasonsTooltip"
              position="left"
              openOnHover
              content={
                <div>
                  <List>
                    {salesRestartNotPossibleReasons.map((reason) => (
                      <li key={reason}>
                        <FormattedMessage
                          id={`ride.CapacityManagementNotPossibleReasons.${reason}`}
                        />
                      </li>
                    ))}
                  </List>
                </div>
              }
            >
              <div className={css.tooltipTarget} />
            </Tooltip>
          </div>
          {ButtonComponent}
        </>
      )}

      {!isDisabled && (
        <>
          {ButtonComponent}
          {popupActive && (
            <RideActionPopup
              type={RideActionType.RESTART_SALES}
              onConfirm={() => {
                setConfirmationLoading(true);
                onRestartSales();
              }}
              onCancel={() => setPopupActive(false)}
              submitLoading={confirmationLoading}
            >
              {children}
            </RideActionPopup>
          )}
        </>
      )}
    </>
  );
};

export default RestartSalesButton;
