import { Icon, IconDownload } from "@flixbus/honeycomb-icons-react";
import { Button, Grid, GridCol, Heading } from "@flixbus/honeycomb-react";
import {
  addNotification,
  NotificationType,
  dlPush,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ConfirmationPopup from "../../../shared/components/confirmationPopup/ConfirmationPopup";
import getFindRidesQueryVars from "../../../shared/helpers/getFindRidesQueryVars/getFindRidesQueryVars";
import { getContractPartner } from "../../../shared/helpers/selectedContractPartner/selectedContractPartner";
import {
  BookingInformationConfiguration,
  BookingInformationField,
  PassengerInformationField,
  TripInformationField,
} from "../../../shared/types/schema";
import useRideFilter, { rideFilterVar } from "../../../state/useRideFilter/useRideFilter";
import downloadBookingInfo from "../../helpers/downloadBookingInfo/downloadBookingInfo";
import { storeBookingInformationConfigrationPreselection } from "../../helpers/selectedBookingInformationConfiguration/selectedBookingInformationConfiguration";
import BookingDataSelectorColumn from "../bookingDataSelectorColumn/BookingDataSelectorColumn";
import * as css from "./DownloadConfiguratorPopup.scss";

export type DownloadConfiguratorPopupProps = {
  allowedConfiguration: BookingInformationConfiguration;
  preselection: BookingInformationConfiguration;
  disabled: boolean;
};

const DownloadConfiguratorPopup: React.FC<DownloadConfiguratorPopupProps> = ({
  allowedConfiguration,
  preselection,
  disabled,
}) => {
  const { contractPartnerId } = getContractPartner();
  const { formatMessage } = useIntl();

  const [popupActive, setPopupActive] = React.useState(false);
  const [downloadInProgress, setDownloadInProgress] = React.useState(false);
  const [bookingConfiguration, setBookingConfiguration] = React.useState(
    preselection.bookingInformation
  );
  const [tripConfiguration, setTripConfiguration] = React.useState(
    preselection.tripInformation
  );
  const [passengerConfiguration, setPassengerConfiguration] = React.useState(
    preselection.passengerInformation
  );

  React.useEffect(() => {
    storeBookingInformationConfigrationPreselection(contractPartnerId, {
      bookingInformation: bookingConfiguration,
      tripInformation: tripConfiguration,
      passengerInformation: passengerConfiguration,
    });
  }, [bookingConfiguration, tripConfiguration, passengerConfiguration]);

  const [rideFilter] = useRideFilter(rideFilterVar);

  const handleDownload = async () => {
    setDownloadInProgress(true);
    const downloadError = await downloadBookingInfo(
      getFindRidesQueryVars(rideFilter, true, false),
      {
        bookingInformation: bookingConfiguration,
        tripInformation: tripConfiguration,
        passengerInformation: passengerConfiguration,
      }
    );

    if (downloadError) {
      addNotification({
        message: formatMessage({ id: "bookingInfo.downloadPopup.unsuccessful" }),
        type: NotificationType.Danger,
      });
    }
    setDownloadInProgress(false);
  };

  return (
    <>
      <Button
        aria-label="download-button"
        appearance="primary"
        onClick={() => {
          setPopupActive(true);
          dlPush({ event: "download_report" });
        }}
        disabled={disabled}
        extraClasses={css.button}
      >
        <Icon InlineIcon={IconDownload} />
        <FormattedMessage id="bookingInfo.downloadButton" />
      </Button>

      {popupActive && (
        <ConfirmationPopup
          active
          titleText={formatMessage({ id: "bookingInfo.downloadPopup.title" })}
          confirmButtonText={formatMessage({ id: "bookingInfo.downloadPopup.confirm" })}
          onConfirm={async () => {
            await handleDownload();
            dlPush({ event: "confirm_download_report" });
            setPopupActive(false);
          }}
          onCancel={() => {
            setPopupActive(false);
          }}
          submitLoading={downloadInProgress}
        >
          <p className={css.configurationDescription}>
            <FormattedMessage id="bookingInfo.downloadPopup.description" />
          </p>
          <Grid>
            <GridCol size={12} sm={4}>
              <div className={css.configurationColumn}>
                <Heading size={4}>
                  <FormattedMessage id="bookingInfo.bookingInformation.heading" />
                </Heading>
                <BookingDataSelectorColumn
                  bookingInformationConfigurationPerFieldType={
                    allowedConfiguration.bookingInformation
                  }
                  selectedFields={bookingConfiguration}
                  onConfigurationChange={(newValues) => {
                    setBookingConfiguration(newValues as BookingInformationField[]);
                  }}
                />
              </div>
            </GridCol>
            <GridCol size={12} sm={4}>
              <div className={css.configurationColumn}>
                <Heading size={4}>
                  <FormattedMessage id="bookingInfo.tripInformation.heading" />
                </Heading>
                <BookingDataSelectorColumn
                  bookingInformationConfigurationPerFieldType={
                    allowedConfiguration.tripInformation
                  }
                  selectedFields={tripConfiguration}
                  onConfigurationChange={(newValues) => {
                    setTripConfiguration(newValues as TripInformationField[]);
                  }}
                />
              </div>
            </GridCol>
            <GridCol size={12} sm={4}>
              <div className={css.configurationColumn}>
                <Heading size={4}>
                  <FormattedMessage id="bookingInfo.passengerInformation.heading" />
                </Heading>
                <BookingDataSelectorColumn
                  bookingInformationConfigurationPerFieldType={
                    allowedConfiguration.passengerInformation
                  }
                  selectedFields={passengerConfiguration}
                  onConfigurationChange={(newValues) => {
                    setPassengerConfiguration(newValues as PassengerInformationField[]);
                  }}
                />
              </div>
            </GridCol>
          </Grid>
        </ConfirmationPopup>
      )}
    </>
  );
};

export default DownloadConfiguratorPopup;
