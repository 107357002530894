import { Button } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import CapacityInput, { CapacityInputProps } from "../capacityInput/CapacityInput";
import * as css from "./CapacityForm.scss";

export type CapacityFormProps = CapacityInputProps;

const CapacityForm: React.FC<CapacityFormProps> = ({
  onCapacityChanged,
  capacityOnOffer,
  minCapacity,
  hasError,
  increaseNotPossibleReasons = [],
  decreaseNotPossibleReasons = [],
}) => {
  const [userSelectedValue, setUserSelectedValue] = React.useState<number | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = React.useState(false);

  const formIsActive = userSelectedValue !== undefined;

  const decreaseDisabled = decreaseNotPossibleReasons.length > 0;
  const increaseDisabled = increaseNotPossibleReasons.length > 0;

  // RESET FORM AFTER MUTATION WAS SUCCESSFUL
  React.useEffect(() => {
    setUserSelectedValue(undefined);
    setIsLoading(false);
  }, [capacityOnOffer, hasError]);

  const isChangeForbidden = () => {
    if (userSelectedValue === undefined) {
      return false;
    }

    // Change is an increase
    if (userSelectedValue > capacityOnOffer) {
      return increaseDisabled;
    }

    // Change is below minCapacity
    if (minCapacity && userSelectedValue < minCapacity) {
      return true;
    }

    // Change is a decrease
    if (userSelectedValue < capacityOnOffer) {
      return decreaseDisabled;
    }

    return false;
  };

  const handleValueChange = (value: number) => {
    if (!increaseDisabled || !decreaseDisabled) {
      setUserSelectedValue(value);
    }
  };

  const applyChanges = () => {
    if (userSelectedValue !== undefined) {
      if (userSelectedValue === capacityOnOffer) {
        setUserSelectedValue(undefined);
      } else {
        setIsLoading(true);
        onCapacityChanged(userSelectedValue);
      }
    }
  };

  const discardChanges = () => {
    setUserSelectedValue(undefined);
  };

  return (
    <div
      data-id="capacity-form-wrapper"
      onKeyDown={({ key }) => {
        if (formIsActive) {
          if (key === "Enter") {
            applyChanges();
          } else if (key === "Escape") {
            discardChanges();
          }
        }
      }}
    >
      <CapacityInput
        onCapacityChanged={handleValueChange}
        capacityOnOffer={
          userSelectedValue !== undefined ? userSelectedValue : capacityOnOffer
        }
        hasError={isChangeForbidden()}
        increaseNotPossibleReasons={increaseNotPossibleReasons}
        decreaseNotPossibleReasons={decreaseNotPossibleReasons}
        minCapacity={minCapacity}
      />
      <div className={css.buttonWrapper}>
        {formIsActive && (
          <>
            <Button
              onClick={discardChanges}
              extraClasses={`${css.button} ${css.cancelButton}`}
              appearance="tertiary"
            >
              <FormattedMessage id="ride.seatsOnOffer.button.cancel" />
            </Button>
            <Button
              onClick={applyChanges}
              extraClasses={css.button}
              appearance="secondary"
              disabled={isChangeForbidden()}
              loading={isLoading}
            >
              <FormattedMessage id="ride.seatsOnOffer.button.apply" />
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default CapacityForm;
