/* 
Hack: There is an Issue in Chrome with onMouseLeave and OnMouseEnter not always firing properly 
when the <Button /> is disabled. Here we create an invisible container that sits over 
the <Button /> which serve as the Tooltip target and its only beeing rendered 
if the <Button /> is disabled.
*/
.tooltipWrapper {
  & > span {
    display: block;
  }

  &.tooltipWrapperDisabled {
    position: relative;
    z-index: 20;

    & > span {
      position: absolute;
      z-index: 11;
    }
  }
}

.button {
  width: 100%;
}

.tooltipTarget {
  width: 240px;
  height: 36px;
  cursor: not-allowed;
}
