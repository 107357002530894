@import "../../helpers/bookingListStyles/BookingListStyles.scss";

.wrapper {
  @include contactWrapper;
}

.icon {
  @include contactIcon;
}

.phone {
  color: $content-primary-color;
}
