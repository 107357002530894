import styles from '../../node_modules/@flixbus/honeycomb/dist/scss/helpers/_a11y.scss.js';
import HoneyBem from '../../packages/honey-bem/honey-bem.js';
import { appendStyles } from '../../packages/injecss/injecss.js';

var srOnlyBem = HoneyBem.init('sr-only', styles);
var srOnlyFocusableBem = HoneyBem.init('sr-only-focusable', styles);
function appendA11yStyles() {
  appendStyles('A11yHelpers', styles.raw);
}
function srOnlyFn() {
  appendA11yStyles();
  return srOnlyBem.b();
}
function srOnlyFocusableFn() {
  appendA11yStyles();
  return srOnlyFocusableBem.b();
}
var srOnly = srOnlyFn();
var srOnlyFocusable = srOnlyFocusableFn();

export { srOnly, srOnlyFocusable };
