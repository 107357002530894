import { Skeleton } from "@flixbus/honeycomb-react";
import {
  contactEmail,
  mpoPathnames as pathnames,
  readURIParams,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useFindAllPartnersQuery } from "../shared/api/operations.generated";
import { setContractPartner } from "../shared/helpers/selectedContractPartner/selectedContractPartner";
import { rideFilterVar, useResetRideFilter } from "../state/useRideFilter/useRideFilter";
import useSelectedRides, {
  selectedRidesVar,
} from "../state/useSelectedRides/useSelectedRides";
import PartnerList from "./ui/partnerList/PartnerList";

const PartnerOverview: React.FC = () => {
  const navigate = useNavigate();
  const { data, loading, error } = useFindAllPartnersQuery();
  const { formatMessage } = useIntl();

  const resetRideFilter = useResetRideFilter(rideFilterVar);
  const [, , , , removeSelectedRides] = useSelectedRides(selectedRidesVar);

  const handlePartnerSelected = (
    contractPartnerId: string,
    contractPartnerName: string
  ) => {
    setContractPartner(contractPartnerId, contractPartnerName);

    resetRideFilter();
    removeSelectedRides();

    const { returnTo } = readURIParams(window.location.search);

    navigate(returnTo || pathnames.capacityManager);
  };

  if (loading) {
    return <Skeleton width="md" height="lg" />;
  }

  if (error) {
    return (
      <div
        data-id="mypartners-error"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: formatMessage(
            { id: "bdOverview.error.noPartners" },
            {
              supportEmail: contactEmail,
            },
            { ignoreTag: true }
          ),
        }}
      />
    );
  }

  if (data?.findAllPartners) {
    if (data.findAllPartners.length === 1) {
      const { contractPartnerId, name } = data.findAllPartners[0];
      handlePartnerSelected(contractPartnerId, name);
      return <></>;
    }

    return (
      <PartnerList
        partners={data.findAllPartners}
        onPartnerSelected={handlePartnerSelected}
      />
    );
  }

  return <></>;
};

export default PartnerOverview;
