import { SupportedGETParams } from "@flixbus-phx/marketplace-common";

const addGetParamToQuery = (
  key: keyof SupportedGETParams,
  value: string,
  params: URLSearchParams
) => {
  const paramExists = params.has(key);

  if (paramExists) {
    params.set(key, value);
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const [name, val] of params.entries()) {
    if (val === "") {
      params.delete(name);
    }
  }

  if (!paramExists && value !== "") {
    params.set(key, value);
  }
};

const addGetParams = (newParams: SupportedGETParams, currentParams: URLSearchParams) => {
  Object.entries(newParams).forEach(([key, value]) => {
    addGetParamToQuery(key as keyof SupportedGETParams, value ?? "", currentParams);
  });
};

export default addGetParams;
