// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type BookingInformationConfiguration = {
  __typename?: "BookingInformationConfiguration";
  passengerInformation: Array<PassengerInformationField>;
  tripInformation: Array<TripInformationField>;
  bookingInformation: Array<BookingInformationField>;
};

export enum BookingInformationField {
  FlixOrderId = "FLIX_ORDER_ID",
  PartnerOrderId = "PARTNER_ORDER_ID",
  BookingDate = "BOOKING_DATE",
  PaidPrice = "PAID_PRICE",
  BoardingPass = "BOARDING_PASS",
  Invoice = "INVOICE",
}

export type BookingInformationResponse = {
  __typename?: "BookingInformationResponse";
  ride: Ride;
  orderId: Scalars["ID"]["output"];
  lineCode: Scalars["String"]["output"];
  departureTime: Scalars["String"]["output"];
  arrivalTime: Scalars["String"]["output"];
  departureStation: Scalars["String"]["output"];
  arrivalStation: Scalars["String"]["output"];
  numberOfPassengers: Scalars["Int"]["output"];
  orderStatus: OrderStatus;
  contactEmail?: Maybe<Scalars["String"]["output"]>;
  interconnection: Scalars["Boolean"]["output"];
  passengers: Array<Passenger>;
};

export enum CancellationStatus {
  None = "NONE",
  Requested = "REQUESTED",
  Accepted = "ACCEPTED",
  Successful = "SUCCESSFUL",
}

export type Capacity = {
  __typename?: "Capacity";
  isCapacityInitiated: Scalars["Boolean"]["output"];
  seatsOnOfferExpected: Scalars["Int"]["output"];
  minSeatsOnOffer: Scalars["Int"]["output"];
  segmentCapacity: Array<SegmentCapacityEntry>;
  decreaseNotPossibleReasons: Array<CapacityManagementNotPossibleReasons>;
  increaseNotPossibleReasons: Array<CapacityManagementNotPossibleReasons>;
};

export enum CapacityManagementNotPossibleReasons {
  CapacityManagementNotAllowed = "CAPACITY_MANAGEMENT_NOT_ALLOWED",
  RideHasArrived = "RIDE_HAS_ARRIVED",
  SalesStoppedManually = "SALES_STOPPED_MANUALLY",
  SalesStoppedAutomatically = "SALES_STOPPED_AUTOMATICALLY",
  RideIsCancelled = "RIDE_IS_CANCELLED",
  SalesNotStopped = "SALES_NOT_STOPPED",
  SeatsOnOfferNotAvailable = "SEATS_ON_OFFER_NOT_AVAILABLE",
  CapacityIsBelowMinSeatsOnOffer = "CAPACITY_IS_BELOW_MIN_SEATS_ON_OFFER",
  DepartureIsTooFarInTheFuture = "DEPARTURE_IS_TOO_FAR_IN_THE_FUTURE",
}

export enum CheckInStatus {
  Unchecked = "UNCHECKED",
  Checked = "CHECKED",
  Absent = "ABSENT",
  NotAvailable = "NOT_AVAILABLE",
}

export type City = {
  __typename?: "City";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  countryCode: Scalars["String"]["output"];
};

export enum CooperationType {
  S = "S",
  M = "M",
}

export enum CutOffSalesRule {
  BeforeDeparture = "BEFORE_DEPARTURE",
  BeforeDayOfDeparture = "BEFORE_DAY_OF_DEPARTURE",
  None = "NONE",
}

export enum DayOfWeek {
  Monday = "MONDAY",
  Tuesday = "TUESDAY",
  Wednesday = "WEDNESDAY",
  Thursday = "THURSDAY",
  Friday = "FRIDAY",
  Saturday = "SATURDAY",
  Sunday = "SUNDAY",
}

export type Filter = {
  flixLineCode?: InputMaybe<Scalars["String"]["input"]>;
  hideCompletedRides?: InputMaybe<Scalars["Boolean"]["input"]>;
  departureStation?: InputMaybe<Scalars["String"]["input"]>;
  departureTime?: InputMaybe<Scalars["String"]["input"]>;
  departureDays?: InputMaybe<Array<DayOfWeek>>;
};

export type Line = {
  __typename?: "Line";
  id: Scalars["ID"]["output"];
  code: Scalars["String"]["output"];
  concessionOwnerId: Scalars["String"]["output"];
  cooperationType: CooperationType;
  cutOffSalesDuration: Scalars["String"]["output"];
  cutOffSalesRule: CutOffSalesRule;
};

export type Mutation = {
  __typename?: "Mutation";
  cancelRide: Ride;
  adjustSeatsOnOffer: Array<Ride>;
  stopSales: Array<Ride>;
  restartSales: Array<Ride>;
  updateOrderPassengerStatus: Array<RidePassenger>;
  updatePassengerStatus: RidePassenger;
};

export type MutationCancelRideArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationAdjustSeatsOnOfferArgs = {
  rides: Array<Scalars["ID"]["input"]>;
  expectedSeatsOnOffer: Scalars["Int"]["input"];
};

export type MutationStopSalesArgs = {
  rides: Array<Scalars["ID"]["input"]>;
};

export type MutationRestartSalesArgs = {
  rides: Array<Scalars["ID"]["input"]>;
};

export type MutationUpdateOrderPassengerStatusArgs = {
  rideId: Scalars["ID"]["input"];
  orderId: Scalars["ID"]["input"];
};

export type MutationUpdatePassengerStatusArgs = {
  ridePassengerId: Scalars["ID"]["input"];
  actionType: PassengerUpdateAction;
};

export enum OrderStatus {
  Active = "ACTIVE",
  Canceled = "CANCELED",
}

export type Partner = {
  __typename?: "Partner";
  name: Scalars["String"]["output"];
  concessionOwnerId: Scalars["String"]["output"];
  contractPartnerId: Scalars["String"]["output"];
  dataPrivacyContractSigned: Scalars["Boolean"]["output"];
  bookingInformationConfiguration: BookingInformationConfiguration;
};

export type Passenger = {
  __typename?: "Passenger";
  firstName: Scalars["String"]["output"];
  lastName: Scalars["String"]["output"];
  phone?: Maybe<Scalars["String"]["output"]>;
};

export enum PassengerInformationField {
  Name = "NAME",
  Phone = "PHONE",
  EMail = "E_MAIL",
  Nationality = "NATIONALITY",
  TicketType = "TICKET_TYPE",
  Gender = "GENDER",
  Birthday = "BIRTHDAY",
  IdentityDocument = "IDENTITY_DOCUMENT",
  IdentityDocumentNumber = "IDENTITY_DOCUMENT_NUMBER",
  SeatLabels = "SEAT_LABELS",
  Luggage = "LUGGAGE",
  Wheelchair = "WHEELCHAIR",
  CheckInStatus = "CHECK_IN_STATUS",
}

export enum PassengerUpdateAction {
  CheckIn = "CHECK_IN",
  CheckOut = "CHECK_OUT",
}

export type PowerBiDetailsResponse = {
  __typename?: "PowerBiDetailsResponse";
  embedUrl: Scalars["String"]["output"];
  embedToken: Scalars["String"]["output"];
  tokenExpiry: Scalars["String"]["output"];
};

export type Query = {
  __typename?: "Query";
  findBookings: Array<BookingInformationResponse>;
  findAllPartners: Array<Partner>;
  findPartner: Partner;
  getPowerBiEmbeddedReport: PowerBiDetailsResponse;
  findRidesByDeparture: Array<Ride>;
  getRide: Ride;
};

export type QueryFindBookingsArgs = {
  start: Scalars["String"]["input"];
  end: Scalars["String"]["input"];
  filter?: InputMaybe<Filter>;
  contractPartnerId: Scalars["ID"]["input"];
};

export type QueryFindPartnerArgs = {
  contractPartnerId: Scalars["ID"]["input"];
};

export type QueryGetPowerBiEmbeddedReportArgs = {
  reportUuid: Scalars["ID"]["input"];
  contractPartnerUuid: Scalars["ID"]["input"];
};

export type QueryFindRidesByDepartureArgs = {
  start: Scalars["String"]["input"];
  end: Scalars["String"]["input"];
  filter?: InputMaybe<Filter>;
  contractPartnerId: Scalars["ID"]["input"];
};

export type QueryGetRideArgs = {
  id: Scalars["ID"]["input"];
};

export type Ride = {
  __typename?: "Ride";
  id: Scalars["ID"]["output"];
  departure: Scalars["String"]["output"];
  status: Scalars["String"]["output"];
  tripNumber: Scalars["Int"]["output"];
  circulationId: Scalars["String"]["output"];
  line: Line;
  partner?: Maybe<Partner>;
  capacity: Capacity;
  rideCancellationAllowed: Scalars["Boolean"]["output"];
  lastModified?: Maybe<Scalars["String"]["output"]>;
  lastModifiedUsername: Scalars["String"]["output"];
  cutOffSalesTime: Scalars["String"]["output"];
  departureStation: Station;
  route: Array<RouteEntry>;
  localDeparture: Scalars["String"]["output"];
  cancellationStatus: CancellationStatus;
  cancellationDate?: Maybe<Scalars["String"]["output"]>;
  saleStatus: RideSaleStatus;
  numberOfPassengers: Scalars["Int"]["output"];
  salesStopNotPossibleReasons: Array<CapacityManagementNotPossibleReasons>;
  salesRestartNotPossibleReasons: Array<CapacityManagementNotPossibleReasons>;
  ridePassengers: Array<RidePassenger>;
  checkInAllowed: Scalars["Boolean"]["output"];
};

export type RidePassenger = {
  __typename?: "RidePassenger";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  status: RidePassengerStatus;
  checkInStatus: CheckInStatus;
  ticketId: Scalars["ID"]["output"];
  departureStationId: Scalars["ID"]["output"];
};

export enum RidePassengerStatus {
  Paid = "PAID",
  Rebooked = "REBOOKED",
  Canceled = "CANCELED",
}

export enum RideSaleStatus {
  OnSale = "ON_SALE",
  Arrived = "ARRIVED",
  SaleStopped = "SALE_STOPPED",
  Canceled = "CANCELED",
}

export type RouteEntry = {
  __typename?: "RouteEntry";
  station: Station;
  city: City;
  time: StationTime;
};

export type SegmentCapacityEntry = {
  __typename?: "SegmentCapacityEntry";
  from: Station;
  to: Station;
  placeAvailable: Scalars["Int"]["output"];
  placeBooked: Scalars["Int"]["output"];
  placeReserved: Scalars["Int"]["output"];
};

export type Station = {
  __typename?: "Station";
  id: Scalars["ID"]["output"];
  code: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  countryCode: Scalars["String"]["output"];
  latitude: Scalars["String"]["output"];
  longitude: Scalars["String"]["output"];
  timezone: Scalars["String"]["output"];
};

export type StationTime = {
  __typename?: "StationTime";
  timeZone: Scalars["String"]["output"];
  arrival?: Maybe<Scalars["String"]["output"]>;
  departure?: Maybe<Scalars["String"]["output"]>;
  localDeparture?: Maybe<Scalars["String"]["output"]>;
  localArrival?: Maybe<Scalars["String"]["output"]>;
};

export enum TripInformationField {
  LineCode = "LINE_CODE",
  TravelDate = "TRAVEL_DATE",
  DepartureTime = "DEPARTURE_TIME",
  ArrivalTime = "ARRIVAL_TIME",
  DepartureAndArrivalStation = "DEPARTURE_AND_ARRIVAL_STATION",
  InterconnectionTrip = "INTERCONNECTION_TRIP",
}
