import { Breakpoint, ResponsiveRenderer } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import CompletedRideFilter from "../../../../shared/components/completedRideFilter/CompletedRideFilter";
import getFindRidesQueryVars from "../../../../shared/helpers/getFindRidesQueryVars/getFindRidesQueryVars";
import useRideFilter, {
  rideFilterVar,
} from "../../../../state/useRideFilter/useRideFilter";
import useSelectedRides, {
  selectedRidesVar,
} from "../../../../state/useSelectedRides/useSelectedRides";
import { useFindRidesForRideActionsQuery } from "../../api/operations.generated";
import hasUserPermissionToChangeRides from "../../helpers/hasUserPermissionToChangeRides/hasUserPermissionToChangeRides";
import BatchOperationsDropdown from "../../ui/batchOperationsDropdown/BatchOperationsDropdown";
import RideActionsSummary from "../../ui/rideActionsSummary/RideActionsSummary";
import * as css from "./RideActions.scss";

const RideActions: React.FC = () => {
  const [selectedRides] = useSelectedRides(selectedRidesVar);
  const [rideFilter] = useRideFilter(rideFilterVar);

  const { data, loading } = useFindRidesForRideActionsQuery({
    variables: getFindRidesQueryVars(rideFilter, false, false),
    fetchPolicy: process.env.NODE_ENV === "test" ? "network-only" : "cache-only",
  });

  return (
    <div className={css.wrapper}>
      <div>
        <RideActionsSummary
          countFiltered={
            data?.findRidesByDeparture ? data.findRidesByDeparture.length : 0
          }
          countSelected={selectedRides.length}
          loading={loading}
        />
      </div>
      <ResponsiveRenderer
        data-id="ride-actions-responsive-renderer"
        hidden={[
          Breakpoint.XXS,
          Breakpoint.XS,
          Breakpoint.SM,
          Breakpoint.MD,
          Breakpoint.LG,
        ]}
      >
        <div>
          {data?.findRidesByDeparture &&
            data.findRidesByDeparture.length > 0 &&
            hasUserPermissionToChangeRides() && (
              <BatchOperationsDropdown
                selectedRides={data.findRidesByDeparture.filter((ride) =>
                  selectedRides.includes(ride.id)
                )}
              />
            )}
        </div>
        <div>
          <CompletedRideFilter />
        </div>
      </ResponsiveRenderer>
    </div>
  );
};

export default RideActions;
