import { IconAttention } from "@flixbus/honeycomb-icons-react";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ConfirmationPopup from "../../../../shared/components/confirmationPopup/ConfirmationPopup";

export enum RideActionType {
  STOP_SALES = "STOP_SALES",
  RESTART_SALES = "RESTART_SALES",
  CANCELLATION = "CANCELLATION",
}

export type RideActionPopupProps = {
  type: RideActionType;
  onConfirm: () => void;
  onCancel: () => void;
  submitLoading?: boolean;
};

const RideActionPopup: React.FC<RideActionPopupProps> = ({
  type,
  onConfirm,
  onCancel,
  submitLoading,
  children,
}) => {
  const { formatMessage } = useIntl();

  let titleText;
  let confirmationContent;

  switch (type) {
    case RideActionType.STOP_SALES:
      titleText = "ride.stopSales.confirmationTitle";
      confirmationContent = "ride.stopSales.confirmationContent";
      break;
    case RideActionType.RESTART_SALES:
      titleText = "ride.restartSale.confirmationTitle";
      confirmationContent = "ride.restartSale.confirmationContent";
      break;
    case RideActionType.CANCELLATION:
      titleText = "ride.rideCancellation.confirmationTitle";
      confirmationContent = "ride.rideCancellation.confirmationContent";
      break;
    default:
      titleText = "";
      confirmationContent = "";
  }

  return (
    <ConfirmationPopup
      active
      titleIcon={IconAttention}
      titleText={formatMessage({ id: titleText })}
      confirmButtonText={formatMessage({ id: "ride.stopSales.confirmationSubmit" })}
      onConfirm={onConfirm}
      onCancel={onCancel}
      submitLoading={submitLoading}
    >
      <FormattedMessage id={confirmationContent} />
      {children}
    </ConfirmationPopup>
  );
};

export default RideActionPopup;
