import { Tag } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { OrderStatus } from "../../../shared/types/schema";

type Props = {
  orderStatus: OrderStatus;
};

const BookingTag: React.FC<Props> = ({ orderStatus }) => (
  <Tag
    appearance={orderStatus === OrderStatus.Active ? "success" : "danger"}
    display={orderStatus !== OrderStatus.Active ? "outlined" : undefined}
    small
  >
    <FormattedMessage id={`general.${orderStatus}`} />
  </Tag>
);

export default BookingTag;
