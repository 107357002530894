import { Ride } from "../../../../shared/types/schema";

export default (ridesTotal: Array<Ride["id"]>, ridesSuccess: Array<Ride["id"]>) => {
  const ridesFail: Array<Ride["id"]> = [];

  if (ridesSuccess.length < ridesTotal.length) {
    ridesTotal.forEach((ride) => {
      if (!ridesSuccess.includes(ride)) {
        ridesFail.push(ride);
      }
    });
  }

  return ridesFail;
};
