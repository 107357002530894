@import "variables";

.isWide [class^="hcr-popup__body"] {
  @include on-bp(md) {
    width: 750px;
  }
}

.allowOverflow [class^="hcr-popup__body"] {
  overflow-y: unset;
}

.contentLeft [class^="hcr-popup"] {
  text-align: left;
}

.hasReducedPaddingOnSmallScreen [class^="hcr-popup__body"] {
  padding-left: $spacing-2;
  padding-right: $spacing-2;
}

.titleIcon {
  padding-bottom: $spacing-2;
}

.content {
  padding-bottom: $spacing-3;
}
