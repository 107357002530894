import { Skeleton } from "@flixbus/honeycomb-react";
import React from "react";
import * as css from "./PaxListLoading.scss";

const PaxListLoading: React.FC = () => {
  return (
    <div>
      <Skeleton extraClasses={css.headline} width="sm" />
      <Skeleton extraClasses={css.paxCount} width="xs" />
      <Skeleton extraClasses={css.input} />

      {Array.from(Array(9)).map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Skeleton key={i} extraClasses={css.station} />
      ))}

      <div className={css.buttonWrapper}>
        <Skeleton height="lg" width="xs" inline />
        <Skeleton height="lg" width="xs" inline />
      </div>
    </div>
  );
};

export default PaxListLoading;
