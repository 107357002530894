import * as React from "react";
import { useIntl } from "react-intl";
import GreyResultBackground from "../../shared/components/greyResultBackground/GreyResultBackground";
import PartnerHeadline from "../../shared/components/partnerHeadline/PartnerHeadline";
import RideFilter from "../rideFilter/RideFilter";
import RideActions from "./containers/rideActions/RideActions";
import RideTableBody from "./containers/rideTableBody/RideTableBody";
import RideTableHeader from "./containers/rideTableHeader/RideTableHeader";
import {
  Grid,
  GridActionRow,
  GridTableHeaderRow,
  GridTableBodyRow,
} from "./ui/grid/Grid";

const RideOverview: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <PartnerHeadline headline={formatMessage({ id: "navigation.capacityManager" })} />
      <RideFilter showCompletedRideFilterInPopup />
      <GreyResultBackground>
        <Grid>
          <GridActionRow>
            <RideActions />
          </GridActionRow>
          <GridTableHeaderRow>
            <RideTableHeader />
          </GridTableHeaderRow>
          <GridTableBodyRow>
            <RideTableBody />
          </GridTableBodyRow>
        </Grid>
      </GreyResultBackground>
    </>
  );
};

export default RideOverview;
