@import "variables";

.border {
  position: absolute;
  width: 100%;
  left: 0;
  height: 1px;
  background: $grayscale-30-color;
  top: $filter-height;
  @include on-bp(md) {
    top: $filter-height-md;
  }
  @include on-bp(xl) {
    top: $filter-height-xl;
  }
}

.filterWrapper {
  width: 100%;
  position: relative;
  margin-bottom: $spacing-2;
  display: inline-flex;
  column-gap: $spacing-2;
  justify-content: flex-start;

  @include on-bp(xl) {
    justify-content: space-between;
    margin-bottom: $spacing-4;
  }

  > .periodFilter {
    width: 225px;
  }

  > .departureDayFilter {
    width: auto;
  }

  > .lineFilter {
    width: 150px;
  }

  > .stationFilter {
    width: 185px;
  }

  > .timeFilter {
    width: 150px;
  }
}
