import { useReactiveVar } from "@apollo/client";
import { deleteAllNotifications } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import getFindRidesQueryVars from "../../../../shared/helpers/getFindRidesQueryVars/getFindRidesQueryVars";
import { removeAllRideUpdateErrors } from "../../../../state/rideUpdateErrors/handleRideUpdateErrors";
import rideUpdateErrorsVar from "../../../../state/rideUpdateErrors/reactiveVariable";
import useRideFilter, {
  rideFilterVar,
} from "../../../../state/useRideFilter/useRideFilter";
import { useFindRidesQuery } from "../../api/operations.generated";
import getRideUpdateErrorsPerRide from "../../helpers/getRideUpdateErrorsPerRide/getRideUpdateErrorsPerRide";
import RideItemInfoText from "../../ui/rideItemInfoText/RideItemInfoText";
import RideItemSkeleton from "../../ui/rideItemSkeleton/RideItemSkeleton";
import { MemoizedRideTableRow } from "../rideTableRow/RideTableRow";

const RideTableBody: React.FC = () => {
  const [rideFilter] = useRideFilter(rideFilterVar);
  const rideUpdateErrors = useReactiveVar(rideUpdateErrorsVar);

  React.useEffect(() => {
    return () => {
      deleteAllNotifications();
      removeAllRideUpdateErrors();
    };
  }, []);

  const { data, loading, error } = useFindRidesQuery({
    variables: getFindRidesQueryVars(rideFilter, false, false),
    pollInterval: 1000 * 30,
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <RideItemSkeleton />;
  }

  if (error) {
    return <RideItemInfoText isError />;
  }

  if (data?.findRidesByDeparture) {
    if (data.findRidesByDeparture.length > 0) {
      return (
        <>
          {data.findRidesByDeparture.map((ride) => {
            return (
              <MemoizedRideTableRow
                ride={ride}
                key={ride.id}
                rideUpdateErrors={getRideUpdateErrorsPerRide(rideUpdateErrors, ride.id)}
              />
            );
          })}
        </>
      );
    }
    return <RideItemInfoText />;
  }

  return <></>;
};

export default RideTableBody;
