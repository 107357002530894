import { Input } from "@flixbus/honeycomb-react";
import * as React from "react";

type Props = {
  label: string;
  info: string;
};

const InputApiError: React.FC<Props> = ({ info, label }) => {
  return <Input id={`error-${label}`} label={label} readOnly valid={false} info={info} />;
};

export default InputApiError;
