@import "variables";

.headline,
.paxCount {
  height: 24px;
}

.headline,
.paxCount,
.station {
  margin-bottom: $spacing-2;
}

.headline {
  margin-top: $spacing-2;
}
.input {
  height: 44px;
  margin-bottom: $spacing-3;
  @include on-bp(xl) {
    height: 34px;
  }
}

.station {
  height: 21px;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  width: calc(100vw - $spacing-4);
  @include max-content-width;

  @include on-bp(xl) {
    margin-top: $spacing-8;
    position: static;
  }
}
