import {
  Icon,
  IconBulb,
  IconBulbSolid,
  IconRefresh,
} from "@flixbus/honeycomb-icons-react";
import { Button } from "@flixbus/honeycomb-react";
import cx from "classnames";
import QrScanner from "qr-scanner";
import React from "react";
import * as css from "../../qrScannerPanel/QrScannerPanel.scss";

type QrScannerInterface = {
  hasFlash: () => Promise<boolean>;
  isFlashOn: () => boolean;
  toggleFlash: () => Promise<void>;
  setCamera: (cameraFacingMode: string) => Promise<void>;
};

type Props = {
  qrScanner: QrScannerInterface;
};

const QrScannerCameraActions: React.FC<Props> = ({ qrScanner }) => {
  const [showButtons, setShowButtons] = React.useState<boolean>(true);
  const [cameraFacingMode, setCameraFacingMode] = React.useState<"user" | "environment">(
    "environment"
  );
  const [hasFlash, setHasFlash] = React.useState<boolean>(false);
  const [isFlashOn, setIsFlashOn] = React.useState<boolean>(false);
  const [hasMultipleCameras, setHasMultipleCameras] = React.useState<boolean | null>(
    null
  );

  const updateFlashState = (then: () => void = () => {}) => {
    qrScanner
      .hasFlash()
      .then((itHasFlash) => {
        setHasFlash(itHasFlash);
        setIsFlashOn(qrScanner.isFlashOn() || false);
      })
      .catch(() => {
        setHasFlash(false);
      })
      .finally(then);
  };

  const toggleFlash = () => {
    qrScanner
      .toggleFlash()
      .then(() => {
        setIsFlashOn(qrScanner.isFlashOn() || false);
      })
      .catch(() => {});
  };

  const toggleCameraFacingMode = () => {
    const newCameraFacingMode =
      cameraFacingMode === "environment" ? "user" : "environment";

    setShowButtons(false); // hide buttons until camera actually switched

    qrScanner
      .setCamera(newCameraFacingMode)
      .then(() => {
        setCameraFacingMode(newCameraFacingMode);
      })
      .catch(() => {})
      .finally(() => {
        updateFlashState(() => {
          setShowButtons(true);
        });
      });
  };

  if (hasMultipleCameras === null) {
    QrScanner.listCameras(false)
      .then((cameras) => {
        setHasMultipleCameras(cameras.length > 1);
        updateFlashState();
      })
      .catch(() => {});
  }

  return (
    <div className={cx(css.cameraActions, cameraFacingMode === "user" && css.userCamera)}>
      {showButtons && hasFlash && (
        <Button appearance="link" onClick={toggleFlash} data-testid="toggle-flash-button">
          {isFlashOn ? (
            <Icon
              extraClasses={css.flashIconOn}
              InlineIcon={IconBulbSolid}
              data-testid="flash-on-icon"
            />
          ) : (
            <Icon InlineIcon={IconBulb} data-testid="flash-off-icon" />
          )}
        </Button>
      )}
      {showButtons && hasMultipleCameras && (
        <Button
          appearance="link"
          extraClasses={css.cameraIcon}
          onClick={toggleCameraFacingMode}
          data-testid="switch-camera-button"
        >
          <Icon InlineIcon={IconRefresh} />
        </Button>
      )}
    </div>
  );
};

export default QrScannerCameraActions;
