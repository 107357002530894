import { Skeleton } from "@flixbus/honeycomb-react";
import { Breakpoint, ResponsiveRenderer } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import * as css from "./RideItemSkeleton.scss";

const RideItemSkeleton: React.FC = () => (
  <div className={css.wrapper}>
    <Skeleton extraClasses={css.skeleton} />
    <Skeleton extraClasses={css.skeleton} />
    <ResponsiveRenderer
      hidden={[
        Breakpoint.XXS,
        Breakpoint.XS,
        Breakpoint.SM,
        Breakpoint.MD,
        Breakpoint.LG,
      ]}
    >
      <Skeleton data-id="desktop-1" extraClasses={css.skeleton} />
      <Skeleton data-id="desktop-2" extraClasses={css.skeleton} />
      <Skeleton data-id="desktop-3" extraClasses={css.skeleton} />
      <Skeleton data-id="desktop-4" extraClasses={css.skeleton} />
      <Skeleton data-id="desktop-5" extraClasses={css.skeleton} />
    </ResponsiveRenderer>
  </div>
);

export default RideItemSkeleton;
