import * as React from "react";
import getPassengerNameText from "../../helpers/getPassengerNameText/getPassengerNameText";
import * as css from "./PassengerNames.scss";

type Props = {
  names: Array<string>;
  orderId: string;
  totalPassengers: number;
  limit?: number;
};

const PassengerNames: React.FC<Props> = ({ names, orderId, totalPassengers, limit }) => (
  <>
    {names.map((name, i, arr) => (
      // eslint-disable-next-line react/no-array-index-key
      <p key={`${orderId}-${i}`} className={css.name}>
        {getPassengerNameText(name, i === arr.length - 1, totalPassengers, limit)}
      </p>
    ))}
  </>
);

export default PassengerNames;
