import { Icon, IconDocument } from "@flixbus/honeycomb-icons-react";
import { Button } from "@flixbus/honeycomb-react";
import { mpoPathnames as pathnames } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import useRideFilter, {
  rideFilterVar,
} from "../../../../state/useRideFilter/useRideFilter";
import generateRideFilter from "../../helpers/generateRideFilter/generateRideFilter";
import * as css from "./DownloadPaxInfoButton.scss";

export type RideItemProps = {
  dateTime: string;
  lineCode: string;
  departureStation: string;
};

const DownloadPaxInfoButton: React.FC<RideItemProps> = ({
  dateTime,
  lineCode,
  departureStation,
}) => {
  const navigate = useNavigate();
  const [rideFilter, , setPeriodAndRideFilter] = useRideFilter(rideFilterVar);

  return (
    <Button
      display="block"
      extraClasses={css.button}
      onClick={() => {
        setPeriodAndRideFilter(
          generateRideFilter(rideFilter, dateTime, lineCode, departureStation)
        );
        navigate(pathnames.bookingInfo);
      }}
    >
      <Icon InlineIcon={IconDocument} />
      <FormattedMessage id="navigation.bookingInfo.button" />
    </Button>
  );
};

export default DownloadPaxInfoButton;
