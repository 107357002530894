@import "variables";

.editButton {
  position: absolute;
  right: calc($spacing-2 + $spacing-2);
}

.grid {
  display: grid;
  grid-template-areas:
    "statusAndLine"
    "departure"
    "route"
    "countPax"
    "seats";
  row-gap: calc($spacing-1 + $spacing-half);

  @include on-bp(sm) {
    grid-template-areas:
      "statusAndLine ."
      "departure route"
      "countPax seats";
  }

  @include on-bp(lg) {
    grid-template-areas:
      "statusAndLine . . ."
      "departure route countPax seats";
  }
}

.statusAndLine {
  grid-area: statusAndLine;
  margin-bottom: $spacing-half;

  .line {
    color: $content-secondary-color;
    margin-left: $spacing-2;
  }
  > div {
    display: inline-block;
  }
}

.departure {
  grid-area: departure;
}

.route {
  grid-area: route;
  width: fit-content;
}

.countPax {
  grid-area: countPax;
}

.seats {
  grid-area: seats;
}
