import { Infobox, Spinner } from "@flixbus/honeycomb-react";
import {
  Feature,
  hasUserPermission,
  localizeDateWithWeekday,
} from "@flixbus-phx/marketplace-common";
import cx from "classnames";
import { format } from "date-fns";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import {
  CancellationStatus,
  CapacityManagementNotPossibleReasons,
} from "../../../../shared/types/schema";
import { RideUpdate } from "../../../../state/rideUpdateErrors/types";
import { FindRidesQuery, useLoadCapacityLazyQuery } from "../../api/operations.generated";
import generateSegmentsForChart from "../../helpers/generateSegementsForChart/generateSegmentsForChart";
import hasUserPermissionToChangeRides from "../../helpers/hasUserPermissionToChangeRides/hasUserPermissionToChangeRides";
import isStopSalesButtonShown from "../../helpers/isStopSalesButtonShown/isStopSalesButtonShown";
import Accordion from "../../ui/accordion/Accordion";
import CapacityForm from "../../ui/capacityForm/CapacityForm";
import Chart from "../../ui/chart/Chart";
import ChartLegend from "../../ui/chartLegend/ChartLegend";
import DownloadPaxInfoButton from "../../ui/downloadPaxInfoButton/DownloadPaxInfoButton";
import PaxListButton from "../../ui/paxListButton/PaxListButton";
import RideCancellationButton from "../../ui/rideCancellationButton/RideCancellationButton";
import RideCheckbox from "../../ui/rideCheckbox/RideCheckbox";
import RideConnection from "../../ui/rideConnection/RideConnection";
import RideInfoSection from "../../ui/rideInfoSection/RideInfoSection";
import RideSaleStatusTag from "../../ui/rideSaleStatusTag/RideSaleStatusTag";
import RestartSalesButton from "../restartSalesButton/RestartSalesButton";
import StopSalesButton from "../stopSalesButton/StopSalesButton";
import * as css from "./RideAccordion.scss";

export type RideAccordionProps = {
  ride: FindRidesQuery["findRidesByDeparture"][number];
  onSeatsOnOfferChange: (seatsOnOfferExpected: number) => void;
  seatsOnOfferError?: boolean;
  onStopSales: () => void;
  onRestartSales: () => void;
  onRideCancellation: () => void;
  rideUpdateErrors?: Array<RideUpdate>;
};

const RideAccordion: React.FC<RideAccordionProps> = ({
  ride,
  onSeatsOnOfferChange,
  seatsOnOfferError = false,
  onStopSales,
  onRestartSales,
  onRideCancellation,
  rideUpdateErrors,
}) => {
  const [query, { data: capacityData, loading: capacityLoading, error: capacityError }] =
    useLoadCapacityLazyQuery({
      // fetch policies are used to prevent unexpected firing of query on re-render, see https://github.com/apollographql/apollo-client/issues/7484#issuecomment-925314635
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    });

  const createMarkup = (content: string) => {
    return { __html: content };
  };

  const showCheckbox = hasUserPermissionToChangeRides();

  const departure = ride.route[0].time.localDeparture
    ? localizeDateWithWeekday(new Date(ride.route[0].time.localDeparture))
    : "";

  return (
    <Accordion
      extraClasses={cx(
        css.accordion,
        rideUpdateErrors?.length && css.accordionError,
        ride.status === "preview" && css.accordionPreview
      )}
      headerSection={
        <div className={cx(css.rideGrid, !showCheckbox && css.rideGridViewOnly)}>
          {showCheckbox && (
            <div>
              <RideCheckbox rideId={ride.id} />
            </div>
          )}
          <div data-id="saleStatus" className={css.center}>
            <RideSaleStatusTag saleStatus={ride.saleStatus} />
          </div>
          <div data-id="line-code" className={css.center}>
            {ride.line.code}
          </div>
          <div data-id="date" className={css.center}>
            {departure}
          </div>
          <div data-id="ride-connection" className={cx(css.pushLeft, css.rideConnection)}>
            <RideConnection rideRoute={ride.route} />
          </div>
          <div data-id="numberOfPassengers" className={cx(css.center, css.orderSummary)}>
            {ride.numberOfPassengers}
            <PaxListButton rideId={ride.id} />
          </div>
          <div data-id="seatsOnOffer" className={css.center}>
            {ride.capacity.isCapacityInitiated ? (
              ride.capacity.seatsOnOfferExpected
            ) : (
              <FormattedMessage id="general.notAvailable" />
            )}
          </div>
          <div
            data-id="lastModified"
            className={css.lastModifiedCell}
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={createMarkup(`
              ${ride.lastModifiedUsername}
              <br/>
              ${
                ride.lastModified
                  ? format(Date.parse(ride.lastModified), "yyyy-MM-dd HH:mm")
                  : ""
              }              
          `)}
          />
        </div>
      }
      onOpen={() => {
        query({ variables: { id: ride.id } });
      }}
    >
      <div className={css.hiddenSectionWrapper}>
        <div className={css.hiddenSection}>
          <RideInfoSection
            rideId={ride.id}
            cancellationStatus={ride.cancellationStatus}
            salesStopNotPossibleReasons={ride.salesStopNotPossibleReasons}
            rideUpdateErrors={rideUpdateErrors}
          />

          <div className={css.hiddenSectionGrid}>
            {ride.capacity.isCapacityInitiated && !capacityError && (
              <>
                <div className={css.hiddenSectionGridLeft}>
                  {capacityData?.getRide.capacity && !capacityLoading && (
                    <Chart
                      segments={generateSegmentsForChart(
                        ride.route,
                        capacityData.getRide.capacity.segmentCapacity
                      )}
                      desiredCapacity={ride.capacity.seatsOnOfferExpected}
                      minimumCapacity={ride.capacity.minSeatsOnOffer}
                      disabled={ride.cancellationStatus !== CancellationStatus.None}
                    />
                  )}
                  {capacityLoading && (
                    <div className={css.spinnerGrid}>
                      <Spinner />
                    </div>
                  )}
                </div>
                <div className={css.hiddenSectionGridRightTop}>
                  <CapacityForm
                    capacityOnOffer={ride.capacity.seatsOnOfferExpected}
                    onCapacityChanged={onSeatsOnOfferChange}
                    minCapacity={ride.capacity.minSeatsOnOffer}
                    decreaseNotPossibleReasons={
                      hasUserPermission(Feature.EDIT_CAPACITY_MANAGER_CAPACITY)
                        ? ride.capacity.decreaseNotPossibleReasons
                        : [
                            CapacityManagementNotPossibleReasons.CapacityManagementNotAllowed,
                          ]
                    }
                    increaseNotPossibleReasons={
                      hasUserPermission(Feature.EDIT_CAPACITY_MANAGER_CAPACITY)
                        ? ride.capacity.increaseNotPossibleReasons
                        : [
                            CapacityManagementNotPossibleReasons.CapacityManagementNotAllowed,
                          ]
                    }
                    hasError={seatsOnOfferError}
                  />
                </div>
                <div className={css.hiddenSectionGridRightCenter}>
                  <div>
                    {ride.route[0].time.localDeparture && (
                      <div className={css.rideItemActionButton}>
                        <DownloadPaxInfoButton
                          dateTime={ride.route[0].time.localDeparture}
                          lineCode={ride.line.code}
                          departureStation={ride.route[0].station.name}
                        />
                      </div>
                    )}

                    {hasUserPermission(
                      Feature.EDIT_CAPACITY_MANAGER_STOP_OR_RESTART_SALES
                    ) && (
                      <div className={css.rideItemActionButton}>
                        {isStopSalesButtonShown(ride.salesStopNotPossibleReasons) ? (
                          <StopSalesButton
                            onStopSales={onStopSales}
                            salesStopNotPossibleReasons={[
                              ...ride.salesStopNotPossibleReasons,
                            ]}
                          />
                        ) : (
                          <RestartSalesButton
                            onRestartSales={onRestartSales}
                            salesRestartNotPossibleReasons={
                              ride.salesRestartNotPossibleReasons
                            }
                          />
                        )}
                      </div>
                    )}
                    {hasUserPermission(Feature.EDIT_CAPACITY_MANAGER_CANCEL_RIDE) && (
                      <div className={css.rideItemActionButton}>
                        <RideCancellationButton
                          disabled={!ride.rideCancellationAllowed}
                          onRideCancellation={onRideCancellation}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className={css.hiddenSectionGridBottom}>
                  {ride.cancellationStatus === CancellationStatus.None ? (
                    <ChartLegend />
                  ) : (
                    <ChartLegend seatsCanceled />
                  )}
                </div>
              </>
            )}
          </div>
          {(!ride.capacity.isCapacityInitiated || capacityError) && (
            <Infobox data-id="capacityNotAvailable" appearance="warning">
              <FormattedMessage id="ride.error.noCapacityInformation" />
            </Infobox>
          )}
        </div>
      </div>
    </Accordion>
  );
};

export default RideAccordion;
