import { Icon, IconClose, IconComponent } from "@flixbus/honeycomb-icons-react";
import { Box } from "@flixbus/honeycomb-react";
import cx from "classnames";
import * as React from "react";
import * as css from "./RideInfo.scss";

export type RideInfoProps = {
  icon: IconComponent;
  message: string;
  onClose?: () => void;
  isError?: boolean;
};

const RideInfo: React.FC<RideInfoProps> = ({ icon, message, onClose, isError }) => {
  return (
    <div className={css.boxWrapper}>
      <Box highlighted extraClasses={css.box}>
        <Icon
          data-id="info-icon"
          InlineIcon={icon}
          extraClasses={cx(css.infoIcon, isError && css.infoIconError)}
        />
        <span data-id="info-text" className={css.infoText}>
          {message}
        </span>
        {onClose && (
          <Icon
            data-id="close-icon"
            InlineIcon={IconClose}
            extraClasses={css.closeIcon}
            onClick={onClose}
          />
        )}
      </Box>
    </div>
  );
};

export default RideInfo;
