@import "variables";

.base {
  display: grid;
  row-gap: $spacing-2;
  column-gap: $spacing-2;
}

.grid {
  grid-template-areas:
    "statusLineOrderId"
    "travelDate"
    "connection"
    "passengerInfo";

  @include on-bp(sm) {
    grid-template-areas:
      "statusLineOrderId ."
      "travelDate connection"
      "passengerInfo .";
    grid-template-columns: auto 50%;
  }
}

.gridExtended {
  grid-template-areas:
    "statusLineOrderId"
    "travelDate"
    "connection"
    "passengerInfo"
    "contacts"
    "interconnection";

  @include on-bp(sm) {
    grid-template-areas:
      "statusLineOrderId ."
      "travelDate connection"
      "passengerInfo contacts"
      "interconnection .";
    grid-template-columns: auto 50%;
  }
}

.statusLineOrderId {
  grid-area: statusLineOrderId;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: $spacing-2;
}

.travelDate {
  grid-area: travelDate;
}

.connection {
  grid-area: connection;
  max-width: 350px;
}

.passengerInfo {
  grid-area: passengerInfo;
}

.contacts {
  grid-area: contacts;
}

.interconnection {
  grid-area: interconnection;
}

.label {
  color: $content-secondary-color;
  font-size: $font-size-small;
}

.box {
  padding: $spacing-1 0;
  margin-top: calc($spacing-1 + $spacing-half);
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: $spacing-1;
}

.lineCode {
  color: $content-secondary-color;
}

.passengerInfoWrapper {
  display: flex;
  flex-direction: row;
  gap: $spacing-2;
}

.nameWrapper {
  display: flex;
  flex-direction: column;
}
