import { Skeleton } from "@flixbus/honeycomb-react";
import { autocompleteStyles } from "@flixbus-phx/marketplace-common";
import cx from "classnames";
import * as React from "react";
import Select from "react-select";
import { AutocompleteOption } from "../../helpers/autocompleteHelper/AutocompleteOption";
import * as css from "./Autocomplete.scss";
import DropdownIndicator from "./ui/dropdownIndicator/DropdownIndicator";

type Props = {
  initialValue?: string;
  defaultValue?: string;
  onValueSelected: (value: string) => void;
  options: Array<AutocompleteOption>;
  label: string;
  loading?: boolean;
  isSearchable?: boolean;
};

const Autocomplete: React.FC<Props> = ({
  initialValue = "",
  onValueSelected,
  options,
  label,
  loading = false,
  defaultValue = "",
  isSearchable = true,
}) => {
  if (!loading) {
    return (
      <div className={css.wrapper}>
        <div className={css.labelWrapper}>
          <label
            className={cx(css.label, options.length === 0 && css.disabled)}
            htmlFor={label}
          >
            {label}
          </label>
        </div>
        <Select
          id={label}
          isSearchable={isSearchable}
          defaultValue={{
            value: initialValue,
            label: initialValue.length ? initialValue : defaultValue,
          }}
          options={[{ value: "", label: defaultValue }].concat(options)}
          styles={autocompleteStyles(false, undefined, undefined, undefined, true)}
          isDisabled={options.length === 0}
          onChange={(selectedOption) => {
            onValueSelected((selectedOption as AutocompleteOption).value);
          }}
          components={{ DropdownIndicator }}
        />
      </div>
    );
  }
  return (
    <>
      <Skeleton extraClasses={css.labelSkeleton} Elem="div" width="sm" />
      <Skeleton extraClasses={css.inputSkeleton} Elem="div" />
    </>
  );
};

export default Autocomplete;
