import { Connection } from "@flixbus/honeycomb-react";
import * as React from "react";
import generateStopsForConnectionComponent from "../../../shared/helpers/generateStopsForConnectionComponent/generateStopsForConnectionComponent";
import { FindBookingsQuery } from "../../api/operations.generated";
import * as css from "./BookingConnection.scss";

type Props = {
  booking: FindBookingsQuery["findBookings"][number];
};

const BookingConnection: React.FC<Props> = ({ booking }) => {
  const defaultConnection = (
    <Connection>
      {generateStopsForConnectionComponent(
        booking.departureStation,
        booking.departureTime,
        booking.arrivalStation,
        booking.arrivalTime
      )}
    </Connection>
  );

  if (booking.ride.departureStation.name !== booking.departureStation) {
    return (
      <>
        <Connection extraClasses={css.departureConnection}>
          {generateStopsForConnectionComponent(
            booking.ride.departureStation.name,
            booking.ride.localDeparture
          )}
        </Connection>
        {defaultConnection}
      </>
    );
  }

  return defaultConnection;
};

export default BookingConnection;
