import { Calendar, Input } from "@flixbus/honeycomb-react";
import { localizeDate } from "@flixbus-phx/marketplace-common";
import { add, isBefore } from "date-fns";
import * as React from "react";
import { useIntl } from "react-intl";
import HandleOutsideClick from "../../../../shared/components/handleOutsideClick/HandleOutsideClick";
import {
  getDays,
  defaultEndDate,
  defaultStartDate,
  getMonths,
} from "../../../../shared/helpers/calendarHelper/calendarHelper";
import * as css from "./PeriodPicker.scss";

export type PeriodPickerProps = {
  periodStart: Date;
  periodEnd: Date;
  onChange: (start: Date, end: Date) => void;
};

const PeriodPicker: React.FC<PeriodPickerProps> = ({
  periodStart,
  periodEnd,
  onChange,
}) => {
  const [startDate, setStartDate] = React.useState<Date>();
  const [endDate, setEndDate] = React.useState<Date>();
  const [showCalendar, setshowCalendar] = React.useState(false);
  const { formatMessage } = useIntl();

  const closeCalendarAndSubmitSelection = () => {
    setshowCalendar(false);
    if (startDate && endDate === undefined) {
      onChange(startDate, isBefore(startDate, periodEnd) ? periodEnd : startDate);
    } else if (startDate && endDate) {
      onChange(startDate, endDate);
    } else {
      return;
    }
    setStartDate(undefined);
    setEndDate(undefined);
  };

  React.useEffect(() => {
    if (startDate && endDate) {
      closeCalendarAndSubmitSelection();
    }
  }, [startDate, endDate]);

  const handleDateSelect = (selected: Date) => {
    if (startDate === undefined) {
      setStartDate(selected);
      return;
    }

    if (endDate === undefined) {
      setEndDate(selected);
      return;
    }

    setStartDate(selected);
    setEndDate(undefined);
  };

  const getStartDate = (): Date => {
    if (startDate && endDate === undefined) {
      return startDate;
    }
    return defaultStartDate;
  };

  // This function is handling the maximum selectable period
  const getEndDate = (): Date => {
    if (startDate) {
      return add(startDate, { days: 31 });
    }
    return defaultEndDate;
  };

  const selectedStartDate = startDate || periodStart;
  const selectedEndDate = endDate || periodEnd;

  return (
    <HandleOutsideClick onClickOutside={closeCalendarAndSubmitSelection}>
      <Input
        label={formatMessage({ id: "bookingInfo.filter.period.label" })}
        id="period-input"
        value={`${localizeDate(selectedStartDate)} - ${localizeDate(selectedEndDate)}`}
        readOnly
        onFocus={() => setshowCalendar(true)}
      />
      <Calendar
        hidden={!showCalendar}
        id="period-calendar"
        appearance="compact"
        startDate={getStartDate()}
        endDate={getEndDate()}
        startSelected={selectedStartDate}
        endSelected={selectedEndDate}
        defaultMonth={selectedStartDate}
        handleSelect={handleDateSelect}
        days={getDays()}
        months={getMonths()}
        extraClasses={css.calendar}
      />
    </HandleOutsideClick>
  );
};

export default PeriodPicker;
