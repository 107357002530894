@import "variables";

.wrapper {
  width: 100vw;
  @include max-content-width;
  position: absolute;
  left: 0;
  height: auto;
  padding: 0 $spacing-2 92px $spacing-2;
  overflow: scroll;
  background: $bg-primary-color;
  @include on-bp(xl) {
    height: auto;
    position: static;
    overflow: visible;
    padding: $spacing-2 0 calc($spacing-6 + $spacing-3) 0;
  }
}

// Prevents Scrolling Issues on iOs
body {
  position: relative;
  @include on-bp(md) {
    position: static;
  }
}

.paxCount {
  font-size: $font-size-small;
  color: $content-secondary-color;
}

.input {
  margin-top: $spacing-2;
  margin-bottom: $spacing-3;
}

.icon {
  color: $icon-primary-color;
}
