import * as React from "react";
import { useIntl } from "react-intl";
import ConfirmationPopup from "../../../../shared/components/confirmationPopup/ConfirmationPopup";
import { FindRidesQuery } from "../../api/operations.generated";
import CapacityInput from "../capacityInput/CapacityInput";

export type CapacityChangePopupProps = {
  capacity: FindRidesQuery["findRidesByDeparture"][number]["capacity"];
  onConfirm: (desiredCapacity: number) => void;
  onCancel: () => void;
};

const CapacityChangePopup: React.FC<CapacityChangePopupProps> = ({
  capacity,
  onConfirm,
  onCancel,
  children,
}) => {
  const [userSelectedValue, setUserSelectedValue] = React.useState<number | undefined>(
    undefined
  );
  const { formatMessage } = useIntl();

  const decreaseDisabled = capacity.decreaseNotPossibleReasons.length > 0;
  const increaseDisabled = capacity.increaseNotPossibleReasons.length > 0;

  const isChangeForbidden = () => {
    if (userSelectedValue === undefined) {
      return false;
    }

    // Change is an increase
    if (userSelectedValue > capacity.seatsOnOfferExpected) {
      return increaseDisabled;
    }

    // Change is below minCapacity
    if (capacity.minSeatsOnOffer && userSelectedValue < capacity.minSeatsOnOffer) {
      return true;
    }

    // Change is a decrease
    if (userSelectedValue < capacity.seatsOnOfferExpected) {
      return decreaseDisabled;
    }

    return false;
  };

  const handleValueChange = (value: number) => {
    if (!increaseDisabled || !decreaseDisabled) {
      setUserSelectedValue(value);
    }
  };

  const isConfirmDisabled = () => {
    if (userSelectedValue === undefined) {
      return true;
    }

    if (isChangeForbidden()) {
      return true;
    }

    return userSelectedValue === capacity.seatsOnOfferExpected;
  };

  const applyChanges = () => {
    if (userSelectedValue !== undefined) {
      onConfirm(userSelectedValue);
    }
  };

  return (
    <ConfirmationPopup
      active
      titleText={formatMessage({
        id: "ride.batchUpdate.dropdown.item.adjustSeatsOnOffer",
      })}
      confirmButtonText={formatMessage({ id: "ride.seatsOnOffer.button.apply" })}
      onConfirm={applyChanges}
      onCancel={onCancel}
      confirmButtonDisabled={isConfirmDisabled()}
    >
      <CapacityInput
        onCapacityChanged={handleValueChange}
        capacityOnOffer={
          userSelectedValue !== undefined
            ? userSelectedValue
            : capacity.seatsOnOfferExpected
        }
        hasError={isChangeForbidden()}
        increaseNotPossibleReasons={capacity.increaseNotPossibleReasons}
        decreaseNotPossibleReasons={capacity.decreaseNotPossibleReasons}
        minCapacity={capacity.minSeatsOnOffer}
      />
      {children}
    </ConfirmationPopup>
  );
};

export default CapacityChangePopup;
