// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type FindPartnerQueryVariables = Types.Exact<{
  contractPartnerId: Types.Scalars["ID"]["input"];
}>;

export type FindPartnerQuery = {
  __typename?: "Query";
  findPartner: {
    __typename?: "Partner";
    name: string;
    contractPartnerId: string;
    concessionOwnerId: string;
    dataPrivacyContractSigned: boolean;
  };
};

export const FindPartnerDocument = gql`
  query FindPartner($contractPartnerId: ID!) {
    findPartner(contractPartnerId: $contractPartnerId) {
      name
      contractPartnerId
      concessionOwnerId
      dataPrivacyContractSigned
    }
  }
`;

/**
 * __useFindPartnerQuery__
 *
 * To run a query within a React component, call `useFindPartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPartnerQuery({
 *   variables: {
 *      contractPartnerId: // value for 'contractPartnerId'
 *   },
 * });
 */
export function useFindPartnerQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FindPartnerQuery,
    FindPartnerQueryVariables
  > &
    ({ variables: FindPartnerQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FindPartnerQuery, FindPartnerQueryVariables>(
    FindPartnerDocument,
    options
  );
}
export function useFindPartnerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindPartnerQuery,
    FindPartnerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<FindPartnerQuery, FindPartnerQueryVariables>(
    FindPartnerDocument,
    options
  );
}
export function useFindPartnerSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    FindPartnerQuery,
    FindPartnerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<FindPartnerQuery, FindPartnerQueryVariables>(
    FindPartnerDocument,
    options
  );
}
export type FindPartnerQueryHookResult = ReturnType<typeof useFindPartnerQuery>;
export type FindPartnerLazyQueryHookResult = ReturnType<typeof useFindPartnerLazyQuery>;
export type FindPartnerSuspenseQueryHookResult = ReturnType<
  typeof useFindPartnerSuspenseQuery
>;
