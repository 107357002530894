import { Station, StationTime } from "../../../../shared/types/schema";
import { FindRidesQuery, LoadCapacityQuery } from "../../api/operations.generated";

export type SegmentsForChart = Array<{
  name: string;
  seatsSold: number;
  seatsReserved: number;
  seatsRemaining: number;
  departureStationName: Station["name"];
  departureTime: StationTime["localDeparture"];
  arrivalStationName: Station["name"];
  arrivalTime: StationTime["localArrival"];
}>;

export default (
  route: FindRidesQuery["findRidesByDeparture"][0]["route"],
  segmentCapacity: NonNullable<
    LoadCapacityQuery["getRide"]["capacity"]["segmentCapacity"]
  >
): SegmentsForChart => {
  return segmentCapacity.map(
    ({ placeAvailable = 0, placeBooked = 0, placeReserved = 0 }, index) => {
      return {
        name: `${route[index].station.code} - ${route[index + 1].station.code}`,
        seatsSold: placeBooked,
        seatsReserved: placeReserved,
        seatsRemaining: placeAvailable,
        departureStationName: route[index].station.name,
        departureTime: route[index].time.localDeparture,
        arrivalStationName: route[index + 1].station.name,
        arrivalTime: route[index + 1].time.localArrival,
      };
    }
  );
};
