function parseValue(value) {
  return typeof value === 'string' ? parseInt(value, 10) : value;
}
/**
 * Increments a value by a step until a maximum value.
 * @param {number|string} value a value to be incremented
 * @param {number|string} step a value of how much to increment the value
 * @param {number|string} max the maximum value
 * @returns {number} the incremented value or "max" if the result is greater than the minimum.
 */
function incrementValue(value, step, max) {
  var newValue = parseValue(value) + step;
  return typeof max === 'number' && newValue > max ? max : newValue;
}
/**
 * Decrements a value by a step until a minimum value.
 * @param {number|string} value a value to be decremented
 * @param {number|string} step a value of how much to decrement the value
 * @param {number|string} min the minimum value
 * @returns {number} the decremented value or "min" if the result is lesser than the minimum.
 */
function decrementValue(value, step, min) {
  var newValue = parseValue(value) - step;
  return typeof min === 'number' && newValue < min ? min : newValue;
}
/**
 * Returns the value if it's within the constraints,
 * returns min if value is lower than min,
 * returns max if value is higher than max.
 * @param {number|string} value a value to be validated within the constraints
 * @param {number|string} min the lowest constraint, minimum value
 * @param {number|string} max the highest constraint, maximum value
 * @returns {number}
 */
function constraintValue(value, min, max) {
  var _a = [value, min, max].map(function (a) {
      return parseValue(a);
    }),
    valueInt = _a[0],
    minInt = _a[1],
    maxInt = _a[2];
  if (valueInt > maxInt) {
    return maxInt;
  }
  if (valueInt < minInt) {
    return minInt;
  }
  return valueInt;
}

export { constraintValue, decrementValue, incrementValue, parseValue };
