import { AutocompleteOption } from "../../../../shared/helpers/autocompleteHelper/AutocompleteOption";
import { FindRideFilterDataQuery } from "../../api/operations.generated";
import RideFilterType from "../../types";

export default (
  rides: FindRideFilterDataQuery["findRidesByDeparture"],
  type: RideFilterType
): Array<AutocompleteOption> => {
  const uniqueItems: Array<string> = [];

  if (type === RideFilterType.departureStation) {
    rides.forEach(({ departureStation }) => {
      if (uniqueItems.indexOf(departureStation.name!) < 0) {
        uniqueItems.push(departureStation.name!);
      }
    });
  } else if (type === RideFilterType.flixLineCode) {
    rides.forEach(({ line }) => {
      if (uniqueItems.indexOf(line.code!) < 0) {
        uniqueItems.push(line.code!);
      }
    });
  } else if (type === RideFilterType.departureTime) {
    rides.forEach(({ localDeparture }) => {
      const departureTime = localDeparture.slice(-5);

      if (uniqueItems.indexOf(departureTime) < 0) {
        uniqueItems.push(departureTime);
      }
    });
  }

  return uniqueItems.sort().map((name) => ({ value: name, label: name }));
};
