import { Icon, IconNewsletterSolid } from "@flixbus/honeycomb-icons-react";
import { OverflownText } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import * as css from "./ContactEmail.scss";

type Props = {
  email: string;
  showFully?: boolean;
};

const ContactEmail: React.FC<Props> = ({ email, showFully = true }) => {
  const emailElement = (
    <a href={`mailto:${email}`} className={css.email}>
      {email}
    </a>
  );

  return (
    <div className={css.wrapper}>
      <Icon InlineIcon={IconNewsletterSolid} extraClasses={css.icon} />

      {showFully ? (
        emailElement
      ) : (
        <OverflownText tooltipContent={<p>{email}</p>}>{emailElement}</OverflownText>
      )}
    </div>
  );
};

export default ContactEmail;
