import * as React from "react";
import { FindBookingsQuery } from "../../api/operations.generated";
import getContactPhone from "../../helpers/getContactPhone/getContactPhone";
import ContactEmail from "../contactEmail/ContactEmail";
import ContactPhone from "../contactPhone/ContactPhone";

type Props = {
  contactEmail: FindBookingsQuery["findBookings"][number]["contactEmail"];
  passengers: FindBookingsQuery["findBookings"][number]["passengers"];
  showEmailFully: boolean;
};

const BookingContacts: React.FC<Props> = ({
  contactEmail,
  passengers,
  showEmailFully,
}) => {
  const contactPhone = getContactPhone(passengers);

  return (
    <>
      {contactEmail && <ContactEmail email={contactEmail} showFully={showEmailFully} />}
      {contactPhone && <ContactPhone phone={contactPhone} />}
    </>
  );
};

export default BookingContacts;
