import { datadogRum } from "@datadog/browser-rum";
import {
  Feature,
  addNotification,
  dlPush,
  hasUserPermission,
} from "@flixbus-phx/marketplace-common";
import React from "react";
import { CheckInStatus } from "../../../shared/types/schema";
import { useUpdatePassengerStatusMutation } from "../../api/operations.generated";
import {
  getErrorNotification,
  getSuccessNotification,
} from "../../helper/getNotifications/getNotifications";
import { QueriedRidePassenger } from "../../types";
import CheckInButton from "../ui/checkInButton/CheckInButton";
import CheckInStatusTag from "../ui/checkInStatusTag/CheckInStatusTag";
import * as css from "./PaxRow.scss";

type Props = {
  pax: QueriedRidePassenger;
  readOnly: boolean;
};

const PaxRow: React.FC<Props> = ({ pax, readOnly }) => {
  const [updatePassengerStatus, { loading }] = useUpdatePassengerStatusMutation({
    onCompleted: (_, options) => {
      if (options?.variables) {
        addNotification(getSuccessNotification(options.variables.actionType));
      }
      datadogRum.addAction("checkInSuccessful", {
        checkInType: "manual",
      });
    },
    onError: (error, options) => {
      if (options?.variables) {
        addNotification(getErrorNotification(error));
      }
      datadogRum.addAction("checkInFailed", {
        checkInType: "manual",
        checkInFailureReason: error.message,
      });
    },
  });

  const getChildren = () => {
    if (
      readOnly ||
      pax.checkInStatus === CheckInStatus.Absent ||
      pax.checkInStatus === CheckInStatus.NotAvailable ||
      !hasUserPermission(Feature.CHECK_IN_MANUAL)
    ) {
      return <CheckInStatusTag status={pax.checkInStatus} />;
    }
    return (
      <CheckInButton
        isLoading={loading}
        status={pax.checkInStatus}
        onStatusChange={(updateAction) => {
          dlPush({ event: "manual_checkin", checkin_type: updateAction });
          updatePassengerStatus({
            variables: {
              ridePassengerId: pax.id,
              actionType: updateAction,
            },
          });
        }}
      />
    );
  };

  return (
    <div className={css.wrapper}>
      <div className={css.paxName}>{pax.name}</div>
      <div>{getChildren()}</div>
    </div>
  );
};

export default PaxRow;
