import { addNotification, NotificationType } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { useIntl } from "react-intl";
import ConfirmationPopup from "../../../../shared/components/confirmationPopup/ConfirmationPopup";
import {
  addRideUpdateErrors,
  updateRideUpdateErrors,
} from "../../../../state/rideUpdateErrors/handleRideUpdateErrors";
import { RideUpdate } from "../../../../state/rideUpdateErrors/types";
import {
  FindRidesForRideActionsQuery,
  useStopSalesMutation,
} from "../../api/operations.generated";
import getRidesWithFailedUpdate from "../../helpers/getRidesWithFailedUpdate/getRidesWithFailedUpdate";
import { includeAndExcludeRidesForSalesStopAndSalesRestart } from "../../helpers/includeAndExcludeRidesForBatchOperations/includeAndExcludeRidesForBatchOperations";
import BatchAdjustExcludedRides from "../../ui/batchAdjustExcludedRides/BatchAdjustExcludedRides";

export type BatchStopSalesPopupProps = {
  selectedRides: FindRidesForRideActionsQuery["findRidesByDeparture"];
  onClose: () => void;
};

const BatchStopSalesPopup: React.FC<BatchStopSalesPopupProps> = ({
  selectedRides,
  onClose,
}) => {
  const { formatMessage } = useIntl();

  const [includedRides, excludedRides] =
    includeAndExcludeRidesForSalesStopAndSalesRestart(
      selectedRides,
      "salesStopNotPossibleReasons"
    );

  const [stopSales, { loading }] = useStopSalesMutation({
    onCompleted: (data) => {
      onClose();

      const ridesSuccess = data.stopSales.map((ride) => ride.id);
      const ridesFail = getRidesWithFailedUpdate(includedRides, ridesSuccess);

      updateRideUpdateErrors(ridesSuccess, ridesFail, RideUpdate.StopSales);

      if (ridesSuccess.length) {
        addNotification({
          message: formatMessage(
            { id: "ride.batchUpdate.notification.stopSales.success" },
            {
              countRides: data.stopSales.length,
            }
          ),
          type: NotificationType.Success,
        });
      }

      if (ridesFail.length) {
        addNotification({
          message: formatMessage(
            { id: "ride.batchUpdate.notification.stopSales.fail" },
            {
              countRides: ridesFail.length,
            }
          ),
          type: NotificationType.Danger,
          isPermanent: true,
        });
      }
    },
    onError: () => {
      onClose();
      addRideUpdateErrors(includedRides, RideUpdate.StopSales);
      addNotification({
        message: formatMessage(
          { id: "ride.batchUpdate.notification.stopSales.fail" },
          {
            countRides: includedRides.length,
          }
        ),
        type: NotificationType.Danger,
        isPermanent: true,
      });
    },
  });

  const handleStopSales = () => {
    stopSales({
      variables: {
        rides: includedRides,
      },
    });
  };

  return (
    <ConfirmationPopup
      active
      titleText={formatMessage(
        { id: "ride.batchUpdate.popup.stopSales.title" },
        {
          countRides: includedRides.length,
        }
      )}
      confirmButtonText={formatMessage({ id: "general.yes" })}
      onConfirm={() => {
        handleStopSales();
      }}
      onCancel={() => onClose()}
      submitLoading={loading}
    >
      {formatMessage({ id: "ride.stopSales.confirmationContent" })}
      {excludedRides.length > 0 && (
        <BatchAdjustExcludedRides rides={excludedRides} type="salesStop" />
      )}
    </ConfirmationPopup>
  );
};

export default BatchStopSalesPopup;
