@import "../../helpers/bookingListStyles/BookingListStyles.scss";

.wrapper {
  @include contactWrapper;
}

.icon {
  @include contactIcon;
}

.email {
  flex: 1;
  word-break: break-all;
  line-height: normal;
  color: $content-primary-color;

  @include on-bp(md) {
    display: inline-block;
    width: calc($contacts-width - 60px);

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
