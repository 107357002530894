@import "variables";

.container {
  background-color: $grayscale-10-color;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon {
  margin-bottom: $spacing-6;
}

.heading {
  font-size: 48px;
  line-height: 48px;
  margin-bottom: $spacing-4;
}

.text {
  margin-bottom: $spacing-1;
  color: $content-secondary-color;
}

.button {
  margin-top: $spacing-3;
}

$dot-width: 11px;

.headingAnimated {
  padding-right: calc($dot-width * 2);
}

/**
 * ==============================================
 * Dot Animation: https://codepen.io/nzbin/pen/GGrXbp
 * ==============================================
 */
.dot {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: $content-primary-color;
  color: $content-primary-color;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  display: inline-block;
  position: relative;
  top: 1px;
  left: $dot-width;
}
.dot::before,
.dot::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot::before {
  left: -12px;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: $content-primary-color;
  color: $content-primary-color;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot::after {
  left: 12px;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: $content-primary-color;
  color: $content-primary-color;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: $content-primary-color;
  }
  50%,
  100% {
    background-color: $grayscale-50-color;
  }
}
