@import "variables";

.partnerFilter {
  margin-bottom: $spacing-4;
  @include on-bp(md) {
    width: 40%;
  }
}

.list {
  padding-bottom: $spacing-4;
  cursor: pointer;
}
