import {
  IconBusStopNo,
  IconCrossedSolid,
  IconForbidden,
} from "@flixbus/honeycomb-icons-react";
import * as React from "react";
import { useIntl } from "react-intl";
import {
  CancellationStatus,
  Ride,
  CapacityManagementNotPossibleReasons,
} from "../../../../shared/types/schema";
import { removeRideUpdateError } from "../../../../state/rideUpdateErrors/handleRideUpdateErrors";
import { RideUpdate } from "../../../../state/rideUpdateErrors/types";
import RideInfo from "../rideInfo/RideInfo";
import * as css from "./RideInfoSection.scss";

type Props = {
  rideId: Ride["id"];
  cancellationStatus: CancellationStatus;
  salesStopNotPossibleReasons: Array<CapacityManagementNotPossibleReasons>;
  rideUpdateErrors?: Array<RideUpdate>;
};

const RideInfoSection: React.FC<Props> = ({
  rideId,
  cancellationStatus,
  salesStopNotPossibleReasons,
  rideUpdateErrors,
}) => {
  const { formatMessage } = useIntl();

  const rideUpdateErrorsNoDuplicates = rideUpdateErrors?.filter((update, i) => {
    return rideUpdateErrors.indexOf(update) === i;
  });

  return (
    <div className={css.sectionWrapper}>
      {cancellationStatus !== CancellationStatus.None && (
        <RideInfo
          icon={IconBusStopNo}
          message={formatMessage({
            id:
              cancellationStatus === CancellationStatus.Successful
                ? "ride.cancellation.successful"
                : "ride.cancellation.inProgress",
          })}
        />
      )}

      {cancellationStatus === CancellationStatus.None &&
        salesStopNotPossibleReasons.some((reason) =>
          [
            CapacityManagementNotPossibleReasons.SalesStoppedManually,
            CapacityManagementNotPossibleReasons.SalesStoppedAutomatically,
          ].includes(reason)
        ) && (
          <RideInfo
            icon={IconForbidden}
            message={formatMessage({
              id: salesStopNotPossibleReasons.includes(
                CapacityManagementNotPossibleReasons.SalesStoppedAutomatically
              )
                ? "ride.autoSalesCut.info"
                : "ride.manualSalesCut.info",
            })}
          />
        )}

      {rideUpdateErrorsNoDuplicates?.map((update) => {
        let message: string;

        switch (update) {
          case RideUpdate.UpdateSeats:
            message = "ride.update.seatsOnOffer.fail";
            break;
          case RideUpdate.StopSales:
            message = "ride.update.stopSales.fail";
            break;
          case RideUpdate.RestartSales:
            message = "ride.update.restartSales.fail";
            break;
          default:
            message = "ride.update.fail";
        }

        return (
          <RideInfo
            key={update}
            icon={IconCrossedSolid}
            message={formatMessage({ id: message })}
            onClose={() => removeRideUpdateError(rideId, update)}
            isError
          />
        );
      })}
    </div>
  );
};

export default RideInfoSection;
