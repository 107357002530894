import { Icon, IconCameraSolid } from "@flixbus/honeycomb-icons-react";
import {
  Button,
  Heading,
  Panel,
  PanelContent,
  PanelHeader,
} from "@flixbus/honeycomb-react";
import { DisableBodyScroll } from "@flixbus-phx/marketplace-common";
import QrScanner from "qr-scanner";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { initQrScanner, stopQrScanner } from "../../helper/qrScanner/QrScanner";
import QrScannerCameraActions from "../ui/qrScannerCameraActions/QrScannerCameraActions";
import * as css from "./QrScannerPanel.scss";

export type QrScannerPanelProps = {
  active: boolean;
  onTicketScanned: (orderId: string) => void;
  onClose: () => void;
};

const QrScannerPanel: React.FC<QrScannerPanelProps> = ({
  active,
  onTicketScanned,
  onClose,
}) => {
  const [hasCameraPermission, setHasCameraPermission] = React.useState<boolean>(true);
  const [qrScanner, setQrScanner] = React.useState<QrScanner | null>(null);

  const videoRef = React.createRef<HTMLVideoElement>();
  const overlayRef = React.createRef<HTMLDivElement>();

  const destroyQrScanner = () => {
    stopQrScanner();
    setQrScanner(null);
  };

  const close = () => {
    destroyQrScanner();
    onClose();
  };

  useEffect(() => {
    if (active) {
      if (!hasCameraPermission) return;
      if (qrScanner !== null) return;

      initQrScanner(
        videoRef.current!,
        overlayRef.current!,
        onTicketScanned,
        (qrScannerInstance: QrScanner) => {
          setQrScanner(qrScannerInstance);
        },
        () => {
          setHasCameraPermission(false);
        }
      );
    } else {
      destroyQrScanner();
    }
  });

  const videoElement = (
    <>
      <video id="qr-video" ref={videoRef} className={css.video}>
        <track kind="captions" />
      </video>
      <div id="qr-overlay" ref={overlayRef} className={css.scanOverlay}>
        {qrScanner && <QrScannerCameraActions qrScanner={qrScanner} />}
      </div>
    </>
  );

  const permissionDeniedElement = (
    <div id="qr-camera-permission-denied" className={css.permissionDenied}>
      <Icon extraClasses={css.cameraIcon} InlineIcon={IconCameraSolid} />
      <Heading size={3}>
        <FormattedMessage id="checkin.autoCheckin.cameraPermissionDenied" />
      </Heading>
      <Button
        appearance="primary"
        onClick={() => {
          setHasCameraPermission(true);
        }}
      >
        <FormattedMessage id="checkin.autoCheckin.retryCameraPermission" />
      </Button>
    </div>
  );

  return (
    <>
      <DisableBodyScroll active={active} />
      <Panel
        id="qr-scanner-panel"
        active={active}
        position="bottom"
        fullSize
        extraClasses={css.qrPanel}
      >
        <PanelHeader closeButtonProps={{ label: "close", onClick: close }}>
          <p>
            <FormattedMessage id="checkin.autoCheckin.qrPanelHeader" />
          </p>
        </PanelHeader>
        <PanelContent extraClasses={css.panelContent}>
          {active && (hasCameraPermission ? videoElement : permissionDeniedElement)}
        </PanelContent>
      </Panel>
    </>
  );
};

export default QrScannerPanel;
