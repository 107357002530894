import ReactUtils from '../../packages/react-utils/react-utils.js';
import 'react';

function connectionPropValidator(validComponent, required) {
  if (required === void 0) {
    required = false;
  }
  return function (props, propName, componentName) {
    if (!required && !props[propName]) return null;
    if (typeof props[propName] === 'string') return null;
    if (ReactUtils.getElementName(props[propName]) === validComponent) return null;
    return new Error("".concat(componentName, " component only accepts \"").concat(validComponent, "\" or a plain string as \"").concat(propName, "\", but received \"").concat(ReactUtils.getElementName(props[propName]), "\"."));
  };
}

export { connectionPropValidator };
