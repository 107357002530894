import { datadogRum } from "@datadog/browser-rum";
import { Icon, IconAttention, IconDownload } from "@flixbus/honeycomb-icons-react";
import { Button, Heading } from "@flixbus/honeycomb-react";
import { HideMainNav, readURIParams } from "@flixbus-phx/marketplace-common";
import { differenceInDays } from "date-fns";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import {
  fetchData,
  getFileName,
  getFileUrl,
  startDownload,
} from "../shared/helpers/downloadFileViaRest/downloadFileViaRest";
import * as css from "./BookingReports.scss";

const BookingReports: React.FC = () => {
  const { search } = useLocation();
  const { token, date } = readURIParams(search);
  const [downloadSucceeded, setDownloadSucceeded] = React.useState<boolean>();

  if (date) {
    datadogRum.addAction("bookingReportsDownload", {
      date: new Date(date),
      daysDifference: differenceInDays(new Date(), new Date(date)),
    });
  }

  const downloadFile = async () => {
    try {
      const response = await fetchData("/bookings/download", {
        token,
      });
      const fileName = getFileName(response);
      const fileUrl = await getFileUrl(response);

      startDownload(fileName, fileUrl);

      setDownloadSucceeded(true);
    } catch (error) {
      setDownloadSucceeded(false);
    }
  };

  React.useEffect(() => {
    if (token) {
      downloadFile();
    } else {
      setDownloadSucceeded(false);
    }
  }, []);

  return (
    <>
      <div className={css.container}>
        {downloadSucceeded === undefined && (
          <>
            <Icon InlineIcon={IconDownload} size={12} extraClasses={css.icon} />
            <Heading
              size={1}
              flushSpace
              extraClasses={`${css.headingAnimated} ${css.heading}`}
            >
              <FormattedMessage id="bookingReportsDownload.loading.title" />
              <span className={css.dot} />
            </Heading>

            <div className={css.text}>
              <FormattedMessage id="bookingReportsDownload.text.pleaseWait" />
            </div>
            <div className={css.text}>
              <FormattedMessage id="bookingReportsDownload.loading.text.bottom" />
            </div>
          </>
        )}

        {downloadSucceeded === true && (
          <>
            <Icon InlineIcon={IconDownload} size={12} extraClasses={css.icon} />
            <Heading size={1} flushSpace extraClasses={`${css.heading}`}>
              <FormattedMessage id="bookingReportsDownload.success.title" />
            </Heading>

            <div className={css.text}>
              <FormattedMessage id="bookingReportsDownload.success.top" />
            </div>
            <div className={css.text}>
              <FormattedMessage id="bookingReportsDownload.text.pleaseWait" />
            </div>
          </>
        )}

        {downloadSucceeded === false && (
          <>
            <Icon InlineIcon={IconAttention} size={12} extraClasses={css.icon} />
            <Heading
              size={1}
              flushSpace
              extraClasses={`${css.headingAnimated} ${css.heading}`}
            >
              <FormattedMessage id="general.AnErrorOccured" />
            </Heading>

            <div className={css.text}>
              <FormattedMessage id="bookingReportsDownload.error.top" />
            </div>
            <div className={css.text}>
              <FormattedMessage id="bookingReportsDownload.error.bottom" />
            </div>
            <Button onClick={() => window.location.reload()} extraClasses={css.button}>
              Retry
            </Button>
          </>
        )}
      </div>
      <HideMainNav />
    </>
  );
};

export default BookingReports;
