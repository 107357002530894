@import "variables";

.container {
  margin: 0 auto;
  padding: $spacing-4 $spacing-2;
}

.capacityManagerQuestions {
  padding-bottom: $spacing-6;
}

.contact {
  text-align: center;
  padding-top: $spacing-12;
}

.lastRowLeftCol {
  border-radius: 0 !important;
  @include on-bp(md) {
    border-bottom-left-radius: $border-radius !important;
    border-bottom-right-radius: $border-radius !important;
  }
}

.firstRowRightCol {
  border-radius: 0 !important;
  border-top: 0;
  @include on-bp(md) {
    border-top: $border;
    border-top-left-radius: $border-radius !important;
    border-top-right-radius: $border-radius !important;
  }
}
