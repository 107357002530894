import { scrollToTopOfElement } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { formatDate } from "../../../shared/helpers/formatDate/formatDate";
import { FindBookingsQuery } from "../../api/operations.generated";
import BookingConnection from "../bookingConnection/BookingConnection";
import BookingContacts from "../bookingContacts/BookingContacts";
import BookingTag from "../bookingTag/BookingTag";
import PassengerInfo from "../passengerInfo/PassengerInfo";
import * as css from "./BookingListBody.scss";

type Props = {
  bookings: FindBookingsQuery["findBookings"];
};

const BookingListBody: React.FC<Props> = ({ bookings }) => {
  const bookingListBodyRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    scrollToTopOfElement(bookingListBodyRef);
  });

  return (
    <div className={css.bookingListBody} ref={bookingListBodyRef}>
      {bookings.map((booking) => (
        <div key={`${booking.ride.id}${booking.orderId}`} className={css.bookingListItem}>
          <div className={css.cell}>
            <BookingTag orderStatus={booking.orderStatus} />
          </div>
          <div className={css.cell}>{booking.lineCode}</div>
          <div className={css.cell}>
            {formatDate(booking.departureTime, "iii, MMM d, y")}
          </div>
          <div className={`${css.cell} ${css.connectionCell}`}>
            <BookingConnection booking={booking} />
          </div>
          <div className={css.cell}>{booking.orderId}</div>
          <div className={css.cell}>
            <PassengerInfo orderId={booking.orderId} passengers={booking.passengers} />
          </div>
          <div className={css.cell}>
            <BookingContacts
              contactEmail={booking.contactEmail}
              passengers={booking.passengers}
              showEmailFully={false}
            />
          </div>
          <div className={css.cell}>
            {booking.interconnection ? (
              <FormattedMessage id="general.yes" />
            ) : (
              <FormattedMessage id="general.no" />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default BookingListBody;
