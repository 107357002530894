import { Connection } from "@flixbus/honeycomb-react";
import * as React from "react";
import generateStopsForConnectionComponent from "../../../../shared/helpers/generateStopsForConnectionComponent/generateStopsForConnectionComponent";
import { RouteEntryFragment } from "../../api/operations.generated";
import * as css from "./RideConnection.scss";

type Props = {
  rideRoute: Array<RouteEntryFragment>;
};

const RideConnection: React.FC<Props> = ({ rideRoute }) => {
  const stops = generateStopsForConnectionComponent(
    rideRoute[0].station.name,
    rideRoute[0].time.localDeparture,
    rideRoute[rideRoute.length - 1].station.name,
    rideRoute[rideRoute.length - 1].time.localArrival
  );

  return <Connection extraClasses={css.connection}>{stops}</Connection>;
};

export default RideConnection;
