import { Skeleton } from "@flixbus/honeycomb-react";
import { Breakpoint, ResponsiveRenderer } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import * as css from "./RideActionsSummary.scss";

type Props = {
  countFiltered?: number;
  countSelected?: number;
  loading: boolean;
};

const RideActionsSummary: React.FC<Props> = ({
  countFiltered,
  countSelected,
  loading,
}) => {
  return (
    <>
      {loading && (
        <>
          <ResponsiveRenderer
            hidden={[
              Breakpoint.XXS,
              Breakpoint.XS,
              Breakpoint.SM,
              Breakpoint.MD,
              Breakpoint.LG,
            ]}
          >
            <Skeleton
              data-id="selected"
              extraClasses={css.summarySkeleton}
              Elem="span"
              height="sm"
            />
            &nbsp;
            <FormattedMessage id="ride.actions.summary.countSelected" />
            &nbsp;|&nbsp;
          </ResponsiveRenderer>
          <Skeleton extraClasses={css.summarySkeleton} Elem="span" height="sm" />
          &nbsp;
          <FormattedMessage id="ride.actions.summary.countFiltered" />
        </>
      )}
      {!loading && (
        <>
          <ResponsiveRenderer
            hidden={[
              Breakpoint.XXS,
              Breakpoint.XS,
              Breakpoint.SM,
              Breakpoint.MD,
              Breakpoint.LG,
            ]}
          >
            <b data-id="selected">{countSelected}</b>&nbsp;
            <FormattedMessage id="ride.actions.summary.countSelected" />
            &nbsp;|&nbsp;
          </ResponsiveRenderer>
          <b>{countFiltered}</b>
          &nbsp;
          <FormattedMessage id="ride.actions.summary.countFiltered" />
        </>
      )}
    </>
  );
};

export default RideActionsSummary;
