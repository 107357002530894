import { ThemeWrapper, themeDark, themeDefault } from "@flixbus/honeycomb-react";
import {
  NavigationApp,
  ErrorBoundary,
  Notification,
  Navigation,
  getThemeSetting,
  isTestEnv,
  AuthProvider,
  StateProvider,
  TranslationProvider,
  legacyTranslate,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./router/Router";
import Layout from "./ui/Layout";

export const AppContent: React.FC = () => {
  return (
    <BrowserRouter>
      <ErrorBoundary translate={legacyTranslate}>
        <StateProvider
          fetchPolicy="cache-and-network"
          typePolicies={{
            Capacity: {
              merge: true,
            },
            Partner: {
              keyFields: ["contractPartnerId"],
            },
          }}
        >
          <Layout>
            <Navigation
              app={NavigationApp.MPO}
              isUsedInternal
              translate={legacyTranslate}
            />
            <Router />
          </Layout>
          <Notification />
        </StateProvider>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

const App: React.FC = () => {
  // User is Cypress
  if (isTestEnv() && process.env.NODE_ENV !== "production") {
    return (
      <ThemeWrapper theme="default" style={{ minWidth: "1200px" }}>
        <TranslationProvider usesCookies isUsedInternal>
          <AppContent />
        </TranslationProvider>
      </ThemeWrapper>
    );
  }

  return (
    <ThemeWrapper
      id="theme-wrapper"
      theme={getThemeSetting()}
      themes={{ default: themeDefault, dark: themeDark }}
    >
      <TranslationProvider usesCookies isUsedInternal>
        <AuthProvider translate={legacyTranslate}>
          <AppContent />
        </AuthProvider>
      </TranslationProvider>
    </ThemeWrapper>
  );
};

export default App;
