import * as React from "react";
import { FormattedMessage } from "react-intl";
import * as css from "./RideItemInfoText.scss";

type Props = {
  isError?: boolean;
};

const RideItemInfoText: React.FC<Props> = ({ isError }) => {
  const emoji = isError ? "🤕" : "🕵️‍♀️";
  const text = isError ? (
    <p data-id="error-message">
      <FormattedMessage id="ride.error.concessionOwnerNotFound" />
    </p>
  ) : (
    <p data-id="help-text">
      <FormattedMessage id="ride.error.noRidesFound" />
    </p>
  );
  return (
    <div className={css.wrapper}>
      <div>
        <div className={css.emojiWrapper}>
          <span role="img" aria-label="emoji">
            {emoji}
          </span>
        </div>
        <div className={css.textWrapper}>{text}</div>
      </div>
    </div>
  );
};

export default RideItemInfoText;
