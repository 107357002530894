import * as React from "react";
import getFindRidesQueryVars from "../../../../shared/helpers/getFindRidesQueryVars/getFindRidesQueryVars";
import { Ride } from "../../../../shared/types/schema";
import useRideFilter, {
  rideFilterVar,
} from "../../../../state/useRideFilter/useRideFilter";
import useSelectedRides, {
  selectedRidesVar,
} from "../../../../state/useSelectedRides/useSelectedRides";
import { useFindRidesForRideTableHeaderQuery } from "../../api/operations.generated";
import TableHeaderRow from "../../ui/tableHeaderRow/TableHeaderRow";

const RideTableHeader: React.FC = () => {
  const [rideFilter] = useRideFilter(rideFilterVar);
  const [selectedRides, , setMultiple, , removeAll] = useSelectedRides(selectedRidesVar);

  const { data, loading } = useFindRidesForRideTableHeaderQuery({
    variables: getFindRidesQueryVars(rideFilter, false, false),
    fetchPolicy: process.env.NODE_ENV === "test" ? "network-only" : "cache-only",
  });

  let filteredRides: Array<Ride["id"]> = [];
  let numberOfFilteredRides = 0;
  let numberOfSelectedRides = 0;

  if (data?.findRidesByDeparture) {
    filteredRides = data.findRidesByDeparture.map((ride) => ride.id);
    numberOfFilteredRides = filteredRides.length;
    numberOfSelectedRides = selectedRides.length;
  }

  const getCheckboxCheckedState = (
    numOfFilteredRides: number,
    numOfSelectedRides: number
  ) => {
    if (numOfSelectedRides > 0 && numOfSelectedRides === numOfFilteredRides) {
      return true;
    }

    return false;
  };

  const isCheckboxChecked = getCheckboxCheckedState(
    numberOfFilteredRides,
    numberOfSelectedRides
  );

  const handleSelectCheckbox = () => {
    if (isCheckboxChecked) {
      return removeAll();
    }

    return setMultiple(filteredRides);
  };

  return (
    <TableHeaderRow
      isCheckboxDisabled={loading || numberOfFilteredRides === 0}
      isCheckboxChecked={isCheckboxChecked}
      isCheckboxIndeterminate={!isCheckboxChecked && numberOfSelectedRides > 0}
      onSelectCheckbox={handleSelectCheckbox}
    />
  );
};

export default RideTableHeader;
