var defaults = {
  minusBtnTitle: 'Remove',
  plusBtnTitle: 'Add'
};
function quantityPropsValidator(props, propName) {
  var prop = props[propName];
  if (!prop || prop === defaults[propName]) {
    return null;
  }
  if (typeof prop !== 'string') {
    return new Error("Quantity component: '".concat(propName, "' prop only accepts string values."));
  }
  if (prop && prop !== defaults[propName]) {
    // eslint-disable-next-line no-console
    console.warn("\n      Quantity component: '".concat(propName, "' prop is deprecated and will be removed in the next major version.\n      Use '").concat(propName.replace('Title', 'Props'), "[\"aria-label\"]' and/or '").concat(propName.replace('Title', 'Props'), "[\"title\"]' instead.\n    "));
    return null;
  }
  return null;
}

export { quantityPropsValidator };
