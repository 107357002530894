import { Checkbox } from "@flixbus/honeycomb-react";
import * as React from "react";
import { Ride } from "../../../../shared/types/schema";
import useSelectedRides, {
  selectedRidesVar,
} from "../../../../state/useSelectedRides/useSelectedRides";

type Props = {
  rideId: Ride["id"];
};

const RideCheckbox: React.FC<Props> = ({ rideId }) => {
  const [selectedRides, addOne, , removeOne] = useSelectedRides(selectedRidesVar);

  const isChecked = selectedRides.includes(rideId);

  const handleSelect = () => {
    if (isChecked) {
      removeOne(rideId);
    } else {
      addOne(rideId);
    }
  };

  return (
    <Checkbox
      id={rideId}
      value={rideId}
      aria-label={`RideItem-${rideId}`}
      checked={isChecked}
      onChange={handleSelect}
    />
  );
};

export default RideCheckbox;
