@import "variables";

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
}

.button {
  min-width: 100px;
}

.cancelButton {
  color: $danger-color;
  border-color: $danger-color;
  &:hover:enabled {
    background-color: $danger-color;
    color: #fff;
  }
}
