import { Icon, IconClose, IconMagnifier } from "@flixbus/honeycomb-icons-react";
import { Button, Heading, Input } from "@flixbus/honeycomb-react";
import {
  DefaultInfoAlert,
  legacyTranslate,
  normalizeText,
} from "@flixbus-phx/marketplace-common";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import DataPrivacyContractCheck from "../shared/components/dataPrivacyContractCheck/DataPrivacyContractCheck";
import { RidePassengerStatus } from "../shared/types/schema";
import * as css from "./PaxList.scss";
import { useFindRidePaxQuery } from "./api/operations.generated";
import PaxListStationRow from "./container/paxListStationRow/PaxListStationRow";
import PaxListLoading from "./container/ui/paxListLoading/PaxListLoading";
import PaxListNavigation from "./container/ui/paxListNavigation/PaxListNavigation";
import { QueriedRidePassenger } from "./types";

const PaxList: React.FC = () => {
  const { formatMessage, formatPlural } = useIntl();
  const { rideId } = useParams();
  const [searchValue, setSearchValue] = React.useState("");
  const [paxList, setPaxList] = React.useState<QueriedRidePassenger[]>([]);

  const { data, error, loading } = useFindRidePaxQuery({
    variables: { id: rideId! },
  });

  React.useEffect(() => {
    if (data) {
      setPaxList(
        data.getRide.ridePassengers
          .filter((pax) => {
            return normalizeText(pax.name).includes(normalizeText(searchValue));
          })
          .filter((pax) => pax.status === RidePassengerStatus.Paid)
      );
    }
  }, [searchValue, data?.getRide.ridePassengers]);

  return (
    <DataPrivacyContractCheck LoadingAnimation={<PaxListLoading />}>
      {loading && <PaxListLoading />}
      {error && (
        <DefaultInfoAlert
          message={formatMessage({ id: "general.AnErrorOccured" })}
          translate={legacyTranslate}
        />
      )}
      {data?.getRide && (
        <>
          <div className={css.wrapper}>
            <Heading size={3} sectionHeader>
              <FormattedMessage id="checkIn.paxList.headline" />
            </Heading>
            <div
              className={css.paxCount}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: formatMessage(
                  {
                    id: `checkIn.paxList.passengerCount.${formatPlural(paxList.length)}`,
                  },
                  {
                    countPax: paxList?.length,
                  },
                  { ignoreTag: true }
                ),
              }}
            />
            <Input
              id="paxSearch"
              aria-label="Search Passengers"
              placeholder={formatMessage({ id: "checkIn.paxList.search.placeholder" })}
              iconLeft={<Icon InlineIcon={IconMagnifier} extraClasses={css.icon} />}
              iconRight={
                searchValue.length ? (
                  <Button aria-label="Clear search" onClick={() => setSearchValue("")}>
                    <Icon InlineIcon={IconClose} extraClasses={css.icon} />
                  </Button>
                ) : undefined
              }
              extraClasses={css.input}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            {data.getRide.route.map((routeEntry) => {
              const { station } = routeEntry;

              return (
                <PaxListStationRow
                  key={station.id}
                  stationName={station.name}
                  paxList={
                    paxList?.filter((pax) => pax.departureStationId === station.id)!
                  }
                  readOnly={!data.getRide.checkInAllowed}
                />
              );
            })}
          </div>
          <PaxListNavigation />
        </>
      )}
    </DataPrivacyContractCheck>
  );
};

export default PaxList;
