import QrScanner from "qr-scanner";
import qrCodeOrderIdExtractor from "../qrCodeOrderIdExtractor/qrCodeOrderIdExtractor";

let qrScanner: QrScanner | null = null;

export const stopQrScanner = () => {
  qrScanner?.stop();
  qrScanner?.destroy();
  qrScanner = null;
};

export const initQrScanner = (
  videoElement: HTMLVideoElement,
  overlayElement: HTMLDivElement,
  onTicketScanned: (orderId: string) => void,
  onStarted: (qrScanner: QrScanner) => void,
  onFailed: () => void
) => {
  if (qrScanner !== null) return;

  const qrScannerInstance = new QrScanner(
    videoElement,
    (result) => {
      const orderId = qrCodeOrderIdExtractor(result);
      if (orderId !== undefined) onTicketScanned(orderId);
    },
    {
      preferredCamera: "environment",
      maxScansPerSecond: 1,
      returnDetailedScanResult: true,
      highlightScanRegion: true,
      highlightCodeOutline: false,
      overlay: overlayElement,
    }
  );

  qrScannerInstance
    .start()
    .then(() => {
      qrScanner = qrScannerInstance;
      onStarted(qrScannerInstance);
    })
    .catch(() => {
      stopQrScanner();
      onFailed();
    });
};
