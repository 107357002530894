import { localizeDateWithWeekday } from "@flixbus-phx/marketplace-common";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Ride } from "../../../../shared/types/schema";
import { FindRidesQuery } from "../../api/operations.generated";
import RideConnection from "../rideConnection/RideConnection";
import * as css from "./LabelledRideData.scss";

type LabelledRideDataDepartureProps = {
  route: FindRidesQuery["findRidesByDeparture"][number]["route"];
};

export const LabelledRideDataDeparture: React.FC<LabelledRideDataDepartureProps> = ({
  route,
}) => {
  const departure = route[0].time.localDeparture
    ? localizeDateWithWeekday(new Date(route[0].time.localDeparture))
    : "";

  return (
    <>
      <div className={css.label}>
        <FormattedMessage id="ride.filter.date" />
      </div>
      <div>{departure}</div>
    </>
  );
};

type LabelledRideDataRouteProps = {
  route: FindRidesQuery["findRidesByDeparture"][number]["route"];
};

export const LabelledRideDataRoute: React.FC<LabelledRideDataRouteProps> = ({
  route,
}) => {
  return (
    <>
      <div className={`${css.label} ${css.route}`}>
        <FormattedMessage id="general.route" />
      </div>
      <div>
        <RideConnection rideRoute={route} />
      </div>
    </>
  );
};

type LabelledRideDataCountPaxProps = {
  countPax: Ride["numberOfPassengers"];
};

export const LabelledRideDataCountPax: React.FC<LabelledRideDataCountPaxProps> = ({
  countPax,
}) => {
  return (
    <>
      <div className={css.label}>
        <FormattedMessage id="general.passengers" />
      </div>
      <div className={css.paxCount}>{countPax}</div>
    </>
  );
};

type LabelledRideDataSeatsOnOfferProps = {
  isCapacityInitiated: Ride["capacity"]["isCapacityInitiated"];
  seatsOnOfferExpected: Ride["capacity"]["seatsOnOfferExpected"];
};

export const LabelledRideDataSeatsOnOffer: React.FC<
  LabelledRideDataSeatsOnOfferProps
> = ({ isCapacityInitiated, seatsOnOfferExpected }) => {
  return (
    <>
      <div className={css.label}>
        <FormattedMessage id="ride.seatsOnOffer" />
      </div>
      <div>
        {isCapacityInitiated ? (
          seatsOnOfferExpected
        ) : (
          <FormattedMessage id="general.notAvailable" />
        )}
      </div>
    </>
  );
};
