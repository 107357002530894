@import "variables";
@import "../../helpers/bookingListStyles/BookingListStyles";

.wrapper {
  overflow: hidden;
  position: relative;
  padding-top: $spacing-2;
  @include max-content-width;

  @include on-bp(md) {
    padding-top: $spacing-3;
  }
}

.noBookingsWrapper {
  margin-top: $spacing-2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.noBookingsHeading {
  margin-bottom: $spacing-2;
}

.bookingList {
  position: relative;
  z-index: 0;
  border: $border;
  border-radius: $border-radius;
  overflow-x: auto;
  display: grid;
}

.bookingListHeader {
  @include bookingListRow;
  height: $spacing-8;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  font-weight: bold;
  width: fit-content;

  > div {
    font-size: 14px;
    line-height: $line-height-small;
    @include bookingListCell;
  }
}
