@import "variables";
@import "../../helpers/bookingListStyles/BookingListStyles.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  gap: $spacing-2;
}

.tooltipWrapper {
  @include on-bp(md) {
    width: calc($passengers-width - 105px);
  }
}
