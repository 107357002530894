import { Box } from "@flixbus/honeycomb-react";
import { Breakpoint, ResponsiveRenderer } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FindRidesQuery } from "../../api/operations.generated";
import {
  LabelledRideDataCountPax,
  LabelledRideDataDeparture,
  LabelledRideDataRoute,
  LabelledRideDataSeatsOnOffer,
} from "../labelledRideData/LabelledRideData";
import PaxListButton from "../paxListButton/PaxListButton";
import RideEditButton from "../rideEditButton/RideEditButton";
import RideSaleStatusTag from "../rideSaleStatusTag/RideSaleStatusTag";
import * as css from "./RideCard.scss";

export type RideCardProps = {
  ride: FindRidesQuery["findRidesByDeparture"][number];
  onSeatsOnOfferChange: (seatsOnOfferExpected: number) => void;
  onStopSales: () => void;
  onRestartSales: () => void;
  onRideCancellation: () => void;
};

const RideCard: React.FC<RideCardProps> = ({
  ride,
  onSeatsOnOfferChange,
  onStopSales,
  onRestartSales,
  onRideCancellation,
}) => {
  return (
    <Box small>
      <div className={css.editButton}>
        <RideEditButton
          ride={ride}
          onCapacityChange={onSeatsOnOfferChange}
          onStopSales={onStopSales}
          onRestartSales={onRestartSales}
          onRideCancellation={onRideCancellation}
        />
      </div>

      <div className={css.grid}>
        <div className={css.statusAndLine}>
          <div>
            <RideSaleStatusTag saleStatus={ride.saleStatus} />
          </div>
          <div className={css.line}>{ride.line.code}</div>
        </div>
        <div className={css.departure}>
          <LabelledRideDataDeparture route={ride.route} />
        </div>
        <div className={css.route}>
          <LabelledRideDataRoute route={ride.route} />
        </div>
        <div className={css.countPax}>
          <LabelledRideDataCountPax countPax={ride.numberOfPassengers} />
          <ResponsiveRenderer
            hidden={[
              Breakpoint.XXS,
              Breakpoint.XS,
              Breakpoint.SM,
              Breakpoint.MD,
              Breakpoint.XL,
            ]}
          >
            <PaxListButton rideId={ride.id} data-id="countPax" />
          </ResponsiveRenderer>
        </div>
        <div className={css.seats}>
          <LabelledRideDataSeatsOnOffer
            seatsOnOfferExpected={ride.capacity.seatsOnOfferExpected}
            isCapacityInitiated={ride.capacity.isCapacityInitiated}
          />
        </div>
      </div>

      <ResponsiveRenderer hidden={[Breakpoint.LG]}>
        <PaxListButton rideId={ride.id} data-id="bottom" />
      </ResponsiveRenderer>
    </Box>
  );
};

export default RideCard;
