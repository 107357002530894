@import "variables";

.stationWrapper {
  height: 21px;

  &:not(:last-child) {
    margin-bottom: $spacing-2;
  }
}

.icon,
.stationName {
  position: relative;
  z-index: 1;
}

.stationName {
  padding-right: $spacing-1;
  padding-left: 3px;
  font-size: $font-size-small;
  color: $content-secondary-color;
  height: 21px;
}

.icon {
  margin-bottom: 2px;
  color: $icon-primary-color;
}

.divider {
  position: relative;
  top: -11px;
  z-index: 0;
}

// To hide Divider behind the Icon and Text
.stationName,
.icon {
  background: $bg-primary-color;
}
