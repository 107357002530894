import { Ride } from "../../../../shared/types/schema";
import { RideUpdateError } from "../../../../state/rideUpdateErrors/types";

export default (errors: Array<RideUpdateError>, rideId: Ride["id"]) => {
  const rideUpdateErrorsPerRide = errors
    .filter((err) => err.id === rideId)
    .map((err) => err.rideUpdate);

  if (rideUpdateErrorsPerRide.length) {
    return rideUpdateErrorsPerRide;
  }

  return undefined;
};
