import {
  CapacityManagementNotPossibleReasons,
  Ride,
} from "../../../../shared/types/schema";
import { FindRidesForRideActionsQuery } from "../../api/operations.generated";

export type ExcludedRides = Array<{
  id: Ride["id"];
  route: FindRidesForRideActionsQuery["findRidesByDeparture"][number]["route"];
  reasons: Array<CapacityManagementNotPossibleReasons>;
}>;

export const includeAndExcludeRidesForCapacityChange = (
  rides: FindRidesForRideActionsQuery["findRidesByDeparture"],
  capacity: number
): [Array<Ride["id"]>, ExcludedRides] => {
  const includedRides: Array<Ride["id"]> = [];
  const excludedRides: ExcludedRides = [];

  rides.forEach((ride) => {
    const {
      seatsOnOfferExpected,
      increaseNotPossibleReasons,
      decreaseNotPossibleReasons,
      minSeatsOnOffer,
    } = ride.capacity;

    // Rides for which the capacity change would be an increase of capacity
    if (capacity > seatsOnOfferExpected) {
      // Increase is not allowed
      if (increaseNotPossibleReasons.length) {
        excludedRides.push({
          id: ride.id,
          route: ride.route,
          reasons: increaseNotPossibleReasons,
        });
        // Increase is allowed
      } else {
        includedRides.push(ride.id);
      }
      // Rides for which the capacity change would be a decrease of capacity
    } else if (capacity < seatsOnOfferExpected) {
      if (decreaseNotPossibleReasons.length || capacity < minSeatsOnOffer) {
        excludedRides.push({
          id: ride.id,
          route: ride.route,
          reasons: (capacity < minSeatsOnOffer &&
          !decreaseNotPossibleReasons.includes(
            CapacityManagementNotPossibleReasons.CapacityIsBelowMinSeatsOnOffer
          )
            ? [CapacityManagementNotPossibleReasons.CapacityIsBelowMinSeatsOnOffer]
            : []
          ).concat(decreaseNotPossibleReasons),
        });
      } else {
        includedRides.push(ride.id);
      }
      // Rides where selected capacity is the same as the current capacity
    } else {
      excludedRides.push({
        id: ride.id,
        route: ride.route,
        reasons: decreaseNotPossibleReasons.filter((reason) =>
          increaseNotPossibleReasons.includes(reason)
        ),
      });
    }
  });

  return [includedRides, excludedRides];
};

export const includeAndExcludeRidesForSalesStopAndSalesRestart = (
  rides: FindRidesForRideActionsQuery["findRidesByDeparture"],
  type: "salesStopNotPossibleReasons" | "salesRestartNotPossibleReasons"
): [Array<Ride["id"]>, ExcludedRides] => {
  return [
    rides.filter((ride) => ride[type].length === 0).map((ride) => ride.id),
    rides
      .filter((ride) => ride[type].length > 0)
      .map((ride) => ({
        id: ride.id,
        route: ride.route,
        reasons: ride[type],
      })),
  ];
};
