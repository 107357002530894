import {
  addNotification,
  Breakpoint,
  NotificationType,
  ResponsiveRenderer,
  dlPush,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { useIntl } from "react-intl";
import ErrorBoundary from "../../../../shared/components/errorBoundary/ErrorBoundary";
import {
  addRideUpdateErrors,
  removeRideUpdateError,
} from "../../../../state/rideUpdateErrors/handleRideUpdateErrors";
import { RideUpdate } from "../../../../state/rideUpdateErrors/types";
import {
  FindRidesQuery,
  useCancelRideMutation,
  useRestartSalesMutation,
  useStopSalesMutation,
  useUpdateSeatsOnOfferMutation,
} from "../../api/operations.generated";
import RideCard from "../../ui/rideCard/RideCard";
import RideTableRowError from "../../ui/rideTableRowError/RideTableRowError";
import RideAccordion from "../rideAccordion/RideAccordion";

type Props = {
  ride: FindRidesQuery["findRidesByDeparture"][0];
  rideUpdateErrors?: Array<RideUpdate>;
};

const RideTableRow: React.FC<Props> = ({ ride, rideUpdateErrors }) => {
  const { formatMessage } = useIntl();

  const [updateSeatsOnOffer, { error }] = useUpdateSeatsOnOfferMutation({
    onCompleted: () => {
      dlPush({ event: "capacity_change" });

      removeRideUpdateError(ride.id, RideUpdate.UpdateSeats);
      addNotification({
        message: formatMessage({ id: "ride.notification.updated" }),
        type: NotificationType.Success,
      });
    },
    onError: () => {
      addRideUpdateErrors([ride.id], RideUpdate.UpdateSeats);
      addNotification({
        message: formatMessage({ id: "ride.update.seatsOnOffer.fail" }),
        type: NotificationType.Danger,
      });
    },
  });

  const onSeatsOnOfferChange = (expectedSeatsOnOffer: number) =>
    updateSeatsOnOffer({ variables: { rides: [ride.id], expectedSeatsOnOffer } });

  const [cancelRide] = useCancelRideMutation({
    onCompleted: () => {
      addNotification({
        message: formatMessage({ id: "ride.notification.rideCanceled" }),
      });
    },
  });

  const [stopSales] = useStopSalesMutation({
    onCompleted: () => {
      removeRideUpdateError(ride.id, RideUpdate.StopSales);
      addNotification({
        message: formatMessage({ id: "ride.notification.salesStopped" }),
        type: NotificationType.Success,
      });
    },
    onError: () => {
      addRideUpdateErrors([ride.id], RideUpdate.StopSales);
      addNotification({
        message: formatMessage({ id: "ride.update.stopSales.fail" }),
        type: NotificationType.Danger,
      });
    },
  });

  const [restartSales] = useRestartSalesMutation({
    onCompleted: () => {
      removeRideUpdateError(ride.id, RideUpdate.RestartSales);
      addNotification({
        message: formatMessage({ id: "ride.notification.restartSale.success" }),
        type: NotificationType.Success,
      });
    },
    onError: () => {
      addRideUpdateErrors([ride.id], RideUpdate.RestartSales);
      addNotification({
        message: formatMessage({ id: "ride.update.restartSales.fail" }),
        type: NotificationType.Danger,
      });
    },
  });

  return (
    <ErrorBoundary errorComponent={<RideTableRowError rideId={ride.id} />} info={ride.id}>
      <ResponsiveRenderer
        hidden={[
          Breakpoint.XXS,
          Breakpoint.XS,
          Breakpoint.SM,
          Breakpoint.MD,
          Breakpoint.LG,
        ]}
      >
        <RideAccordion
          ride={ride}
          onSeatsOnOfferChange={onSeatsOnOfferChange}
          seatsOnOfferError={!!error}
          onStopSales={() => stopSales({ variables: { rides: [ride.id] } })}
          onRestartSales={() => restartSales({ variables: { rides: [ride.id] } })}
          onRideCancellation={() => cancelRide({ variables: { id: ride.id } })}
          rideUpdateErrors={rideUpdateErrors}
        />
      </ResponsiveRenderer>
      <ResponsiveRenderer hidden={[Breakpoint.XL]}>
        <RideCard
          ride={ride}
          onSeatsOnOfferChange={onSeatsOnOfferChange}
          onStopSales={() => stopSales({ variables: { rides: [ride.id] } })}
          onRestartSales={() => restartSales({ variables: { rides: [ride.id] } })}
          onRideCancellation={() => cancelRide({ variables: { id: ride.id } })}
        />
      </ResponsiveRenderer>
    </ErrorBoundary>
  );
};

export default RideTableRow;
export const MemoizedRideTableRow = React.memo(RideTableRow);
