import { FindAllPartnersQuery } from "../../../shared/api/operations.generated";

export const sort = (
  array: FindAllPartnersQuery["findAllPartners"]
): FindAllPartnersQuery["findAllPartners"] => {
  return [...array].sort((a, b) => a.name.localeCompare(b.name));
};

export const filter = (
  filterValue: string,
  array: FindAllPartnersQuery["findAllPartners"]
): FindAllPartnersQuery["findAllPartners"] => {
  return array.filter((entry) =>
    entry.name.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase())
  );
};
