const CONTRACT_PARTNER_DELIMITER = "//";
export const CONTRACT_PARTNER_KEY = "partner_v2";

export const setContractPartner = (
  contractPartnerId: string,
  contractPartnerName: string
): void => {
  localStorage.setItem(
    CONTRACT_PARTNER_KEY,
    btoa(encodeURI(contractPartnerId + CONTRACT_PARTNER_DELIMITER + contractPartnerName))
  );
};

export const getContractPartner = (): {
  contractPartnerId: string;
  contractPartnerName: string;
} => {
  const storedItem = localStorage.getItem(CONTRACT_PARTNER_KEY);
  if (storedItem !== null) {
    const partnerData = decodeURI(atob(storedItem)).split(CONTRACT_PARTNER_DELIMITER);
    return {
      contractPartnerId: partnerData[0],
      contractPartnerName: partnerData[1],
    };
  }

  return { contractPartnerId: "", contractPartnerName: "" };
};
