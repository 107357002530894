import { RideFilter } from "../../../state/useRideFilter/useRideFilter";
import { QueryFindRidesByDepartureArgs } from "../../types/schema";
import { formatApiDate } from "../formatDate/formatDate";
import { getContractPartner } from "../selectedContractPartner/selectedContractPartner";

export default (
  rideFilter: RideFilter,
  excludeHideCompletedRides: boolean,
  excludeAllFilters: boolean
): QueryFindRidesByDepartureArgs => {
  return {
    start: `${formatApiDate(rideFilter.period.startDate)}T00:00`,
    end: `${formatApiDate(rideFilter.period.endDate)}T23:59`,
    filter: excludeAllFilters
      ? undefined
      : {
          flixLineCode: rideFilter.flixLineCode?.length
            ? rideFilter.flixLineCode
            : undefined,
          departureStation: rideFilter.departureStation?.length
            ? rideFilter.departureStation
            : undefined,
          departureTime: rideFilter.departureTime.length
            ? rideFilter.departureTime
            : undefined,
          hideCompletedRides: excludeHideCompletedRides
            ? undefined
            : rideFilter.hideCompletedRides,
          departureDays: rideFilter.departureDays.length
            ? rideFilter.departureDays
            : undefined,
        },
    contractPartnerId: getContractPartner().contractPartnerId,
  };
};
