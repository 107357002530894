import { Heading, Skeleton } from "@flixbus/honeycomb-react";
import { hasUserPermission, Feature } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { useFindAllPartnersQuery } from "../../api/operations.generated";
import { getContractPartner } from "../../helpers/selectedContractPartner/selectedContractPartner";
import ChangeSelectedPartnerButton from "../changeSelectedPartnerButton/ChangeSelectedPartnerButton";
import * as css from "./PartnerHeadline.scss";

export type PartnerHeadlineProps = {
  headline: string;
};

const PartnerHeadline: React.FC<PartnerHeadlineProps> = ({ headline }) => {
  const { data, loading } = useFindAllPartnersQuery({
    fetchPolicy: "cache-first",
  });

  if (loading) {
    return (
      <div className={css.wrapper}>
        <Skeleton width="sm" flushBottom extraClasses={css.headerSkeleton} />
        <Skeleton width="xs" flushBottom extraClasses={css.buttonSkeleton} />
      </div>
    );
  }

  return (
    <div className={css.wrapper}>
      <div className={css.heading}>
        <Heading size={1} flushSpace>
          {data?.findAllPartners && data.findAllPartners.length > 1
            ? getContractPartner().contractPartnerName || headline
            : headline}
        </Heading>
      </div>
      <div>
        {data?.findAllPartners &&
          data.findAllPartners.length > 1 &&
          hasUserPermission(Feature.CHANGE_PARTNER) && <ChangeSelectedPartnerButton />}
      </div>
    </div>
  );
};

export default PartnerHeadline;
