import { CapacityManagementNotPossibleReasons } from "../../../../shared/types/schema";

export default (reasons: Array<CapacityManagementNotPossibleReasons>) => {
  if (
    reasons.some((reason) =>
      [
        CapacityManagementNotPossibleReasons.RideHasArrived,
        CapacityManagementNotPossibleReasons.RideIsCancelled,
      ].includes(reason)
    )
  ) {
    return true;
  }

  if (
    reasons.some((reason) =>
      [
        CapacityManagementNotPossibleReasons.SalesStoppedManually,
        CapacityManagementNotPossibleReasons.SalesStoppedAutomatically,
      ].includes(reason)
    )
  ) {
    return false;
  }

  return true;
};
