import { formatMessage } from "@flixbus-phx/marketplace-common";
import {
  BookingInformationField,
  PassengerInformationField,
  TripInformationField,
} from "../../../shared/types/schema";

export type BookingDataField =
  | BookingInformationField
  | TripInformationField
  | PassengerInformationField;

const translationKeys = new Map<BookingDataField, string>([
  [BookingInformationField.FlixOrderId, "bookingInfo.bookingInformation.flixOrderId"],
  [
    BookingInformationField.PartnerOrderId,
    "bookingInfo.bookingInformation.partnerOrderId",
  ],
  [BookingInformationField.BookingDate, "bookingInfo.bookingInformation.bookingDate"],
  [BookingInformationField.PaidPrice, "bookingInfo.bookingInformation.paidPrice"],
  [BookingInformationField.BoardingPass, "bookingInfo.bookingInformation.boardingPass"],
  [BookingInformationField.Invoice, "bookingInfo.bookingInformation.invoice"],

  [TripInformationField.LineCode, "bookingInfo.tripInformation.lineCode"],
  [TripInformationField.TravelDate, "general.travelDate"],
  [TripInformationField.DepartureTime, "bookingInfo.tripInformation.departureTime"],
  [TripInformationField.ArrivalTime, "bookingInfo.tripInformation.arrivalTime"],
  [
    TripInformationField.DepartureAndArrivalStation,
    "bookingInfo.tripInformation.departureAndArrivalStation",
  ],
  [
    TripInformationField.InterconnectionTrip,
    "bookingInfo.tripInformation.interconnectionTrip",
  ],

  [PassengerInformationField.Name, "bookingInfo.passengerInformation.name"],
  [PassengerInformationField.Phone, "bookingInfo.passengerInformation.phone"],
  [PassengerInformationField.EMail, "bookingInfo.passengerInformation.email"],
  [PassengerInformationField.Nationality, "bookingInfo.passengerInformation.nationality"],
  [PassengerInformationField.Gender, "bookingInfo.passengerInformation.gender"],
  [PassengerInformationField.TicketType, "bookingInfo.passengerInformation.ticketType"],
  [PassengerInformationField.Birthday, "bookingInfo.passengerInformation.birthday"],
  [
    PassengerInformationField.IdentityDocument,
    "bookingInfo.passengerInformation.identityDocument",
  ],
  [
    PassengerInformationField.IdentityDocumentNumber,
    "bookingInfo.passengerInformation.identityDocumentNumber",
  ],
  [PassengerInformationField.SeatLabels, "bookingInfo.passengerInformation.seatLabels"],
  [PassengerInformationField.Luggage, "bookingInfo.passengerInformation.luggage"],
  [PassengerInformationField.Wheelchair, "bookingInfo.passengerInformation.wheelchair"],
  [
    PassengerInformationField.CheckInStatus,
    "bookingInfo.passengerInformation.checkInStatus",
  ],
]);

export const getLabelTextForBookingData = (field: BookingDataField): string => {
  return formatMessage(translationKeys.get(field) || "bookingInfo.unknownField");
};

export const isDefaultValue = (field: BookingDataField): boolean => {
  return [
    BookingInformationField.FlixOrderId,
    TripInformationField.LineCode,
    TripInformationField.TravelDate,
    TripInformationField.DepartureTime,
    TripInformationField.ArrivalTime,
    TripInformationField.DepartureAndArrivalStation,
    PassengerInformationField.Name,
  ].includes(field);
};
