import { datadogRum } from "@datadog/browser-rum";
import { Icon, IconQrCode } from "@flixbus/honeycomb-icons-react";
import { Button } from "@flixbus/honeycomb-react";
import { addNotification, dlPush } from "@flixbus-phx/marketplace-common";
import React from "react";
import { FormattedMessage } from "react-intl";
import { PassengerUpdateAction } from "../../../shared/types/schema";
import { useUpdateOrderPassengerStatusMutation } from "../../api/operations.generated";
import {
  getErrorNotification,
  getSuccessNotification,
} from "../../helper/getNotifications/getNotifications";
import QrScannerPanel from "../qrScannerPanel/QrScannerPanel";

type Props = {
  rideId: string;
};

const QrCheckin: React.FC<Props> = ({ rideId }) => {
  const [showQrCodePanel, setShowQrCodePanel] = React.useState(false);

  const [updateOrderPaxStatus, { loading }] = useUpdateOrderPassengerStatusMutation({
    onCompleted: (_, options) => {
      if (options?.variables) {
        addNotification(getSuccessNotification(PassengerUpdateAction.CheckIn));
      }
      datadogRum.addAction("checkInSuccessful", {
        checkInType: "scan",
      });
    },
    onError: (error, options) => {
      if (options?.variables) {
        addNotification(getErrorNotification(error));
      }
      datadogRum.addAction("checkInFailed", {
        checkInType: "scan",
        checkInFailureReason: error.message,
      });
    },
  });

  return (
    <>
      <Button
        loading={loading}
        appearance="primary"
        onClick={() => setShowQrCodePanel(true)}
        Elem="a"
      >
        <Icon InlineIcon={IconQrCode} />
        <FormattedMessage id="checkIn.autoCheckin.text" />
      </Button>
      <QrScannerPanel
        active={showQrCodePanel}
        onTicketScanned={(orderId) => {
          setShowQrCodePanel(false);
          dlPush({ event: "qr_checkin" });
          updateOrderPaxStatus({
            variables: {
              orderId,
              rideId: rideId!,
            },
          });
        }}
        onClose={() => {
          setShowQrCodePanel(false);
        }}
      />
    </>
  );
};

export default QrCheckin;
