import { Tooltip } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as css from "./ChartLegend.scss";

type Props = {
  seatsCanceled?: boolean;
};

const ChartLegend: React.FC<Props> = ({ seatsCanceled = false }) => {
  const { formatMessage } = useIntl();

  return (
    <div className={css.container}>
      <div data-id="chart-legend-seats-sold-cancelled" className={css.wrapper}>
        <div
          className={`${css.legendColor} ${
            seatsCanceled ? css.legendColorGray : css.legendColorBlue
          }`}
        />
        <span>
          {seatsCanceled ? (
            <FormattedMessage id="ride.chart.legend.seatsCanceled" />
          ) : (
            <FormattedMessage id="ride.chart.legend.seatsSold" />
          )}
        </span>
      </div>
      <Tooltip
        id="tooltip-seats-reserved"
        content={formatMessage({ id: "ride.chart.tooltip.seatsReserved" })}
        position="top"
        openOnHover
      >
        <div data-id="chart-legend-seats-reserved" className={css.wrapper}>
          <div className={`${css.legendColor} ${css.legendColorLightBlue}`} />
          <span>
            <FormattedMessage id="ride.chart.legend.seatsReserved" />
          </span>
        </div>
      </Tooltip>
      <div data-id="chart-legend-desired-capacity" className={css.wrapper}>
        <div className={`${css.legendColor} ${css.legendColorGreen}`} />
        <span>
          <FormattedMessage id="ride.chart.desiredCapacity" />
        </span>
      </div>
      <div data-id="chart-legend-minimum-capacity" className={css.wrapper}>
        <div className={`${css.legendColor} ${css.legendColorRed}`} />
        <span>
          <FormattedMessage id="ride.chart.minimumCapacity" />
        </span>
      </div>
    </div>
  );
};

export default ChartLegend;
