import "normalize.css/normalize.css";
import { MainWrapper, PageContainer } from "@flixbus/honeycomb-react";
import { ViewportProvider } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import POPUP_PORTAL_ID from "../shared/components/confirmationPopup/PopupPortalId";
import * as css from "./Layout.scss";

const Layout: React.FC = ({ children }) => (
  <MainWrapper full>
    <PageContainer>
      <ViewportProvider>
        <div data-id="container" className={css.container}>
          {children}
          <div id={POPUP_PORTAL_ID} />
        </div>
      </ViewportProvider>
    </PageContainer>
  </MainWrapper>
);

export default Layout;
