@import "variables";

.wrapper {
  margin-left: auto;
  margin-right: auto;
  width: 415px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include full-height-minus-header;
}

.header {
  text-align: center;
  margin-bottom: $spacing-4;
}

.buttonWrapper {
  padding-top: $spacing-4;
  display: flex;
  justify-content: center;
}

.topButtonWrapper {
  margin-bottom: $spacing-4;
  text-align: center;
}
