import { Button } from "@flixbus/honeycomb-react";
import {
  Breakpoint,
  mpoPathnames as pathnames,
  useIsInRange,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

const ChangeSelectedPartnerButton: React.FC = () => {
  const navigate = useNavigate();
  const isMdButton = useIsInRange([Breakpoint.MD, Breakpoint.LG, Breakpoint.XL]);

  return (
    <Button
      appearance="tertiary"
      onClick={() =>
        navigate(pathnames.partnerOverviewWithReturnLocation(window.location.pathname))
      }
      data-id="bd-overview-button"
      size={isMdButton ? "md" : "sm"}
    >
      <FormattedMessage id="partnerHeadline.changePartner.button" />
    </Button>
  );
};

export default ChangeSelectedPartnerButton;
