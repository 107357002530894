/* 
Hack: There is an Issue in Chrome with onMouseLeave and OnMouseEnter not always firing properly 
when the <Button /> is disabled. Here we create an invisible container that sits over 
the <Button /> which serve as the Tooltip target and it's only being rendered 
if the <Button /> is disabled.
*/
.tooltipWrapper {
  position: relative;

  & > span {
    position: absolute;
    z-index: 11;
  }
}

.tooltipTarget {
  width: 142px;
  height: 36px;
  cursor: not-allowed;
}

.actionButton {
  width: 100%;
}
