import {
  fetchData,
  getFileName,
  getFileUrl,
  startDownload,
} from "../../../shared/helpers/downloadFileViaRest/downloadFileViaRest";
import {
  BookingInformationConfiguration,
  QueryFindRidesByDepartureArgs,
} from "../../../shared/types/schema";

export type DownloadBookingInfoFieldConfigurationParameter = {
  bookingInformation: BookingInformationConfiguration["bookingInformation"];
  tripInformation: BookingInformationConfiguration["tripInformation"];
  passengerInformation: BookingInformationConfiguration["passengerInformation"];
};

const downloadBookingInfo = async (
  findRidesQueryArgs: QueryFindRidesByDepartureArgs,
  fieldConfiguration: DownloadBookingInfoFieldConfigurationParameter
) => {
  try {
    const response = await fetchData("/bookings", {
      ...findRidesQueryArgs,
      fieldConfiguration: {
        ...fieldConfiguration,
      },
    });
    const fileName = getFileName(response);
    const fileUrl = await getFileUrl(response);

    startDownload(fileName, fileUrl);

    return undefined;
  } catch (error) {
    return error;
  }
};

export default downloadBookingInfo;
