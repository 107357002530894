import { makeVar, ReactiveVar, useReactiveVar } from "@apollo/client";
import { Ride } from "../../shared/types/schema";

export type SelectedRides = Array<Ride["id"]>;

export const selectedRidesVar = makeVar<SelectedRides>([]);

const useSelectedRides = (
  reactiveVar: ReactiveVar<SelectedRides>
): [
  SelectedRides,
  (newRide: SelectedRides[number]) => void,
  (newRides: SelectedRides) => void,
  (ride: SelectedRides[number]) => void,
  () => void,
] => {
  const selectedRides = useReactiveVar(reactiveVar);

  const addOne = (newRide: SelectedRides[number]) => {
    if (!selectedRides.includes(newRide)) {
      reactiveVar([...selectedRides, newRide]);
    }
  };

  const setMultiple = (newRides: SelectedRides) => {
    reactiveVar(newRides);
  };

  const removeOne = (ride: SelectedRides[number]) => {
    reactiveVar(selectedRides.filter((existingRide) => existingRide !== ride));
  };

  const removeAll = () => {
    return reactiveVar([]);
  };

  return [selectedRides, addOne, setMultiple, removeOne, removeAll];
};

export default useSelectedRides;
