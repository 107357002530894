import { ConnectionStop } from "@flixbus/honeycomb-react";
import * as React from "react";
import { Station, StationTime } from "../../types/schema";
import { formatDate } from "../formatDate/formatDate";

export type Stop = {
  station: string;
  time: string;
};

const generateStopsForConnectionComponent = (
  departureStation: Station["name"],
  departureTime: StationTime["localDeparture"],
  arrivalStation?: Station["name"],
  arrivalTime?: StationTime["localArrival"]
): Array<JSX.Element> => {
  const connectionStops = [
    <ConnectionStop
      key={departureStation}
      station={departureStation}
      time={formatDate(departureTime!, "HH:mm")}
    />,
  ];

  if (arrivalStation && arrivalTime) {
    connectionStops.push(
      <ConnectionStop
        key={arrivalStation}
        station={arrivalStation}
        time={formatDate(arrivalTime!, "HH:mm")}
      />
    );
  }

  return connectionStops;
};

export default generateStopsForConnectionComponent;
