import { Accordion, Heading, Grid, GridCol } from "@flixbus/honeycomb-react";
import {
  Footer,
  contactEmail,
  getUserPreferredLanguage,
  spaLocales,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as css from "./Faq.scss";

const Faq: React.FC = () => {
  const { formatMessage } = useIntl();

  const createMarkup = (content: string) => {
    return { __html: content };
  };

  return (
    <div className={css.container}>
      <Heading size={1}>
        <FormattedMessage id="faq.headline" />
      </Heading>

      <Heading size={2}>
        <FormattedMessage id="faq.capacitymanager" />
      </Heading>
      <div className={css.capacityManagerQuestions}>
        <Grid>
          <GridCol md={6} size={12}>
            <Accordion title={formatMessage({ id: "faq.howLongForChange.question" })}>
              <p>
                <FormattedMessage id="faq.howLongForChange.answer" />
              </p>
            </Accordion>
            <Accordion title={formatMessage({ id: "faq.caculateMinimum.question" })}>
              <p
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={createMarkup(
                  formatMessage(
                    { id: "faq.calculateMinimum.answer" },
                    {},
                    { ignoreTag: true }
                  )
                )}
              />
            </Accordion>
            <Accordion title={formatMessage({ id: "faq.minimumRule.question" })}>
              <p>
                <FormattedMessage id="faq.minimumRule.answer" />
              </p>
            </Accordion>
            <Accordion
              extraClasses={css.lastRowLeftCol}
              title={formatMessage({ id: "faq.stopSales.question" })}
            >
              <p
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={createMarkup(
                  formatMessage({ id: "faq.stopSales.answer" }, {}, { ignoreTag: true })
                )}
              />
            </Accordion>
          </GridCol>
          <GridCol md={6} size={12}>
            <Accordion
              extraClasses={css.firstRowRightCol}
              title={formatMessage({ id: "faq.seatsSold.question" })}
            >
              <p
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={createMarkup(
                  formatMessage({ id: "faq.seatsSold.answer" }, {}, { ignoreTag: true })
                )}
              />
            </Accordion>
            <Accordion title={formatMessage({ id: "faq.seatsSoldSegments.question" })}>
              <p
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={createMarkup(
                  formatMessage(
                    { id: "faq.seatsSoldSegments.answer" },
                    {},
                    { ignoreTag: true }
                  )
                )}
              />
            </Accordion>
            <Accordion title={formatMessage({ id: "faq.cancelRide.question" })}>
              <p
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={createMarkup(
                  formatMessage({ id: "faq.cancelRide.answer" }, {}, { ignoreTag: true })
                )}
              />
            </Accordion>
            <Accordion title={formatMessage({ id: "faq.ridesNotVisible.question" })}>
              <p
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={createMarkup(
                  formatMessage(
                    { id: "faq.ridesNotVisible.answer" },
                    { supportEmail: contactEmail },
                    { ignoreTag: true }
                  )
                )}
              />
            </Accordion>
          </GridCol>
        </Grid>
      </div>
      <Heading size={2}>
        <FormattedMessage id="faq.pricing" />
      </Heading>
      <div>
        <Grid>
          <GridCol md={6} size={12}>
            <Accordion
              title={formatMessage({
                id: "faq.pricing.howOftenPriceChange.question",
              })}
            >
              <p>
                <FormattedMessage id="faq.pricing.howOftenPriceChange.answer" />
              </p>
            </Accordion>
            <Accordion
              title={formatMessage({ id: "faq.pricing.forbiddenRelation.question" })}
            >
              <p>
                <FormattedMessage id="faq.pricing.forbiddenRelation.answer" />
              </p>
            </Accordion>
            <Accordion
              title={formatMessage({ id: "faq.pricing.changeCurrency.question" })}
            >
              <p>
                <FormattedMessage id="faq.pricing.changeCurrency.answer" />
              </p>
            </Accordion>
            <Accordion
              extraClasses={css.lastRowLeftCol}
              title={formatMessage({ id: "faq.pricing.whenToEnterPrices.question" })}
            >
              <p>
                <FormattedMessage id="faq.pricing.whenToEnterPrices.answer" />
              </p>
            </Accordion>
          </GridCol>
          <GridCol md={6} size={12}>
            <Accordion
              extraClasses={css.firstRowRightCol}
              title={formatMessage({ id: "faq.pricing.reusePrices.question" })}
            >
              <p>
                <FormattedMessage id="faq.pricing.reusePrices.answer" />
              </p>
            </Accordion>
            <Accordion
              title={formatMessage({ id: "faq.pricing.refreshPeriod.question" })}
            >
              <p
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={createMarkup(
                  formatMessage(
                    { id: "faq.pricing.refreshPeriod.answer" },
                    { supportEmail: contactEmail },
                    { ignoreTag: true }
                  )
                )}
              />
            </Accordion>
            <Accordion
              title={formatMessage({ id: "faq.pricing.changeSchedule.question" })}
            >
              <p>
                <FormattedMessage id="faq.pricing.changeSchedule.answer" />
              </p>
            </Accordion>
          </GridCol>
        </Grid>
      </div>
      <div className={css.contact}>
        <p
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={createMarkup(
            formatMessage(
              { id: "faq.moreQuestions" },
              { supportEmail: contactEmail },
              { ignoreTag: true }
            )
          )}
        />
      </div>
      <Footer
        isSpa
        locale={
          spaLocales.find((l) => getUserPreferredLanguage() === l.locale)?.locale || "en"
        }
      />
    </div>
  );
};

export default Faq;
