import * as React from "react";

export type HandleOutsideClickProps = {
  onClickOutside: () => void;
};

const HandleOutsideClick: React.FC<HandleOutsideClickProps> = ({
  onClickOutside,
  children,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const handleClickOutside: EventListener = (event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      onClickOutside();
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return <div ref={ref}>{children}</div>;
};

export default HandleOutsideClick;
