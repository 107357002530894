import { Connection, Box, Heading } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import generateStopsForConnectionComponent from "../../../../shared/helpers/generateStopsForConnectionComponent/generateStopsForConnectionComponent";
import { SegmentsForChart } from "../../helpers/generateSegementsForChart/generateSegmentsForChart";
import * as css from "./ChartTooltip.scss";

export type ChartTooltipProps = {
  active?: boolean;
  data: SegmentsForChart[number];
};

const ChartTooltip: React.FC<ChartTooltipProps> = ({ active, data }) => {
  if (active) {
    const stops = generateStopsForConnectionComponent(
      data.departureStationName,
      data.departureTime,
      data.arrivalStationName,
      data.arrivalTime
    );

    return (
      <Box highlighted extraClasses={css.box}>
        <Heading sectionHeader size={4} data-id="seats-sold">
          <FormattedMessage
            id="ride.chart.seatsSold"
            values={{ seatsSold: data.seatsSold }}
          />
        </Heading>
        <Heading sectionHeader size={4} data-id="seats-reserved">
          <FormattedMessage
            id="ride.chart.seatsReserved"
            values={{ seatsReserved: data.seatsReserved }}
          />
        </Heading>
        <Heading sectionHeader size={4} data-id="seats-remaining">
          <FormattedMessage
            id="ride.chart.seatsRemaining"
            values={{ seatsRemaining: data.seatsRemaining }}
          />
        </Heading>
        <Connection>{stops}</Connection>
      </Box>
    );
  }

  return null;
};

export default ChartTooltip;
