@import "variables";

.wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1002;
  width: 100vw;
  padding: 12px;
  background: $bg-primary-color;
  display: flex;
  column-gap: $spacing-2;
  justify-content: space-between;

  @include on-bp(xl) {
    position: static;
    width: auto;
    padding: 0 0 $spacing-4;
    background: none;
  }

  > a {
    margin-top: 0 !important;
  }
}
