import { Icon, IconFilter } from "@flixbus/honeycomb-icons-react";
import { Button, PopupSection } from "@flixbus/honeycomb-react";
import { Breakpoint, useIsInRange } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CompletedRideFilter from "../../../../shared/components/completedRideFilter/CompletedRideFilter";
import ConfirmationPopup from "../../../../shared/components/confirmationPopup/ConfirmationPopup";
import useRideFilter, {
  RideFilter,
  rideFilterVar,
} from "../../../../state/useRideFilter/useRideFilter";
import RideFilterType from "../../types";
import DepartureDayFilter from "../departureDayFilter/DepartureDayFilter";
import RideFilterSelect from "../rideFilterSelect/RideFilterSelect";
import * as css from "./RideFilterPopup.scss";

type Props = {
  showCompletedRideFilter: boolean;
};

const RideFilterPopup: React.FC<Props> = ({ showCompletedRideFilter }) => {
  const [showPopup, setShowPopup] = React.useState(false);
  const [rideFilter, setRideFilter] = useRideFilter(rideFilterVar);
  const [selectedRideFilter, setSelectedRideFilter] =
    React.useState<RideFilter>(rideFilter);
  const isButtonSquared = useIsInRange([Breakpoint.XXS]);
  const { formatMessage } = useIntl();

  React.useEffect(() => {
    setSelectedRideFilter(rideFilter);
  }, [rideFilter]);

  const handleRideFilterSelect = (
    value: RideFilter[keyof RideFilter],
    key: keyof RideFilter
  ) => {
    setSelectedRideFilter({
      ...selectedRideFilter,
      [key]: value,
    });
  };

  return (
    <>
      <Button
        display={isButtonSquared ? "square" : "block"}
        onClick={() => setShowPopup(true)}
      >
        <Icon InlineIcon={IconFilter} aria-hidden="true" />
        {isButtonSquared ? null : <FormattedMessage id="ride.filter.title" />}
      </Button>
      {showPopup && (
        <ConfirmationPopup
          active
          titleText={formatMessage({ id: "ride.filter.title" })}
          onCancel={() => {
            setSelectedRideFilter(rideFilter);
            setShowPopup(false);
          }}
          onConfirm={() => {
            setRideFilter(selectedRideFilter);
            setShowPopup(false);
          }}
          hasReducedPaddingOnSmallScreen
        >
          <PopupSection type="content" extraClasses={css.container}>
            <div className={css.dayPicker}>
              <DepartureDayFilter
                onChange={(value) => handleRideFilterSelect(value, "departureDays")}
                values={selectedRideFilter.departureDays}
              />
            </div>
            <div className={css.rideFilterSelect}>
              <RideFilterSelect
                type={RideFilterType.flixLineCode}
                onChange={(value) => handleRideFilterSelect(value, "flixLineCode")}
                value={selectedRideFilter.flixLineCode}
                isSearchable={false}
              />
            </div>
            <div className={css.rideFilterSelect}>
              <RideFilterSelect
                type={RideFilterType.departureStation}
                onChange={(value) => handleRideFilterSelect(value, "departureStation")}
                value={selectedRideFilter.departureStation}
                isSearchable={false}
              />
            </div>
            <div className={css.rideFilterSelect}>
              <RideFilterSelect
                type={RideFilterType.departureTime}
                onChange={(value) => handleRideFilterSelect(value, "departureTime")}
                value={selectedRideFilter.departureTime}
                isSearchable={false}
              />
            </div>
            {showCompletedRideFilter && (
              <div className={css.completedRideFilter}>
                <CompletedRideFilter
                  onChange={(value) =>
                    handleRideFilterSelect(value, "hideCompletedRides")
                  }
                  value={selectedRideFilter.hideCompletedRides}
                />
              </div>
            )}
          </PopupSection>
        </ConfirmationPopup>
      )}
    </>
  );
};

export default RideFilterPopup;
