@import "variables";
@import "../../../../shared/styles/dropdown.scss";

.dropdown {
  > button {
    color: $ui-primary-color !important;
  }

  @include dropdown;
}
