import { Icon, IconArrowUp, IconArrowDown } from "@flixbus/honeycomb-icons-react";
import { Box } from "@flixbus/honeycomb-react";
import cx from "classnames";
import * as React from "react";
import { useState } from "react";
import * as css from "./Accordion.scss";

export type AccordionProps = {
  headerSection: JSX.Element;
  extraClasses?: string;
  onOpen?: () => void;
};

const Accordion: React.FC<AccordionProps> = ({
  headerSection,
  extraClasses,
  onOpen,
  children,
}) => {
  const [open, setOpen] = useState(false);

  const handleOnClick = () => {
    const newState = !open;
    setOpen(newState);
    if (newState && onOpen) {
      onOpen();
    }
  };

  return (
    <Box extraClasses={cx(css.box, extraClasses)} highlighted>
      <div
        onClick={(event) => {
          // break if click happened on an <input /> or a <label /> element
          // we do this to prevent clicks on the <RideCheckbox /> triggering a call to handleOnClick()
          const { tagName } = event.target as Element;
          if (tagName && ["LABEL", "INPUT"].includes(tagName)) {
            return;
          }

          handleOnClick();
        }}
        className={css.clickable}
        data-id="accordionClickable"
      >
        {headerSection}
        <div className={css.buttonArrow}>
          {!open && (
            <Icon data-id="icon-arrow-down" InlineIcon={IconArrowDown} size={4} />
          )}
          {open && <Icon data-id="icon-arrow-up" InlineIcon={IconArrowUp} size={4} />}
        </div>
      </div>
      {open && <div>{children}</div>}
    </Box>
  );
};

export default Accordion;
