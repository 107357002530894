// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type FindAllPartnersQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FindAllPartnersQuery = {
  __typename?: "Query";
  findAllPartners: Array<{
    __typename?: "Partner";
    name: string;
    contractPartnerId: string;
  }>;
};

export const FindAllPartnersDocument = gql`
  query FindAllPartners {
    findAllPartners {
      name
      contractPartnerId
    }
  }
`;

/**
 * __useFindAllPartnersQuery__
 *
 * To run a query within a React component, call `useFindAllPartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllPartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllPartnersQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindAllPartnersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FindAllPartnersQuery,
    FindAllPartnersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FindAllPartnersQuery, FindAllPartnersQueryVariables>(
    FindAllPartnersDocument,
    options
  );
}
export function useFindAllPartnersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindAllPartnersQuery,
    FindAllPartnersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FindAllPartnersQuery,
    FindAllPartnersQueryVariables
  >(FindAllPartnersDocument, options);
}
export function useFindAllPartnersSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    FindAllPartnersQuery,
    FindAllPartnersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    FindAllPartnersQuery,
    FindAllPartnersQueryVariables
  >(FindAllPartnersDocument, options);
}
export type FindAllPartnersQueryHookResult = ReturnType<typeof useFindAllPartnersQuery>;
export type FindAllPartnersLazyQueryHookResult = ReturnType<
  typeof useFindAllPartnersLazyQuery
>;
export type FindAllPartnersSuspenseQueryHookResult = ReturnType<
  typeof useFindAllPartnersSuspenseQuery
>;
