import { Divider } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FindRidesQuery } from "../../api/operations.generated";
import {
  LabelledRideDataCountPax,
  LabelledRideDataDeparture,
  LabelledRideDataRoute,
} from "../labelledRideData/LabelledRideData";
import * as css from "./RideSummary.scss";

type Props = {
  route: FindRidesQuery["findRidesByDeparture"][number]["route"];
  numberOfPassengers: number;
};

const RideSummary: React.FC<Props> = ({ route, numberOfPassengers }) => {
  return (
    <div>
      <Divider extraClasses={css.divider} />
      <div className={css.summaryWrapper}>
        <div>
          <LabelledRideDataDeparture route={route} />
        </div>
        <div>
          <LabelledRideDataRoute route={route} />
        </div>
        <div>
          <LabelledRideDataCountPax countPax={numberOfPassengers} />
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default RideSummary;
