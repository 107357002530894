@import "variables";
@import "../../helpers/tableStyles/TableStyles.scss";

.wrapper {
  height: $table-body-height;
  padding-top: 100px;
}

.emojiWrapper {
  width: 80px;
  margin-left: auto;
  margin-right: auto;
  font-size: 80px;
}

.textWrapper {
  margin: $spacing-8 auto 0 auto;
  text-align: center;
  width: 350px;
  font-size: 18px;
  font-weight: bold;
}
