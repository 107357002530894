@import "variables";

.table {
  border: $border;
  border-radius: $border-radius;
  margin-top: $spacing-2;
}

.body {
  max-height: 300px;
  overflow-y: scroll;
}

.header {
  border-bottom: $border;
  font-weight: $font-weight-bold;
  padding: $spacing-1 0;
}

.row {
  display: flex;
  flex-wrap: wrap;
  padding: $spacing-2;
  &:not(:last-child) {
    border-bottom: $border;
  }
}

.rideInfoWrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: $spacing-2;
}

.date {
  align-self: center;
  margin-right: $spacing-4;
}

.connection {
  text-align: left;
}

.reasons {
  width: 100%;
}

.reasonsBox {
  padding: $spacing-2;
}

.reasonsIcon {
  float: left;
}

.reasonsList {
  margin-bottom: 0;
  margin-left: 20px;
  text-align: left;
  > li {
    color: $grayscale-70-color;
    &::before {
      background: $grayscale-70-color;
    }
  }
}
