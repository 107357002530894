import { Icon, IconArrowLeftL } from "@flixbus/honeycomb-icons-react";
import { Button } from "@flixbus/honeycomb-react";
import {
  Feature,
  hasUserPermission,
  mpoPathnames as pathnames,
} from "@flixbus-phx/marketplace-common";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link, useParams } from "react-router-dom";
import QrCheckin from "../../qrCheckin/QrCheckin";
import * as css from "./PaxListNavigation.scss";

const PaxListNavigation: React.FC = () => {
  const { rideId } = useParams();

  return (
    <>
      <div className={css.wrapper}>
        <Button RouterLink={Link} to={pathnames.capacityManager}>
          <Icon InlineIcon={IconArrowLeftL} />
          <FormattedMessage id="general.back" />
        </Button>
        {hasUserPermission(Feature.CHECK_IN_QR) && <QrCheckin rideId={rideId!} />}
      </div>
    </>
  );
};

export default PaxListNavigation;
