/* 
We are using getDerivedStateFromError and componentDidCatch here which are not yet implemented
as hooks. Thats why we need a class based component
https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes
*/
import { datadogRum } from "@datadog/browser-rum";
import * as React from "react";

type State = {
  hasError: boolean;
};

type Props = {
  errorComponent: JSX.Element;
  info: string;
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    const { info } = this.props;
    datadogRum.addError(error, { ride: info });
  }

  render() {
    const { hasError } = this.state;
    const { children, errorComponent } = this.props;

    if (hasError) {
      return errorComponent;
    }
    return children;
  }
}

export default ErrorBoundary;
