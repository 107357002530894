import * as React from "react";
import { useIntl } from "react-intl";
import Autocomplete from "../../../../shared/components/autocomplete/Autocomplete";
import InputApiError from "../../../../shared/components/inputApiError/InputApiError";
import getFindRidesQueryVars from "../../../../shared/helpers/getFindRidesQueryVars/getFindRidesQueryVars";
import useRideFilter, {
  RideFilter,
  rideFilterVar,
} from "../../../../state/useRideFilter/useRideFilter";
import { useFindRideFilterDataQuery } from "../../api/operations.generated";
import getOptionsForFilter from "../../helpers/getOptionsForFilter/getOptionsForFilter";
import RideFilterType from "../../types";

export type RideFilterSelectProps = {
  type: RideFilterType;
  onChange?: (
    value:
      | RideFilter["departureStation"]
      | RideFilter["flixLineCode"]
      | RideFilter["departureTime"]
  ) => void;
  isSearchable?: boolean;
  value?:
    | RideFilter["departureStation"]
    | RideFilter["flixLineCode"]
    | RideFilter["departureTime"];
};

const RideFilterSelect: React.FC<RideFilterSelectProps> = ({
  type,
  onChange,
  isSearchable,
  value,
}) => {
  const [rideFilter, setRideFilter] = useRideFilter(rideFilterVar);
  const intl = useIntl();

  const { data, loading, error } = useFindRideFilterDataQuery({
    variables: getFindRidesQueryVars(rideFilter, false, true),
    fetchPolicy: "cache-first",
  });

  const handleSelect = (selectedValue: string) => {
    if (onChange && value !== undefined) {
      onChange(selectedValue);
    } else {
      const changedFilter = { ...rideFilter };
      changedFilter[type] = selectedValue;
      setRideFilter(changedFilter);
    }
  };

  if (error) {
    return (
      <InputApiError
        label={intl.formatMessage({ id: `ride.filter.${type}` })}
        info={intl.formatMessage({ id: `ride.filter.${type}.errorMessage` })}
      />
    );
  }

  return (
    <Autocomplete
      onValueSelected={handleSelect}
      options={
        data?.findRidesByDeparture
          ? getOptionsForFilter(data.findRidesByDeparture, type)
          : []
      }
      initialValue={value || rideFilter[type]}
      defaultValue={intl.formatMessage({ id: "ride.filter.select.default" })}
      label={intl.formatMessage({ id: `ride.filter.${type}` })}
      loading={loading}
      isSearchable={isSearchable}
    />
  );
};

export default RideFilterSelect;
