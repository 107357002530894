import { Icon, IconPause } from "@flixbus/honeycomb-icons-react";
import { Button, List, Tooltip } from "@flixbus/honeycomb-react";
import cx from "classnames";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { CapacityManagementNotPossibleReasons } from "../../../../shared/types/schema";
import RideActionPopup, {
  RideActionType,
} from "../../ui/rideActionPopup/RideActionPopup";
import * as css from "./StopSalesButton.scss";

const StopSalesTooltipText: React.FC = () => (
  <div>
    <b>
      <FormattedMessage id="ride.stopSales.button.hint" />
    </b>
  </div>
);

export type StopSalesButtonProps = {
  onStopSales: () => void;
  salesStopNotPossibleReasons: Array<CapacityManagementNotPossibleReasons>;
};

const StopSalesButton: React.FC<StopSalesButtonProps> = ({
  onStopSales,
  salesStopNotPossibleReasons,
  children,
}) => {
  const [popupActive, setPopupActive] = React.useState(false);
  const [confirmationLoading, setConfirmationLoading] = React.useState(false);
  const isDisabled = salesStopNotPossibleReasons.length > 0;

  const ButtonComponent = (
    <Button
      extraClasses={css.button}
      appearance="primary"
      disabled={isDisabled}
      onClick={() => setPopupActive(true)}
    >
      <Icon InlineIcon={IconPause} />
      <FormattedMessage id="ride.stopSales.button" />
    </Button>
  );

  return (
    <>
      {isDisabled && (
        <>
          <div
            className={cx(css.tooltipWrapper, css.tooltipWrapperDisabled)}
            data-id="disabled-tooltip-wrapper"
          >
            <Tooltip
              id="StopSalesNotPossibleReasonsTooltip"
              position="left"
              openOnHover
              content={
                <div>
                  <StopSalesTooltipText />
                  <List>
                    {salesStopNotPossibleReasons.map((reason) => (
                      <li key={reason}>
                        <FormattedMessage
                          id={`ride.CapacityManagementNotPossibleReasons.${reason}`}
                        />
                      </li>
                    ))}
                  </List>
                </div>
              }
            >
              <div className={css.tooltipTarget} />
            </Tooltip>
          </div>
          {ButtonComponent}
        </>
      )}

      {!isDisabled && (
        <div className={css.tooltipWrapper}>
          <Tooltip
            id="StopSalesTooltip"
            position="left"
            openOnHover
            content={
              <div>
                <StopSalesTooltipText />
              </div>
            }
          >
            {ButtonComponent}
          </Tooltip>

          {popupActive && (
            <RideActionPopup
              type={RideActionType.STOP_SALES}
              onConfirm={() => {
                setConfirmationLoading(true);
                onStopSales();
              }}
              onCancel={() => setPopupActive(false)}
              submitLoading={confirmationLoading}
            >
              {children}
            </RideActionPopup>
          )}
        </div>
      )}
    </>
  );
};

export default StopSalesButton;
