@import "variables";
@import "../../helpers/tableStyles/TableStyles.scss";

.tableGrid {
  padding: 0 $spacing-2 0 $spacing-2;
  min-height: 60px;
  @include table-item-grid;
  > div {
    font-weight: bold;
    font-size: 14px;
    line-height: $line-height-small;
  }
}

.tableGridViewOnly {
  @include table-item-grid-view-only;
}

.textCenter {
  text-align: center;
}
