import { Ride } from "../../shared/types/schema";

export enum RideUpdate {
  UpdateSeats = "updateSeats",
  StopSales = "stopSales",
  RestartSales = "restartSales",
}

export type RideUpdateError = {
  id: Ride["id"];
  rideUpdate: RideUpdate;
};
