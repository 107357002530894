import * as React from "react";
import { useIntl } from "react-intl";
import RideFilter from "../ride/rideFilter/RideFilter";
import DataPrivacyContractCheck from "../shared/components/dataPrivacyContractCheck/DataPrivacyContractCheck";
import GreyResultBackground from "../shared/components/greyResultBackground/GreyResultBackground";
import PartnerHeadline from "../shared/components/partnerHeadline/PartnerHeadline";
import BookingInfoBody from "./containers/bookingInfoBody/BookingInfoBody";

const BookingInfo: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <DataPrivacyContractCheck>
      <PartnerHeadline headline={formatMessage({ id: "navigation.bookingInfo" })} />
      <RideFilter showCompletedRideFilterInPopup={false} />
      <GreyResultBackground>
        <BookingInfoBody />
      </GreyResultBackground>
    </DataPrivacyContractCheck>
  );
};

export default BookingInfo;
