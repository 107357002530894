import { Icon, IconForbidden } from "@flixbus/honeycomb-icons-react";
import { Button, Tooltip } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import RideActionPopup, { RideActionType } from "../rideActionPopup/RideActionPopup";
import * as css from "./RideCancellationButton.scss";

export type RideCancellationButtonProps = {
  disabled: boolean;
  onRideCancellation: () => void;
};

const RideCancellationButton: React.FC<RideCancellationButtonProps> = ({
  disabled,
  onRideCancellation,
  children,
}) => {
  const { formatMessage } = useIntl();

  const [popupActive, setPopupActive] = React.useState(false);
  const [confirmationLoading, setConfirmationLoading] = React.useState(false);

  return (
    <>
      <Tooltip
        id="rice-cancellation-button-tooltip"
        content={
          <div>
            <b>
              <FormattedMessage id="ride.rideCancellation.button.hint" />
            </b>
          </div>
        }
        openOnHover
        position="left"
        wrapAround="block"
      >
        <Button
          extraClasses={css.button}
          disabled={disabled}
          title={
            disabled ? formatMessage({ id: "ride.rideCancellation.button.hint" }) : ""
          }
          onClick={() => setPopupActive(true)}
        >
          <Icon InlineIcon={IconForbidden} />
          <FormattedMessage id="ride.rideCancellation.button" />
        </Button>
      </Tooltip>
      {popupActive && !disabled && (
        <RideActionPopup
          type={RideActionType.CANCELLATION}
          onConfirm={() => {
            setConfirmationLoading(true);
            onRideCancellation();
          }}
          onCancel={() => setPopupActive(false)}
          submitLoading={confirmationLoading}
        >
          {children}
        </RideActionPopup>
      )}
    </>
  );
};

export default RideCancellationButton;
