@import "../../helpers/tableStyles/TableStyles.scss";

.wrapper {
  @include on-bp(xl) {
    height: $table-body-height;
    padding: $spacing-2;
  }
}

.skeleton {
  height: 272px;
  width: 100%;
  margin-bottom: $spacing-2;
  @include on-bp(xl) {
    height: 76px;
  }
}
