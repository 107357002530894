import { Icon, IconPersonSolid } from "@flixbus/honeycomb-icons-react";
import { Tag } from "@flixbus/honeycomb-react";
import * as React from "react";
import * as css from "./PassengerCount.scss";

type Props = {
  passengers: number;
};

const PassengerCount: React.FC<Props> = ({ passengers }) => (
  <div>
    <Tag display="outlined">
      <Icon InlineIcon={IconPersonSolid} extraClasses={css.icon} />
      <p className={css.passengerCount}>{passengers}</p>
    </Tag>
  </div>
);
export default PassengerCount;
