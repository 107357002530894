import { Breakpoint, ResponsiveRenderer } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import * as css from "./RideFilter.scss";
import DepartureDayFilter from "./containers/departureDayFilter/DepartureDayFilter";
import PeriodFilter from "./containers/periodFilter/PeriodFilter";
import RideFilterPopup from "./containers/rideFilterPopup/RideFilterPopup";
import RideFilterSelect from "./containers/rideFilterSelect/RideFilterSelect";
import RideFilterType from "./types";

type Props = {
  showCompletedRideFilterInPopup: boolean;
};

const RideFilter: React.FC<Props> = ({ showCompletedRideFilterInPopup }) => {
  return (
    <>
      <div className={css.filterWrapper}>
        <div className={css.periodFilter}>
          <PeriodFilter />
        </div>
        <ResponsiveRenderer
          hidden={[
            Breakpoint.XXS,
            Breakpoint.XS,
            Breakpoint.SM,
            Breakpoint.MD,
            Breakpoint.LG,
          ]}
        >
          <div className={css.departureDayFilter}>
            <DepartureDayFilter />
          </div>
          <div className={css.lineFilter}>
            <RideFilterSelect type={RideFilterType.flixLineCode} />
          </div>
          <div className={css.stationFilter}>
            <RideFilterSelect type={RideFilterType.departureStation} />
          </div>
          <div className={css.timeFilter}>
            <RideFilterSelect type={RideFilterType.departureTime} />
          </div>
        </ResponsiveRenderer>
        <ResponsiveRenderer hidden={[Breakpoint.XL]}>
          <div>
            <RideFilterPopup showCompletedRideFilter={showCompletedRideFilterInPopup} />
          </div>
        </ResponsiveRenderer>
      </div>
      <div className={css.border} />
    </>
  );
};

export default RideFilter;
