@import "variables";
@import "../../helpers/tableStyles/TableStyles.scss";

.rideWrapper:first-child {
  padding-top: $spacing-2;
}

.rideConnection {
  width: 100%;
}

.orderSummary {
  position: relative;
}

.accordion {
  // "invisible" border is needed because in the error case there is a border, so there also has to be one if there is no error
  border: 1px solid transparent;

  @include table-item-layout;
}

.accordionError {
  border: 1px solid $danger-color;
}

.accordionPreview {
  opacity: 0.75;
  border: 2px dotted #444;
  background-color: #cdcdcd;
  color: #353535;

  input[type="checkbox"] + label:before {
    border-color: #353535;
  }

  .rideConnection {
    table {
      color: #353535;
    }
  }
}

.rideGrid {
  min-height: $table-item-min-height;
  @include table-item-grid;
}

.rideGridViewOnly {
  @include table-item-grid-view-only;
}

.hiddenSectionWrapper {
  padding: 0 $spacing-2 $spacing-4 $spacing-2;
}

.hiddenSection {
  border-top: $border;
}

.hiddenSectionGrid {
  display: grid;
  grid-template-columns: 840px 240px;
  grid-template-rows: 133px auto auto;
  column-gap: $spacing-4;
  width: 100%;
  margin-top: $spacing-6;
}

.hiddenSectionGridLeft {
  grid-area: 1 / 1 / 3 / 2;
}

.hiddenSectionGridRightTop {
  grid-area: 1 / 2 / 2 / 3;
  align-self: start;
  padding-top: $spacing-2;
}

.hiddenSectionGridRightCenter {
  grid-area: 2 / 2 / 3 / 3;
  align-self: start;
  padding-top: $spacing-2;
}

.hiddenSectionGridBottom {
  grid-area: 3 / 1 / 4 / 3;
  display: flex;
  justify-content: space-between;
}

.rideItemActionButton {
  & + .rideItemActionButton {
    margin-top: $spacing-2;
  }
}

.spinnerGrid {
  width: 800px;
  height: 280px;
  text-align: center;
  padding-top: $spacing-8;
}

.lastModifiedCell {
  font-size: 14px;
  justify-self: left;
  line-height: 1.4;
}
