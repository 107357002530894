import { Ride } from "../../shared/types/schema";
import rideUpdateErrorsVar from "./reactiveVariable";
import { RideUpdate, RideUpdateError } from "./types";

// helper function to add errors and to return an array of all errors without duplicates
export const addErrorsIfNoDuplicate = (
  rideIds: Array<Ride["id"]>,
  initErrors: Array<RideUpdateError>,
  rideUpdate: RideUpdate
) => {
  const errors: Array<RideUpdateError> = JSON.parse(JSON.stringify(initErrors));

  rideIds.forEach((id) => {
    if (!errors.find((error) => error.id === id && error.rideUpdate === rideUpdate)) {
      errors.push({ id, rideUpdate });
    }
  });

  return errors;
};

// helper function to remove one error and to return an array of all remaining errors
export const removeErrorIfResolved = (
  id: Ride["id"],
  errors: Array<RideUpdateError>,
  rideUpdate: RideUpdate
) => errors.filter((error) => !(error.id === id && error.rideUpdate === rideUpdate));

// used for onError of batch update mutation in case all updates failed
export const addRideUpdateErrors = (
  rideIds: Array<Ride["id"]>,
  rideUpdate: RideUpdate
) => {
  const initErrors = rideUpdateErrorsVar();
  const updatedErrors = addErrorsIfNoDuplicate(rideIds, initErrors, rideUpdate);

  if (updatedErrors.length > initErrors.length) {
    rideUpdateErrorsVar([...updatedErrors]);
  }
};

// used for onCompleted of batch update mutation in case of only some updates failed
export const updateRideUpdateErrors = (
  rideIdsSuccess: Array<Ride["id"]>,
  rideIdsFail: Array<Ride["id"]>,
  rideUpdate: RideUpdate
) => {
  let isUpdated = false;
  const initErrors = rideUpdateErrorsVar();

  let reducedErrors: Array<RideUpdateError> = JSON.parse(JSON.stringify(initErrors));
  rideIdsSuccess.forEach((id) => {
    reducedErrors = removeErrorIfResolved(id, reducedErrors, rideUpdate);
  });
  if (reducedErrors.length < initErrors.length) {
    isUpdated = true;
  }

  const extendedErrors = addErrorsIfNoDuplicate(rideIdsFail, reducedErrors, rideUpdate);
  if (extendedErrors.length > reducedErrors.length) {
    isUpdated = true;
  }

  if (isUpdated) {
    rideUpdateErrorsVar([...extendedErrors]);
  }
};

export const removeRideUpdateError = (id: Ride["id"], rideUpdate: RideUpdate) => {
  const initErrors = rideUpdateErrorsVar();

  const errors = removeErrorIfResolved(id, initErrors, rideUpdate);

  if (errors.length < initErrors.length) {
    rideUpdateErrorsVar([...errors]);
  }
};

export const removeAllRideUpdateErrors = () => {
  const initErrors = rideUpdateErrorsVar();

  if (initErrors.length) {
    rideUpdateErrorsVar([]);
  }
};
