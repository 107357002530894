import { mpoPathnames as pathnames } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { getContractPartner } from "../shared/helpers/selectedContractPartner/selectedContractPartner";

const RedirectWrapper: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const { contractPartnerId } = getContractPartner();
  if (contractPartnerId.length === 0) {
    return <Navigate to={pathnames.partnerOverview} />;
  }
  return children;
};

export default RedirectWrapper;
