@import "variables";

.calendar {
  position: absolute;
  margin-top: var(--hcr-spacing-1);

  select {
    min-width: 175px;
  }
}
