@import "variables";

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: $spacing-3;
}

.paxName {
  margin-left: $spacing-3;
}
