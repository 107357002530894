@import "variables";

.configurationDescription {
  margin: 0;
  padding-top: $spacing-2;
  text-align: left;
}

.configurationColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: $spacing-2;
  @include on-bp(sm) {
    padding-bottom: 0;
  }
}

.button {
  width: 100%;

  @include on-bp(md) {
    width: auto;
    margin-left: auto;
  }
}
