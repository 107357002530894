import { Switch } from "@flixbus/honeycomb-react";
import { parseDate } from "@flixbus-phx/marketplace-common";
import { isToday, isFuture } from "date-fns";
import * as React from "react";
import { useIntl } from "react-intl";
import useRideFilter, {
  RideFilter,
  rideFilterVar,
} from "../../../state/useRideFilter/useRideFilter";

export type CompletedRideFilterProps = {
  onChange?: (value: RideFilter["hideCompletedRides"]) => void;
  value?: RideFilter["hideCompletedRides"];
};

const CompletedRideFilter: React.FC<CompletedRideFilterProps> = ({ onChange, value }) => {
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [rideFilter, setRideFilter] = useRideFilter(rideFilterVar);
  const { formatMessage } = useIntl();

  const handleHideCompletedRidesFilterChange = (hideCompletedRides: boolean) => {
    if (onChange && value !== undefined) {
      onChange(hideCompletedRides);
    } else {
      setRideFilter({ ...rideFilter, hideCompletedRides });
    }
  };

  React.useEffect(() => {
    const startDate = parseDate(rideFilter.period.startDate);
    const endDate = parseDate(rideFilter.period.endDate);

    if (
      (!isToday(startDate) && isFuture(startDate)) ||
      (!isToday(endDate) && !isFuture(endDate))
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [rideFilter.period.startDate, rideFilter.period.endDate]);

  return (
    <Switch
      label={formatMessage({ id: "ride.filter.hideRides" })}
      id="switch"
      disabled={disabled}
      checked={value !== undefined ? value : rideFilter.hideCompletedRides}
      onChange={() =>
        handleHideCompletedRidesFilterChange(
          value !== undefined ? !value : !rideFilter.hideCompletedRides
        )
      }
    />
  );
};

export default CompletedRideFilter;
